import { Alert, Form, Input, Modal, Checkbox, Row } from 'antd';
import LabelWithInfo from 'components/LabelWithInfo';
import { basePosition } from 'core/utils/dnd';
import { first, get, isEmpty, isEqual, last, sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { questionGroupsResource } from 'redux/resources/questionGroups';
import { getChecklistDefinitionQuestionGroups } from 'redux/selectors/checklistItems/checklistItems';
import { setEditableChecklistDefinition } from 'redux/ui/checklistEditor/operations';
import { setEditableGroup } from 'redux/ui/checklistEditor/reducer';
import { RATING_MODE } from 'core/utils/constants';

const defaultGroup = {};

const EditQuestionGroupModal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currentChecklist, editableGroup = defaultGroup } = useSelector(
    state => state.uiChecklistEditor,
    isEqual
  );

  const { createStarted, updateByIdStarted } = useSelector(
    state => state.questionGroupsResource,
    isEqual
  );

  const questionGroupsByIds = useSelector(state => state.questionGroupsResource.byIds, isEqual);

  const currentChecklistQuestionGroups = useSelector(
    state => sortBy(getChecklistDefinitionQuestionGroups(state, currentChecklist), 'position'),
    isEqual
  );

  const loading = createStarted || updateByIdStarted;

  const layout = {
    labelCol: {
      span: 4
    },
    labelAlign: 'left',
    wrapperCol: {
      span: 20
    }
  };

  const tailLayout = { wrapperCol: { offset: 4, span: 16 } };

  const checklistDefinitionId = get(currentChecklist, 'id', undefined);
  const isAbleToViewPercentageCalculationStartingWithZero =
    currentChecklist.ratingMode === RATING_MODE.PERCENTAGE;
  const visible = !isEmpty(editableGroup);
  const isNew = isEmpty(get(questionGroupsByIds, editableGroup.id, {}));

  const createGroupOrEditRootGroup = async ({
    name,
    position,
    checklistDefinitionId,
    currentChecklist,
    percentageCalculationStartingWithZero
  }) => {
    if (!currentChecklist.isGroupable) {
      await dispatch(
        checklistDefinitionsResource.operations.updateById({
          id: currentChecklist.id,
          isGroupable: true
        })
      );
      await dispatch(
        questionGroupsResource.operations.updateById({
          id: first(get(currentChecklist, 'questionGroupsIds', [])),
          name,
          percentageCalculationStartingWithZero
        })
      );
    } else {
      await dispatch(
        questionGroupsResource.operations.create({
          name,
          position,
          checklistDefinitionId,
          percentageCalculationStartingWithZero
        })
      );
    }
    return [];
  };

  const saveAndCloseModal = async ({ name, percentageCalculationStartingWithZero }) => {
    const savedQuestionGroup = isNew
      ? await createGroupOrEditRootGroup({
          name,
          position: get(last(currentChecklistQuestionGroups), 'position', 0) + basePosition,
          checklistDefinitionId,
          currentChecklist,
          percentageCalculationStartingWithZero
        })
      : await dispatch(
          questionGroupsResource.operations.updateById({
            id: get(editableGroup, 'id', ''),
            name,
            checklistDefinitionId,
            percentageCalculationStartingWithZero
          })
        );

    if (savedQuestionGroup) {
      form.setFieldsValue({ name: '', percentageCalculationStartingWithZero: false });
      dispatch(setEditableGroup());
      dispatch(setEditableChecklistDefinition({ id: get(currentChecklist, 'id', '') }));
    }
  };

  const handleSubmit = values => {
    if (checklistDefinitionId) {
      const { name, percentageCalculationStartingWithZero } = values;
      saveAndCloseModal({ name, percentageCalculationStartingWithZero });
    }
  };

  const closeModal = () => {
    form.setFieldsValue({ name: '', percentageCalculationStartingWithZero: false });
    dispatch(setEditableGroup());
  };

  useEffect(() => {
    if (editableGroup?.name) {
      form.setFieldsValue({ name: editableGroup.name });
    }
    if (editableGroup?.percentageCalculationStartingWithZero) {
      form.setFieldsValue({
        percentageCalculationStartingWithZero: editableGroup.percentageCalculationStartingWithZero
      });
    }
  }, [editableGroup?.name, editableGroup?.percentageCalculationStartingWithZero]);

  return (
    <Modal
      visible={visible}
      title={t('checklistsPage.editQuestionGroupModal.title')}
      width={768}
      onOk={form.submit}
      onCancel={closeModal}
      confirmLoading={loading}
      okText={
        isNew
          ? t('checklistsPage.editQuestionGroupModal.ok.create')
          : t('checklistsPage.editQuestionGroupModal.ok.update')
      }
      cancelText={t('checklistsPage.editQuestionGroupModal.cancel')}
    >
      <Alert
        description={t('checklistsPage.editQuestionGroupModal.alert.description')}
        type="info"
        style={{ marginBottom: 16 }}
      />
      <Form
        {...layout}
        form={form}
        onFinish={handleSubmit}
        initialValues={{ name: editableGroup?.name, percentageCalculationStartingWithZero: false }}
      >
        <Form.Item
          label={
            <LabelWithInfo
              infoTitle={t('checklistsPage.editQuestionGroupModal.form.fields.namePlaceholder')}
            >
              {t('checklistsPage.editQuestionGroupModal.form.fields.name')}
            </LabelWithInfo>
          }
          name="name"
          rules={[
            {
              required: true,
              message: t('checklistsPage.editQuestionGroupModal.form.messages.enterName'),
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder={t('checklistsPage.editQuestionGroupModal.form.fields.namePlaceholder')}
          />
        </Form.Item>
        {isAbleToViewPercentageCalculationStartingWithZero && (
          <Form.Item
            {...tailLayout}
            name="percentageCalculationStartingWithZero"
            rules={[
              {
                transform: value => value || undefined,
                type: 'boolean'
              }
            ]}
            valuePropName="checked"
          >
            <Row type="flex">
              <Checkbox style={{ paddingRight: '8px' }} />
              <LabelWithInfo
                regularText
                infoTitle={t(
                  'checklistsPage.editQuestionGroupModal.form.fields.percentageCalculationStartingWithZeroPlaceholder'
                )}
              >
                {t(
                  'checklistsPage.editQuestionGroupModal.form.fields.percentageCalculationStartingWithZero'
                )}
              </LabelWithInfo>
            </Row>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default EditQuestionGroupModal;
