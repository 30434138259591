import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { isEmpty, isEqual } from 'lodash';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateDashboardWidget } from 'redux/ui/widgetSettingsModal/operations';
import { exportWidgetData } from 'pages/DashboardPage/utils';
import { getUserName } from 'components/UserPreview/getUserName';
import moment from 'moment';
import { Button, Modal } from 'antd';
import { prepareRows } from './prepareTableData';

export const TableReviewsWithScoresContext = React.createContext({
  loadingExport: false,
  startExport: () => {}
});

export const TableReviewsWithScoresProvider = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);
  const unitsByIds = useSelector(state => state.unitsResource.byIds, shallowEqual);

  const startExport = async ({
    id,
    type,
    filters,
    widgetMeta,
    initialState,
    currentChecklist,
    checklistItems,
    commentsResourceByIds,
    uploadedFilesResourceByIds
  }) => {
    const res = await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: {
          number: 1,
          size: widgetMeta.total_count
        }
      })
    );

    const { viewMode } = filters;

    const itemsType =
      viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
        ? 'checklist-questions'
        : 'checklist-question-groups';

    const targetItems = checklistItems.filter(item => item.type === itemsType);

    const columns = [
      {
        title: t('general.clientInteractionType'),
        dataIndex: 'reviewId',
        key: 'reviewId'
      },
      {
        title: t('general.operator'),
        dataIndex: 'operatorId',
        key: 'operatorId'
      },
      {
        title: t('general.unit'),
        dataIndex: 'unitName',
        key: 'unitName'
      },
      {
        title: t('general.phoneNumber'),
        dataIndex: 'communicationKey',
        key: 'communicationKey'
      },
      {
        title: t('general.clientInteractionDate'),
        dataIndex: 'clientInteractionStartedAt',
        key: 'clientInteractionStartedAt'
      },
      {
        title: t('general.reviewDate'),
        dataIndex: 'createdAt',
        key: 'createdAt'
      }
    ];

    const body = {
      widgetType: type,
      widgetData: {
        columns,
        rows: prepareRows({
          filters,
          currentChecklist,
          widgetValue: res?.updatedAnalytics,
          checklistItems,
          isExport: true,
          usersByIds,
          unitsByIds
        })
      }
    };

    // Форматирование комментариев
    const handleFormattingComments = comments => {
      return comments
        .map(comment => {
          const foundFiles = Object.values(uploadedFilesResourceByIds).filter(file =>
            comment?.uploadedFilesIds.includes(file.id)
          );
          const filesUrl = foundFiles
            .map(file => {
              return file.url;
            })
            .join('\n\n');
          const files = `Прикрепленные изображения:\n${filesUrl}`;
          const userName = getUserName({ user: usersByIds[comment?.authorId] });
          const createdAt = moment(comment?.createdAt).format('DD/MM/YYYY, HH:mm');
          const transferText = comment?.text
            .replace(/<br\s*\/?>/g, '\n') // Если есть <br> переносим строку
            .replace(/<p>/g, '') // Избавляемся от тегов </p>
            .replace(/<\/p>/g, '')
            .replace(/<strong>/g, '') // Избавляемся от тегов </strong>
            .replace(/<\/strong>/g, '')
            .replace(/<a\s+(?:[^>]*?\s+)?href=([\"\'])(.*?)(?:\1)[^>]*>.*?<\/a>/g, '$2'); // Находим тег <a> и достаём из него ссылку и вместо тега подставляем найденную ссылку
          return `${userName} ${createdAt} \n${transferText}${filesUrl ? `\n${files}` : ''}`;
        })
        .join('\n\n'); // Отделяем каждый комментарий пробелом
    };

    const getComments = question => {
      const commentsColumn = {
        title: `Комментарии - ${question?.name}`,
        dataIndex: `${question?.id}=comment`,
        key: `${question?.id}=comment`
      };

      body.widgetData.rows.forEach(row => {
        // группируем для каждого критерия все его комментарии
        const comments = Object.values(commentsResourceByIds).filter(
          comment =>
            comment.metadata?.question_id === question?.id && comment?.commentableId === row?.key
        );

        // Добавляем собранные комментарии в значение колонки
        row[`${question?.id}=comment`] = handleFormattingComments(comments);
      });

      return [commentsColumn];
    };

    const getReviewComments = () => {
      const commentsColumn = {
        title: `Комментарии к проверке`,
        dataIndex: 'reviewComment',
        key: 'reviewComment'
      };

      body.widgetData.rows.forEach(row => {
        // группируем для каждого критерия все его комментарии
        const comments = Object.values(commentsResourceByIds).filter(
          comment => comment?.commentableId === row?.key && isEmpty(comment?.metadata?.question_id)
        );

        // Добавляем собранные комментарии в значение колонки
        row.reviewComment = handleFormattingComments(comments);
      });

      return [commentsColumn];
    };

    const handleExport = (exportComments = false) => {
      targetItems.forEach(item => {
        // Добавляем колонку с критерием
        columns.push({
          title: item?.name,
          dataIndex: item?.id,
          key: item?.id
        });
        // Добавляем комментарий после критерия
        if (exportComments && viewMode === WIDGET_VIEW_MODES.QUESTIONS.value) {
          columns.push(...getComments(item));
        }
      });
      // Добавляем колонку Итог
      columns.push({
        title: t('general.result'),
        dataIndex: 'checklistScore',
        key: 'checklistScore'
      });
      // Добавляем колонку Общий комментарий и колонку комментарии к проверке
      if (exportComments) {
        columns.push({
          title: `Общий комментарий`,
          dataIndex: `generalComment`,
          key: `generalComment`
        });
        columns.push(...getReviewComments());
      }
    };

    Modal.confirm({
      title: 'Экспорт виджета',
      closable: true, // Крестик закрытия модального окна
      maskClosable: true, // Закрытие модального окна при нажатии вне его области
      okButtonProps: { style: { display: 'none' } }, // Скрываем стандартную кнопку Ок
      cancelButtonProps: { style: { display: 'none' } }, // Скрываем стандартную кнопку отмены
      content: (
        <div>
          Выберите вариант экспорта
          <div className="ant-modal-confirm-btns">
            <Button
              type="default"
              onClick={async () => {
                await handleExport(true);
                await exportWidgetData({ body, setLoadingExport });
                Modal.destroyAll();
              }}
            >
              С комментариями
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                await handleExport();
                await exportWidgetData({ body, setLoadingExport });
                Modal.destroyAll();
              }}
            >
              Обычный
            </Button>
          </div>
        </div>
      )
    });

    await dispatch(
      updateDashboardWidget({
        id,
        type,
        filters,
        page: initialState
      })
    );
  };
  return (
    <TableReviewsWithScoresContext.Provider value={{ loadingExport, startExport }}>
      {children}
    </TableReviewsWithScoresContext.Provider>
  );
};

export const useTableReviewsWithScoresExport = () => {
  const { loadingExport, startExport } = useContext(TableReviewsWithScoresContext);
  return { loadingExport, startExport };
};
