import { Tooltip } from 'antd';
import React from 'react';
import { SText } from './Standard';

// value - значение
// length - ограничение символов
// template - шаблон идущий в конец строки
const SlicedTextWithTooltip = ({ value, length = 15, template = '...', strong = false }) => {
  // Если значение отсутствует, возвращаем пустую строку
  if (!value) {
    return '';
  }

  const stringValue = value.toString();

  // Если длина строки превышает заданное значение
  if (stringValue.length > length) {
    const substring = `${stringValue.substring(0, length)}${template}`;
    return (
      <Tooltip title={value}>
        <SText strong={strong} ellipsis>
          {substring}
        </SText>
      </Tooltip>
    );
  }

  // Если длина строки меньше или равна длине ограничения, просто возвращаем строку
  return (
    <Tooltip title={value}>
      <SText strong={strong}>{stringValue}</SText>
    </Tooltip>
  );
};

export default SlicedTextWithTooltip;
