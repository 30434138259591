import { Col } from 'antd';
import { INTEGRATIONS } from 'core/utils/constants';
import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import SRow from 'components/Standard/SRow';
import AddIntegration from './components/AddIntegration';
import SaasIntegrationPage from './IntegrationSettingsPages/SaasIntegrationSettingsPage';
import NewIntegrationPage from './CreateIntegrationPage';
import CreateSaasIntegrationPage from './CreateIntegrationsPages/CreateSaasIntegrationPage';
import CreateCustomIntegrationPage from './CreateIntegrationsPages/CreateCustomIntegrationPage';
import CustomIntegrationSettingsPage from './IntegrationSettingsPages/CustomIntegrationSettingsPage';
import MangoIntegrationPage from './IntegrationSettingsPages/MangoIntegrationPage';
import CreateSipuniIntegrationPage from './CreateIntegrationsPages/CreateSipuniIntegration';
import CreateMangoIntegrationPage from './CreateIntegrationsPages/CreateMangoIntegration';
import SipuniIntegrationPage from './IntegrationSettingsPages/SipuniIntegrationSettingsPage';

const IntegrationsPage = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const onAdd = () => {
    history.push(`${url}/new`);
  };
  return (
    <SRow margin="16px">
      <Col span={24}>
        <Switch>
          <Route exact path={`${path}/new`}>
            <NewIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.amocrm.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.bitrix_crm.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.custom_http_telephony.type}/:integrationId`}>
            <CreateCustomIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.custom_http_text.type}/:integrationId`}>
            <CreateCustomIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.sipuni.type}/:integrationId`}>
            <CreateSipuniIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.mango.type}/:integrationId`}>
            <CreateMangoIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.usedesk.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.binotel.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.zendesk.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.help_desk_eddy.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.infinity.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.bright_pattern.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.uis.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.naumen.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/new/${INTEGRATIONS.edna.type}/:integrationId`}>
            <CreateSaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.mango.type}/:integrationId`}>
            <MangoIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.amocrm.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.bitrix_crm.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.bitrix_crm.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.custom_http_text.type}/:integrationId`}>
            <CustomIntegrationSettingsPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.custom_http_telephony.type}/:integrationId`}>
            <CustomIntegrationSettingsPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.sipuni.type}/:integrationId`}>
            <SipuniIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.usedesk.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.binotel.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.zendesk.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.help_desk_eddy.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.infinity.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.bright_pattern.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.uis.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.naumen.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route path={`${path}/${INTEGRATIONS.edna.type}/:integrationId`}>
            <SaasIntegrationPage />
          </Route>
          <Route exact path={path}>
            <AddIntegration onAdd={onAdd} />
          </Route>
        </Switch>
      </Col>
    </SRow>
  );
};

export default IntegrationsPage;
