import { INTEGRATIONS } from 'core/utils/constants';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import api from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import { saveBackgroundJob } from '../backgroundJob/operations';

export const createBrightPatternIntegration = ({
  name,
  endpoint,
  clientId,
  clientSecret,
  mysqlHost,
  mysqlPort,
  mysqlDatabase,
  mysqlUsername,
  mysqlPassword
}) => async dispatch => {
  try {
    const integration = await dispatch(
      integrationsResource.operations.create({
        name,
        endpoint,
        client_id: clientId,
        client_secret: clientSecret,
        mysql_host: mysqlHost,
        mysql_port: mysqlPort,
        mysql_database: mysqlDatabase,
        mysql_username: mysqlUsername,
        mysql_password: mysqlPassword,
        integrationType: INTEGRATIONS.bright_pattern.type
      })
    );
    dispatch(saveBackgroundJob(integration?.currentBackgroundJob));

    return integration?.id;
  } catch (error) {}
};

export const syncDataWithBrightPattern = ({ id, fetchFrom }) => async dispatch => {
  const response = await api.brightPatternSyncDataByPeriod(
    { id },
    decamelizeKeys({
      fetchFrom
    })
  );

  const backgroundJob = processJsonApiObject(response.body.data);

  return dispatch(saveBackgroundJob(backgroundJob));
};
