import { flatMap, flatten, get, find, mapValues, keyBy, uniq, isArray } from 'lodash';

export const prepareData = ({ widgetValue, unitsByIds }) => {
  return Object.keys(widgetValue).map(unitId => ({
    name: unitsByIds[unitId]?.name,
    ...mapValues(keyBy(widgetValue[unitId], 'name'), 'value')
  }));
};

export const prepareKeys = ({ widgetValue }) => {
  return uniq(
    flatMap(
      Object.keys(widgetValue).map(unitId => {
        const unitData = get(widgetValue, unitId, []);
        return isArray(unitData) ? unitData.map(item => item.name) : [];
      })
    )
  );
};


export const prepareColors = ({ widgetValue, keys }) => {
  const labelObjects = flatten(Object.values(widgetValue));
  return keys.map(name => get(find(labelObjects, ['name', name]), 'color', ''));
};
