import { Empty, Space } from 'antd';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { get, intersection, isEmpty, keyBy, orderBy, isEqual } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentUser,
  getCurrentUserPermissions,
  getUnitsWithActiveUsers
} from 'redux/selectors/users';
import { loadTeamAnalytics } from 'redux/ui/teamAnalyticsPage/operations';
import PageFilters from './Components/PageFilters';
import UnitTable from './Components/UnitTable/UnitTable';
import UsersTable from './Components/UsersTable/UsersTable';

const TeamPage = () => {
  const { widgets, loadingWidgets } = useSelector(state => state.analyticsWidgets, isEqual);
  const { filters, viewType } = useSelector(state => state.uiTeamAnalyticsPage, isEqual);
  const reviewsCountWidgets = keyBy(
    get(widgets, `${ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS}.current_period`, {}),
    'id'
  );
  const reviewedUsersIds = Object.keys(reviewsCountWidgets, isEqual);
  const units = useSelector(state => getUnitsWithActiveUsers(state), isEqual);
  const unitsWithReviewedUsers = orderBy(
    units.filter(unit =>
      isEmpty(filters.unitsIds)
        ? intersection(unit.usersIds, reviewedUsersIds).length > 0
        : intersection(unit.usersIds, reviewedUsersIds).length > 0 &&
          filters.unitsIds.includes(unit.id)
    ),
    'name'
  );
  const loading = useSelector(
    state =>
      state.checklistDefinitionsResource.loading ||
      state.unitsResource.loading ||
      loadingWidgets ||
      state.usersResource.loading
  );
  const currentUserId = useSelector(state => getCurrentUser(state)?.id);
  const getUserPermissions = useMemo(() => getCurrentUserPermissions, []);
  const currentUserPermissions = useSelector(state => getUserPermissions(state), isEqual);
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const dispatch = useDispatch();

  const {
    unitsIds,
    checklistDefinitionsIds,
    clientInteractionTimeFrom,
    clientInteractionTimeTo,
    clientInteractionSymbolicTimeRange,
    reviewTimeFrom,
    reviewTimeTo,
    reviewSymbolicTimeRange
  } = filters;

  useEffect(() => {
    dispatch(loadTeamAnalytics({ filters }));
  }, [
    unitsIds,
    checklistDefinitionsIds,
    clientInteractionTimeFrom,
    clientInteractionTimeTo,
    clientInteractionSymbolicTimeRange,
    reviewTimeFrom,
    reviewTimeTo,
    reviewSymbolicTimeRange
  ]);

  const { t } = useTranslation();

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.teamPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <PageFilters
          checklistDefinitionsByIds={checklistDefinitionsByIds}
          units={units}
          filters={filters}
          viewType={viewType}
          showExport={!isEmpty(unitsWithReviewedUsers)}
          reviewsCountWidgets={reviewsCountWidgets}
          widgets={widgets}
        />
      </SCol>
      <SCol span={24} padding="16px">
        {isEmpty(unitsWithReviewedUsers) && (
          <SCard loading={loading} bordered shadowed width="100%">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('teamPage.unitTable.empty')}
            />
          </SCard>
        )}
        {!isEmpty(unitsWithReviewedUsers) && (
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            {viewType === 'employees' ? (
              unitsWithReviewedUsers.map(unit => (
                <UsersTable
                  key={unit.id}
                  unit={unit}
                  widgets={widgets}
                  filters={filters}
                  currentUserId={currentUserId}
                  currentUserPermissions={currentUserPermissions}
                  checklistDefinitionsByIds={checklistDefinitionsByIds}
                  loading={loading}
                  units={units}
                />
              ))
            ) : (
              <UnitTable
                widgets={widgets}
                filters={filters}
                currentUserId={currentUserId}
                currentUserPermissions={currentUserPermissions}
                checklistDefinitionsByIds={checklistDefinitionsByIds}
                loading={loading}
                units={units}
              />
            )}
          </Space>
        )}
      </SCol>
    </SRow>
  );
};

export default TeamPage;
