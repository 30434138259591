export default {
  menu: {
    appMenu: {
      analytics: 'Аналитика',
      reports: 'Отчёты',
      dashboards: 'Дашборд',
      dynamicsOfEvaluationsByUnits: 'Динамика оценок по отделам',
      communications: 'Список коммуникаций',
      tasks: 'Задачи',
      library: 'Библиотека звонков',
      addReview: 'Новая проверка',
      team: 'Команда',
      settings: 'Инструменты',
      checklistsSettings: 'Формы оценки',
      tagsSettings: 'Теги',
      calibrations: 'Калибровочные сессии',
      workPlanTaskConfigurations: 'Распределение нагрузки',
      upload: 'Загрузка звонков',
      appeals: 'Апелляции',
      autoFailScores: 'Динамика по критичным ошибкам'
    },
    userMenu: {
      profile: 'Личный кабинет',
      profileSettings: 'Настройки профиля',
      organizationSettings: 'Настройки организации',
      organizationStructureSettings: 'Сотрудники и права доступа',
      integrationsSettings: 'Интеграции',
      logout: 'Выход',
      organizationId: 'Код организации',
      copyOrganizationId: 'Скопировать Код организации',
      copyOrganizationIdSuccess: 'Код организации скопирован',
      customFields: 'Пользовательские поля',
      aiSettings: 'Настройки'
    },
    helpMenu: {
      helpCenter: 'Help center',
      updates: 'Последние изменения',
      support: 'Написать в поддержку',
      suggestAnIdea: 'Предложить идею',
      hotKeys: 'Горячие клавиши'
    },
    alerts: {
      rolesAlert: {
        title:
          '💡 Роли Администратор и Оператор являются базовыми, без возможности их удаления. Подробнее о ролях, правах доступа и их настройках ',
        button: 'тут'
      }
    },
    workPlanTasks: 'Мои задачи'
  }
};
