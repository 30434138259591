import { createAction, createReducer } from '@reduxjs/toolkit';
import uniqid from 'uniqid';

const defaultState = {
  byIds: {}
};

export const loadQuestionGroupsByIds = createAction('QuestionGroup/loadByIds');
export const createQuestionGroup = createAction('QuestionGroup/create');
export const updateQuestionGroup = createAction('QuestionGroup/create');
export const deleteQuestionGroup = createAction('QuestionGroup/delete');

export const questionGroupsReducer = createReducer(defaultState, {
  [loadQuestionGroupsByIds]: (state, action) => ({
    ...state,
    byIds: { ...state.byIds, ...action.payload }
  }),
  [createQuestionGroup]: (state, action) => ({
    ...state,
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload
    }
  }),
  [updateQuestionGroup]: (state, action) => ({
    ...state,
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload
    }
  }),
  [deleteQuestionGroup]: (state, action) => {
    const newByIds = { ...state.byIds };
    delete newByIds[action.id];
    return { ...state, byIds: newByIds };
  }
});
