import React from 'react';
import { Typography, Tooltip } from 'antd';
import { keyBy } from 'lodash';
import { RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { Trans } from 'react-i18next';
import ClientInteractionLink from 'components/ClientInteractionLink';
import { stringify } from 'core/utils/queryString';
import { valueFormatter } from 'core/utils/valueFormatter';
import { getUserName } from 'components/UserPreview/getUserName';
import moment from 'moment';
import { beatifyFloat } from 'core/utils/convertFloat';
import GetUserPreview from 'components/UserPreview/GetUserPreview';
import {
  getFilters,
  getOrderedGroupQuestionsAndQuestions,
  valueToColorZoneColor
} from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({ currentChecklist, checklistItems, filters, widgetMeta }) => {
  const { viewMode, viewType } = filters;

  const getOrderString = ({ orderString }) => {
    if (orderString === 'asc') return 'ascend';
    if (orderString === 'desc') return 'descend';
  };

  const getSorterId = ({ itemId }) => {
    if (viewMode === WIDGET_VIEW_MODES.QUESTIONS.value) {
      return getOrderString({
        orderString: widgetMeta[`answer_score_${itemId.replace(/-/gi, '_')}`]
      });
    }
    if (viewMode !== WIDGET_VIEW_MODES.QUESTIONS.value) {
      return getOrderString({
        orderString: widgetMeta[`group_score_${itemId.replace(/-/gi, '_')}`]
      });
    }
  };

  const targetItems = getOrderedGroupQuestionsAndQuestions({ checklistItems, viewMode });

  const itemsColumns = targetItems.reduce((acc, item) => {
    const data = {
      title: (
        <Tooltip title={item.name} placement="topLeft">
          <Text>{item.name}</Text>
        </Tooltip>
      ),
      dataIndex: item.id,
      key: item.id,
      align: 'center',
      width: targetItems.length < 8 ? null : 170,
      ellipsis: true,
      sorter: true,
      defaultSortOrder: getSorterId({ itemId: item.id }),
      render: (text, { key }) => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: (text?.value || text?.value === 0) && (
            <Text>
              {viewType !== WIDGET_VIEW_MODES.SCORE.value
                ? beatifyFloat(text?.value)
                : valueFormatter({ value: text?.value, ratingMode: currentChecklist.ratingMode })}
            </Text>
          )
        };
      }
    };
    return [...acc, data];
  }, []);

  return [
    {
      title: <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.type" />,
      dataIndex: 'reviewId',
      key: 'reviewId',
      fixed: 'left',
      width: 60,
      ellipsis: true,
      render: (text, record) => {
        return (
          <ClientInteractionLink
            isReview
            id={text}
            communication={{ id: text, communicationType: record.clientInteractionType }}
          />
        );
      }
    },
    {
      title: <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.operatorId" />,
      dataIndex: 'operatorId',
      key: 'operatorId',
      width: 200,
      ellipsis: true,
      render: (text, record) => {
        return {
          props: {
            style: {
              padding: '2px 5px'
            }
          },
          children: (
            <GetUserPreview
              userId={text}
              altName={record.name}
              link={`/user/${text}/charts${stringify({
                filters: {
                  ...getFilters(filters)
                }
              })}`}
            />
          )
        };
      }
    },

    {
      title: <Trans i18nKey="dashboardPage.widget.unitName" />,
      dataIndex: 'unitName',
      key: 'unitName',
      fixed: 'left',
      width: 80
    },

    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.communicationKey" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.communicationKey" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'communicationKey',
      key: 'communicationKey',
      width: 150,
      ellipsis: true,
      render: text => (
        <Tooltip title={text}>
          <Text>{text || ''}</Text>
        </Tooltip>
      )
    },
    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.clientInteractionStartedAt" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.clientInteractionStartedAt" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'clientInteractionStartedAt',
      key: 'clientInteractionStartedAt',
      defaultSortOrder: getOrderString({ orderString: widgetMeta.client_interaction_started_at }),
      width: 170,
      ellipsis: true,
      sorter: true,
      render: text => <Text>{text || ''}</Text>
    },
    {
      title: (
        <Tooltip
          title={
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.reviewCreatedAt" />
          }
          placement="topLeft"
        >
          <Text>
            <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.reviewCreatedAt" />
          </Text>
        </Tooltip>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 170,
      ellipsis: true,
      sorter: true,
      defaultSortOrder: getOrderString({ orderString: widgetMeta.created_at }),
      render: text => <Text>{text || ''}</Text>
    },
    ...itemsColumns,
    {
      title: <Trans i18nKey="dashboardPage.tableReviewsWithScores.table.columns.totalScore" />,
      dataIndex: 'checklistScore',
      key: 'checklistScore',
      align: 'center',
      fixed: 'right',
      width: 100,
      sorter: true,
      defaultSortOrder: getOrderString({ orderString: widgetMeta.checklist_score }),
      render: text => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: (text?.value || text?.value === 0) && (
            <Text>
              {viewType !== WIDGET_VIEW_MODES.SCORE.value
                ? beatifyFloat(text?.value)
                : valueFormatter({ value: text?.value, ratingMode: currentChecklist.ratingMode })}
            </Text>
          )
        };
      }
    }
  ];
};

export const prepareRows = ({
  widgetValue,
  currentChecklist,
  checklistItems,
  filters,
  isExport,
  usersByIds,
  unitsByIds
}) => {
  const { viewMode, viewType } = filters;
  const { colorZones, ratingMode } = currentChecklist;
  const clietnInteractionTypeToId = {
    phone_call: 'client_phone_number',
    custom_communication: 'communication_id',
    chat: 'integration_uid'
  };

  const getQuestionColorZones = ({ questionId }) =>
    keyBy(checklistItems, 'id')[questionId]?.colorZones || [];

  const getColorZones = ({ questionId }) => {
    if (viewMode === WIDGET_VIEW_MODES.QUESTIONS.value)
      return getQuestionColorZones({ questionId });

    if (viewMode !== WIDGET_VIEW_MODES.QUESTIONS.value && ratingMode === RATING_MODE.PERCENTAGE)
      return colorZones;

    if (viewMode !== WIDGET_VIEW_MODES.QUESTIONS.value && ratingMode !== RATING_MODE.PERCENTAGE)
      return undefined;

    return undefined;
  };

  const getReviewScores = ({ scores }) => {
    return Object.keys(scores).reduce((acc, id) => {
      const value =
        viewType === WIDGET_VIEW_MODES.SCORE.value ? scores[id]?.score : scores[id]?.original_score;

      const valueForColorZone =
        viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
          ? scores[id]?.original_score
          : scores[id]?.score;

      return {
        ...acc,
        [id]: {
          value,
          color: valueToColorZoneColor({
            value: valueForColorZone,
            colorZones: getColorZones({ questionId: id })
          })
        }
      };
    }, {});
  };

  return widgetValue.reduce((acc, review) => {
    if (!review) return acc;
    const value =
      viewType === WIDGET_VIEW_MODES.SCORE.value
        ? review.scores[currentChecklist.id]?.score
        : review.scores[currentChecklist.id]?.original_score;

    const valueForColorZone = review.scores[currentChecklist.id]?.score;

    // Форматирование для общего комментария
    const generalComment = review.checklist_comment
      ? review?.checklist_comment
          .replace(/<br\s*\/?>/g, '\n') // Если есть <br> переносим строку
          .replace(/<p>/g, '') // Избавляемся от тегов </p>
          .replace(/<\/p>/g, '')
          .replace(/<strong>/g, '') // Избавляемся от тегов </strong>
          .replace(/<\/strong>/g, '')
          .replace(/<a\s+(?:[^>]*?\s+)?href=([\"\'])(.*?)(?:\1)[^>]*>.*?<\/a>/g, '$2') // Находим тег <a> и достаём из него ссылку и вместо тега подставляем найденную ссылку
      : '';

    const data = {
      key: review.review_id,
      reviewId: isExport
        ? `${window.location.origin}/reviews/${review.review_id}`
        : review.review_id,
      operatorId: isExport
        ? getUserName({ user: usersByIds[review.operator_id] })
        : review.operator_id,
      unitName: unitsByIds[usersByIds[review.operator_id]?.unitId]?.name,
      name: review?.operator_name,
      communicationKey: review[clietnInteractionTypeToId[review.client_interaction_type]] || '',
      ...getReviewScores({ scores: review.scores }),
      generalComment,
      checklistScore: {
        value,
        color: valueToColorZoneColor({
          value: valueForColorZone,
          colorZones
        })
      },
      clientInteractionType: review.client_interaction_type,
      clientInteractionStartedAt: moment(review.client_interaction_started_at).format(
        'DD/MM/YYYY, HH:mm'
      ),
      createdAt: moment(review.review_created_at).format('DD/MM/YYYY, HH:mm')
    };

    return [...acc, data];
  }, []);
};
