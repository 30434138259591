import createListReducer from 'core/redux/lists/listReducer';
import { phoneCallsResourceV3 } from 'redux/resources/callsV3';

const defaultState = {
  filters: { direction: 'all' },
  filteredUnits: []
};

const additionalReducer = {
  setUnits: (state, { payload }) => ({
    ...state,
    filteredUnits: payload
  })
};

const { actions, reducer, operations } = createListReducer({
  name: 'callsV3',
  defaultState,
  additionalReducer,
  rebuildQuery: true,
  loader: phoneCallsResourceV3.operations.load
});

const callsListReducerV3 = reducer;

export { actions, callsListReducerV3, operations };
