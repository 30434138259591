import createListReducer from 'core/redux/lists/listReducer';
import { promptsCustomFieldsResource } from '../resources/promptsCustomFields';

export const listParams = {
  name: 'promptsCustomFieldsList',
  loader: promptsCustomFieldsResource.operations.load,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const promptsCustomFieldsReducer = reducer;

export { actions, promptsCustomFieldsReducer, operations };
