import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import { get, isEmpty, isEqual, orderBy } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import truncateString from 'core/utils/truncateString';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import prepareData from './prepareData';
import EmptyWidget from '../../Components/EmptyWidget';
import { WidgetCard } from '../../styled';

const ChecklistDefinitionQuestionsAverageScoresByQuestionGroups = ({
  widgetData,
  loadingWidgetId,
  colors,
  isWarningWidget
}) => {
  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const checklistDefinitionsByIds = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const questionsByIds = useSelector(state => state.questionsResource.byIds, isEqual);
  const questionGroupsByIds = useSelector(state => state.questionGroupsResource.byIds, isEqual);

  let widgetValue = get(widgetsAnalyticsByIds, widgetData.id, []);
  const dateLastUpdate = widgetValue?.dateLastUpdate || null;
  if (Object.prototype.hasOwnProperty.call(widgetValue, 'dateLastUpdate')) {
    widgetValue = { ...widgetValue };
    delete widgetValue.dateLastUpdate;
  }
  const { id, type, filters, name } = widgetData;

  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds, {});

  const { keys, data } =
    loadingWidgetId === id
      ? { keys: [], data: [] }
      : prepareData({ widgetValue, questionsByIds, questionGroupsByIds, checklistDefinition });

  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={6} key={id}>
          <WidgetCard>
            <SMLWidgetHeader
              name={name}
              type={type}
              widgetData={widgetData}
              filters={filters}
              dateLastUpdate={dateLastUpdate}
            />

            <ResponsiveBar
              data={orderBy(data, 'questionGroupPosition', 'desc')}
              keys={keys}
              indexBy="questionGroupName"
              margin={{ top: 0, right: 10, bottom: 40, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={colors}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              tooltip={<span />}
              axisBottom={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 16)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default ChecklistDefinitionQuestionsAverageScoresByQuestionGroups;
