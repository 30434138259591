import camelcase from 'camelcase';
import camelcaseKeys from 'camelcase-keys';
import api from 'core/api';
import { processJsonApiCollection } from 'core/jsonapi';
import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';
import decamelize from 'decamelize-keys-deep';
import { reduce } from 'lodash';
import { setTableMeta } from 'redux/ui/clientInteractions/reducer';

export const communicationTablesResource = createReduxResource({
  name: 'communicationTables',
  customOperations: {
    shareTableById: {
      callEndpoint: requestOptions => {
        const { id, ...params } = requestOptions;
        return api.shareCommunicationTable({ id }, decamelize(params));
      },
      onSuccess: ({ result, dispatch, getState }) => {
        const state = getState();
        const table = state.communicationTablesResource.byIds[result.id];

        if (table)
          updateResource({
            dispatch,
            type: 'communicationTables',
            resource: { ...table, usersIds: result.usersIds }
          });
      }
    },
    getEmpty: {
      callEndpoint: api.getEmptyCommunicationTable,
      onSuccess: ({ dispatch, response }) => {
        const included = reduce(
          processJsonApiCollection(response?.body?.included),
          (result, curr) => {
            const key = `${camelcase(curr.type)}`;
            return { ...result, [key]: [...(result[key] || []), curr] };
          },
          { clientInteractions: [], reviews: [], comments: [], tasks: [] }
        );

        const meta = camelcaseKeys(response.body?.meta || {});

        dispatch(setTableMeta({ ...included, ...meta }));
        return { included, meta };
      }
    }
  },
  reactors: {
    onLoadByIdSucceed: ({ dispatch, getState, response }) => {
      const included = reduce(
        processJsonApiCollection(response?.body?.included),
        (result, curr) => {
          const key = `${camelcase(curr.type)}`;
          return { ...result, [key]: [...(result[key] || []), curr] };
        },
        { clientInteractions: [], reviews: [], comments: [], tasks: [] }
      );

      const meta = camelcaseKeys(response.body?.meta || {});

      dispatch(setTableMeta({ ...included, ...meta }));
    },
    onUpdateByIdSucceed: ({ dispatch, getState, response }) => {
      const included = reduce(
        processJsonApiCollection(response?.body?.included),
        (result, curr) => {
          const key = `${camelcase(curr.type)}`;
          return { ...result, [key]: [...(result[key] || []), curr] };
        },
        { clientInteractions: [], reviews: [], comments: [], tasks: [] }
      );

      const meta = camelcaseKeys(response.body?.meta || {});

      dispatch(setTableMeta({ ...included, ...meta }));
    }
  }
});
