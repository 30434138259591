import { Affix, Col, Layout, Row, Spin } from 'antd';
import cn from 'classnames/bind';
import SCol from 'components/Standard/SCol';
import { DEFAULT_PAGE_TITLE } from 'core/utils/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Logo from '../Logo/Logo';
import AppMenu from '../Menu/AppMenu';
import HelpMenu from '../Menu/HelpMenu';
import UserMenu from '../Menu/UserMenu';

const defaultStyle = {};

const MainLayout = ({
  children,
  cardComponents,
  style = defaultStyle,
  pageTitle = DEFAULT_PAGE_TITLE
}) => {
  const { Content } = Layout;
  const { i18n, t } = useTranslation();
  document.title = t(pageTitle);

  const preferedLocale = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['prefered-locale']
  );

  const isLoadingReduxTokenAuth = useSelector(state => state.reduxTokenAuth.currentUser.isLoading);

  useEffect(() => {
    if (preferedLocale) i18n.changeLanguage(preferedLocale);
  }, [preferedLocale]);

  return (
    <Layout>
      <Affix>
        <StyledHeader>
          <Row type="flex" justify="space-between">
            <Col flex="auto">
              <Row>
                <Logo />
                <Col flex="auto">
                  <AppMenu />
                </Col>
              </Row>
            </Col>
            <SCol display="flex" justifyContent="flex-end">
              <HelpMenu />
              <UserMenu />
            </SCol>
          </Row>
        </StyledHeader>
      </Affix>
      <Content
        className={cn('contentStyle', { cardComponentsContainer: cardComponents })}
        style={style}
      >
        {children}
      </Content>
      <StyledSpin spinning={isLoadingReduxTokenAuth} tip={t('errorPages.loadingPage.title')} />
    </Layout>
  );
};

const StyledSpin = styled(Spin)`
  &.ant-spin {
    position: absolute;
    height: 100vh;
    width: 100vw;
    padding-top: 25%;
    background-color: rgba(100, 100, 100, 0.15);
  }
`;

const StyledHeader = styled(Layout.Header)`
  &.ant-layout-header {
    padding: 0 16px;
    height: 48px;
  }
`;

export default MainLayout;
