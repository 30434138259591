import { Checkbox, message, Modal, Row, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { operations as usersOperations } from 'redux/lists/usersListReducer';
import { actions, operations } from 'redux/lists/datasetList';
import { operations as datasetsFileListOperations } from 'redux/lists/datasetFileList';
import { operations as datasetsBindingOperations } from 'redux/lists/datasetBinding';
import SCard from 'components/Standard/SCard';
import SText from 'components/Standard/SText';
import { SCol, SRow } from 'components/Standard';
import moment from 'moment';
import { uniq } from 'lodash';
import UserPreview from 'components/UserPreview';
import { deleteDatasetEndpoint, getDelayedEndpoint } from 'core/api';
import { setEditingDataset } from 'redux/ui/DatasetModal/reducer';
import STable from './STable';
import CheckedInfo from './CheckedInfo';
import ModalDataset from './ModalDataset';

let selectedIds;

const DatasetAiTable = ({
  setEditingDataset,
  setIsModalOpen,
  loadDatasets,
  loadUsers,
  datasets,
  loading,
  totalCount,
  page,
  search,
  updatePage,
  setSorting,
  sort,
  onDatasetDelete,
  datasetsByIds,
  loadDatasetsFileList,
  loadDatasetsBinding,
  createdByText,
  searchText
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [massDeletion, setMassDeletion] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  if (selectedRows) {
    selectedIds = selectedRows.map(item => item.id);
  }
  const delayedKey = localStorage.getItem('delayedKey') || null;

  useEffect(() => {
    if (delayedKey !== null) {
      const interval = setInterval(() => {
        fetch(`${getDelayedEndpoint}?key=${delayedKey}`, {
          method: 'GET'
        })
          .then(response => response.json())
          .then(data => {
            if (data.status === 'complete') {
              message.success('Данные обновились');
              localStorage.removeItem('delayedKey');
              setIsComplete(true);
              clearInterval(interval);
            }
            setIsComplete(false);
          })
          .catch(error => {
            console.error('Произошла ошибка:', error);
          });
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [delayedKey]);

  useEffect(() => {
    loadDatasets({
      organization_id: organizationId,
      page_number: page.number,
      search_input: searchText,
      created_by: createdByText,
      page_size: page.size,
      sort
    });
    loadUsers({
      sort: 'last_name',
      include: 'unit,role.levels',
      pagination: false
    });
    loadDatasetsFileList();
    loadDatasetsBinding();
  }, [sort, page, search, isComplete, createdByText, searchText]);

  const handleCheckboxChange = (e, record) => {
    const { checked } = e.target;
    setSelectedRows(prevSelectedRows => {
      if (checked) {
        return [...prevSelectedRows, record];
      }
      return prevSelectedRows.filter(row => row.id !== record.id);
    });
  };

  const handleAllCheckboxChange = e => {
    const { checked } = e.target;
    if (checked) {
      setMassDeletion(true);
      setSelectedRows(datasets);
    } else {
      setMassDeletion(false);
      setSelectedRows([]);
    }
  };

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    const { pageSize, current } = pagination;
    dispatch(
      updatePage({
        size: pageSize.toString(),
        number: current.toString()
      })
    );
    dispatch(setSorting(sorter));
  }, []);

  const handleEdit = record => {
    setEditingDataset(datasetsByIds[record.id]);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    Modal.confirm({
      maskClosable: true,
      title: t('datasetAiPage.modalConfirmDelete.title'),
      content: massDeletion
        ? t('datasetAiPage.modalConfirmDelete.massDescription')
        : t('datasetAiPage.modalConfirmDelete.description'),
      okText: t('datasetAiPage.modalConfirmDelete.ok'),
      cancelText: t('datasetAiPage.modalConfirmDelete.cancel'),
      okType: 'danger',
      onOk: async () => {
        try {
          await fetch(deleteDatasetEndpoint, {
            method: 'POST',
            body: JSON.stringify({
              id: selectedIds
            })
          });
          message.success(t('datasetAiPage.messages.deleteSuccess'));
          await onDatasetDelete({ selectedIds });
          await loadDatasets({
            organization_id: organizationId,
            page_number: page.number,
            page_size: page.size,
            sort
          });
          setSelectedRows([]);
        } catch (error) {
          console.log(error);
          message.error(t('errorPages.internalServerError.title'));
        }
      }
    });
  };

  const columns = [
    {
      title: <Checkbox checked={selectedRows.length > 0} onChange={handleAllCheckboxChange} />,
      dataIndex: 'checked',
      key: 'checked',
      fixed: 'left',
      width: 30,
      render: (checked, record) => (
        <Checkbox
          checked={selectedRows.some(row => row.id === record.id)}
          onChange={e => handleCheckboxChange(e, record)}
        />
      )
    },
    {
      title:
        selectedRows.length > 1 ? (
          <SText style={{ position: 'absolute', top: '2px' }}>
            <CheckedInfo handleDelete={handleDelete} />
          </SText>
        ) : (
          t('datasetAiPage.columns.title')
        ),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      ellipsis: true,
      render: (text, record) => {
        return (
          <SRow direction="vertical">
            <SText
              title={text}
              placement="topLeft"
              onClick={() => handleEdit(record)}
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              <Typography.Link href="#">{text}</Typography.Link>
            </SText>
            <SText style={{ position: 'absolute', top: '6px' }}>
              {selectedRows.some(row => row.id === record.id) && selectedRows.length < 2 && (
                <CheckedInfo handleDelete={handleDelete} />
              )}
            </SText>
          </SRow>
        );
      }
    },
    {
      title: t('datasetAiPage.columns.createdBy'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '25%',
      render: text => {
        return <UserPreview userId={text} showAvatar disabled style={{ width: '100px' }} />;
      }
    },
    {
      title: t('datasetAiPage.columns.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '25%',
      sorter: true,
      render: text => {
        return <SText ellipsis>{text ? moment(text).format('DD/MM/YYYY, HH:mm') : ''}</SText>;
      }
    },
    {
      title: t('datasetAiPage.columns.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '25%',
      sorter: true,
      render: text => {
        return <SText ellipsis>{text ? moment(text).format('DD/MM/YYYY, HH:mm') : ''}</SText>;
      }
    }
  ];

  return (
    <SCol span={24}>
      <Helmet>
        <title>{t('pagesMeta.datasetPage.title')}</title>
      </Helmet>
      <SCard bordered shadowed>
        <Row
          type="flex"
          align="start"
          justify="space-between"
          gutter={[8, 8]}
          style={{ margin: '-4px -4px 4px' }}
        />
        <STable
          dataSource={datasets}
          columns={columns}
          loading={loading}
          rowKey="id"
          size="small"
          border
          pagination={{
            pageSize: parseInt(page.size),
            current: parseInt(page.number),
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50']
          }}
          onChange={handleTableChange}
          scroll={{ y: 'calc(100vh - 400px)' }}
        />
      </SCard>
      <ModalDataset />
    </SCol>
  );
};

const mapStateToProps = state => {
  const { loading: datasetsResourceLoading, byIds: datasetsByIds } = state.datasetsResource;
  const { loading: datasetsListLoading, ids, totalCount, page, sort } = state.datasetsList;
  const loading = datasetsListLoading || datasetsResourceLoading;

  const datasets = uniq(ids)
    .map(id => datasetsByIds[id])
    .filter(Boolean);

  return {
    loading,
    datasets,
    datasetsByIds,
    totalCount,
    page,
    sort
  };
};

const mapDispatchToProps = {
  setEditingDataset,
  loadDatasets: operations.load,
  loadDatasetsFileList: datasetsFileListOperations.load,
  loadDatasetsBinding: datasetsBindingOperations.load,
  loadUsers: usersOperations.load,
  updatePage: actions.updatePage,
  setSorting: actions.setSorting,
  setSearch: actions.setSearch,
  onDatasetDelete: actions.onDatasetDelete
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatasetAiTable));
