import createListReducer from 'core/redux/lists/listReducer';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';

export const listParams = {
  name: 'clientInteractionsList',
  defaultState: {
    tasksIds: [],
    reviewsIds: [],
    filters: { direction: 'all', checklistDefinitionsIds: [] },
    customFieldFilters: {}
  },
  additionalReducer: {
    setDefaultState: state => ({ ...state, ...listParams.defaultState }),
    setReviewsIds: (state, { payload }) => ({
      ...state,
      reviewsIds: payload
    }),
    setTasksIds: (state, { payload }) => ({ ...state, tasksIds: payload }),
    clearList: state => ({
      ...state,
      ids: [],
      tasksIds: [],
      reviewsIds: [],
      page: { ...state.page, number: '1' }
    }),
    setCustomFieldFilters: (state, { payload }) => ({ ...state, customFieldFilters: payload }),
    updateCustomFieldFilters: (state, { payload }) => ({
      ...state,
      customFieldFilters: { ...state.customFieldFilters, ...payload }
    })
  },
  rebuildQuery: true,
  loader: clientInteractionsResource.operations.load
};

const { actions, reducer, operations } = createListReducer(listParams);

const clientInteractionsListReducer = reducer;

export { actions, clientInteractionsListReducer, operations };
