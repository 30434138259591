import styled from 'styled-components';
import { Checkbox, Row } from 'antd';

export const SRow = styled(Row)``;

export const TableContainer = styled.div``;

export const SCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    //padding-right: 10px;
  }
`;

export const TitleContainer = styled.div``;

export const CommunicationTypeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OperatorContainer = styled.div`
  margin: 10px 0 10px 30px;
`;

export const DirectionContainer = styled.div`
  margin: 10px 0 10px 0;
`;

export const RequiredFieldSpan = styled.span`
  color: red;
`;

export const UploadCallsPageContainer = styled.div`
  background-color: #f0f2f5;
  height: 100vh;
`;

export const HeaderUploadCallsPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

export const HeaderTitle = styled.div`
  font-size: 16px;
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const HeaderItem = styled.div``;

export const HeaderFileList = styled.div`
  width: 200px;
`;
