export default {
  clientInteractionPage: {
    review: 'Evaluacione: ',
    messages: {
      emptyQuestionsComments:
        'No se puede guardar una comprobación vacía. Evaluar al menos un criterio',
      emptyQuestionsValues:
        'No se puede guardar solo el comentario a las preguntas. Añada la evaluación a una o varias preguntas de la lista',
      needToChangeEditMode: 'Para ejecutar la acción pase al modo de redacción de la evaluación',
      commentSuccessfullyUpdated: 'El comentario ha sido actualizado',
      updateCommentFailed: 'No se pudo actualizar el comentario',
      closeDrawer: {
        title: 'Estimación cercana?',
        content: 'Cuando está cerrado, los resultados del análisis no se guardarán.'
      }
    },
    alerts: {
      disabledUser: {
        title: 'Ufff! Este usuario no está activo',
        description: 'Para pasar a la evaluación, es necesario activar el usuario.',
        button: 'Pasar a los ajustes'
      }
    }
  }
};
