import React, { useEffect, useState } from 'react';
import { Checkbox, Modal } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, uniq } from 'lodash';
import SText from 'components/Standard/SText';
import { SListItem } from 'components/Standard/SList';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SButton from 'components/Standard/SButton';
import SInput from 'components/Standard/SInput';
import { unitsResource } from 'redux/resources/units';
import { AUTO_FAIL_TYPES } from 'core/utils/constants';
import SModal from 'components/Standard/SModal';
import { WidgetTitle } from 'components/WidgetTitle';
import { operations } from '../redux/lists/usersToUnitList';
import { hasConflictFilters } from '../pages/DashboardPage/utils';

const CheckboxGroup = styled(Checkbox.Group)`
  && {
    display: block;
    width: 97%;
  }

  .ant-checkbox-group-item {
    display: block;
    margin-right: 0;
    margin-top: 16px;
  }
`;

const ReportSettingsModal = ({
  id,
  setVisibleFilterModal,
  visible,
  onCancel,
  onAdd,
  deleteDashboardPageWidget,
  isAutoFailsAnalytics = false
}) => {
  const type = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.type, isEqual);
  const checkedUnitsIds = useSelector(
    state => state.dashboardWidgetsResource.byIds[id]?.filters?.unitsIds,
    isEqual
  );
  const checkedOperatorsIds = useSelector(
    state => state.dashboardWidgetsResource.byIds[id]?.filters?.operatorsIds,
    isEqual
  );
  const widgetPageSize = useSelector(
    state => state.dashboardWidgetsResource.byIds[id]?.page.size,
    isEqual
  );
  const widgetName = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.name, isEqual);
  const widgetFilters = useSelector(
    state => state.dashboardWidgetsResource.byIds[id]?.filters,
    isEqual
  );
  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);
  const { ids } = useSelector(state => state.usersToUnitList, isEqual);
  const users = useSelector(
    state => uniq(ids || []).map(id => state.usersResource.byIds[id]),
    isEqual
  );
  const arrayOfUnits = Object.keys(unitsByIds);

  const hasConflicts = hasConflictFilters({
    currentFilters: checkedUnitsIds || [],
    availableReduxFilters: arrayOfUnits
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checkedList, setCheckedList] = useState(checkedUnitsIds || checkedOperatorsIds);
  const [indeterminate, setIndeterminate] = useState();
  const [title, setTitle] = useState(widgetName);
  const [checkAll, setCheckAll] = useState(false);

  const isUnitMode = type === AUTO_FAIL_TYPES.CRITICAL_ERRORS_BY_UNITS;

  useEffect(() => {
    if (visible) {
      if (isAutoFailsAnalytics) {
        isUnitMode
          ? dispatch(
              unitsResource.operations.load({
                pagination: false
              })
            )
          : dispatch(
              operations.load({
                pagination: false
              })
            );
      }
      setCheckedList(hasConflicts ? [] : checkedUnitsIds || checkedOperatorsIds);
      setCheckAll(
        hasConflicts
          ? false
          : checkedOperatorsIds?.length === users?.length ||
              checkedUnitsIds?.length === Object.values(unitsByIds)?.length
      );
      setTitle(hasConflicts ? '' : widgetName);
      if (
        !hasConflicts &&
        !checkAll &&
        ((!!checkedOperatorsIds?.length && checkedOperatorsIds?.length < users?.length) ||
          (!!checkedUnitsIds?.length &&
            checkedUnitsIds?.length < Object.values(unitsByIds)?.length))
      ) {
        setIndeterminate(true);
      }
    }
  }, [visible, users.length, Object.values(unitsByIds).length]);

  const getOptions = () => {
    const style = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    };

    let options;
    if (isAutoFailsAnalytics) {
      options = isUnitMode
        ? Object.values(unitsByIds).map(unit => ({
            label: unit.name,
            value: unit.id,
            key: unit.id,
            style
          }))
        : users.map(user => ({
            label: user.name,
            value: user.id,
            key: user.id,
            style
          }));
      return options;
    }
    options = Object.values(unitsByIds).map(unit => ({
      label: unit.name,
      value: unit.id,
      key: unit.id,
      style
    }));
    return options;
  };

  const getUpdatedFilter = () => {
    let updatedFilter;
    if (isAutoFailsAnalytics) {
      updatedFilter = isUnitMode ? { unitsIds: checkedList } : { operatorsIds: checkedList };
      return updatedFilter;
    }
    updatedFilter = { unitsIds: checkedList };
    return updatedFilter;
  };

  const onOk = () => {
    onAdd({
      id,
      updatedFilter: getUpdatedFilter(),
      filters: widgetFilters,
      setVisibleFilterModal,
      dispatch,
      name: title,
      widgetPageSize
    });
  };

  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < getOptions().length);
    setCheckAll(list.length === getOptions().length);
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? getOptions().map(obj => obj.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChangeTitle = e => {
    setTitle(e.currentTarget.value);
  };

  const deleteWidget = () => {
    setVisibleFilterModal(false);
    Modal.confirm({
      closable: true,
      title: t('dashboardPages.deleteWidgetConfirmModalTitle'),
      content: t('dashboardPages.deleteWidgetConfirmModalContent'),
      cancelText: t('general.canceling'),
      onOk: () => {
        dispatch(
          deleteDashboardPageWidget({
            id,
            page: {
              number: 1,
              size: 5
            }
          })
        );
      },
      okText: t('general.continue')
    });
  };

  return (
    <SModal
      size="big"
      width="474px"
      title={<WidgetTitle>{t('dashboardPages.reportSettings')}</WidgetTitle>}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <SRow justify="space-between">
          <SButton onClick={() => deleteWidget()} danger>
            {`${t('dashboardPages.deleteReport')}`}
          </SButton>
          <SButton onClick={onOk} type="primary">
            {t('general.save')}
          </SButton>
        </SRow>
      ]}
    >
      <SListItem borderBottom="1px solid var(--gray_4)" padding="0px">
        <SCol span={24}>
          <SRow>
            <SCol span={24}>
              <SRow marginBottom="5px">
                <SText strong>{t('dashboardPages.reportName')}</SText>
              </SRow>
              <SInput
                value={title}
                onChange={onChangeTitle}
                placeholder={t('dashboardPages.reportName')}
              />
            </SCol>
          </SRow>
          <SRow marginTop="26px" marginBottom="15px">
            <SText strong>{t('dashboardPages.changeUnit')}</SText>
          </SRow>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            {t('dashboardPages.selectAll')}
          </Checkbox>
          <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '280px' }}>
            <CheckboxGroup options={getOptions()} value={checkedList} onChange={onChange} />
          </PerfectScrollbar>
        </SCol>
      </SListItem>
    </SModal>
  );
};

export default ReportSettingsModal;
