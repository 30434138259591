import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import {
  color,
  space,
  layout,
  flexbox,
  background,
  shadow,
  position,
  typography
} from 'styled-system';

export default styled(Breadcrumb)`
&.ant-breadcrumb {
  ${space}
  ${layout} 
  ${flexbox} 
  ${background} 
  ${shadow} 
  ${position}
  ${typography}
  ${color}
}
`;