import React from 'react';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { InputNumber } from 'antd';

export default ({
  style,
  parser,
  onChange,
  min,
  max,
  step,
  precision,
  formatter,
  value = { from: null, to: null },
  placeholderFrom = <Trans i18nKey="general.from" />,
  placeholderTo = <Trans i18nKey="general.to" />,
  disabled,
  size,
  loading
}) => {
  const { t } = useTranslation();
  const defaultFromFormatter = value => `${t('general.from')}: ${isNil(value) ? '' : value}`;
  const defaultToFormatter = value => `${t('general.to')}: ${isNil(value) ? '' : value}`;

  // const defaultParser = value => {
  //   // const regExp = /[a-zA-Zа-яА-Я:]/gi;
  //   // const parsedValue = parseInt(value.replace(regExp, ''));
  //   // if (parsedValue === 0) return 0;
  //   // return parsedValue || '';
  // };

  const defaultParser = value => {
    if (!value) return '';
    const trimmedValue = value.trim();
    if (trimmedValue.charAt(0) === '-' && trimmedValue.length > 1) {
      return `-${trimmedValue.replace(/-/g, '')}`;
    }
    return trimmedValue.replace(/[^0-9.-]+/g, '');
  };

  return (
    <Container style={style}>
      <InputNumber
        disabled={disabled}
        loading={loading}
        placeholder={placeholderFrom}
        parser={parser || defaultParser}
        min={min}
        max={max}
        step={step}
        precision={precision}
        formatter={formatter || defaultFromFormatter}
        value={value.from}
        onChange={newValue => {
          if (newValue >= value?.to) {
            return onChange({
              to: isNil(newValue) ? undefined : `${newValue}`,
              from: isNil(newValue) ? undefined : `${newValue}`
            });
          }
          onChange({ to: value.to, from: newValue });
        }}
        size={size}
      />
      <InputNumber
        disabled={disabled}
        loading={loading}
        placeholder={placeholderTo}
        parser={parser || defaultParser}
        min={value.from || 0}
        max={max}
        step={step}
        precision={precision}
        formatter={formatter || defaultToFormatter}
        value={value.to}
        onChange={newValue => {
          onChange({ from: value.from, to: newValue });
        }}
        size={size}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
