import { Button, Carousel, Col, Typography } from 'antd';
import colorLogo from 'assets/images/qolio-logo/png/logo-color-100.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const { Text, Title } = Typography;

export const AdditionalText = styled.span`
  color: white !important;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  color: white !important;
  text-decoration: underline;
  margin: 0 8px;
`;

export const FormContainer = styled(Col)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-card {
      width: 80%;
      max-width: 560px;
    }
  }
`;

export const SlideImage = styled.img`
  margin: 0 auto;
  display: block;
  max-width: 100%;
  height: 50vh;
`;

export const sharedTextStyle = css`
  width: 100%;
  text-align: center;
  display: block;
`;

export const SlideText = styled(Text)`
  ${sharedTextStyle}
`;

export const SlideTitle = styled(Title)`
  ${sharedTextStyle}
`;

export const StyledCarousel = styled(Carousel)`
  && {
    .slick-dots.slick-dots-bottom {
      bottom: -8px;
      margin: 0;

      li {
        opacity: 0.5;
        button {
          background: var(--blue_6);
        }

        &.slick-active {
          opacity: 1;
        }
      }
    }
  }
`;

const StyledLinkToLanding = styled.a`
  position: absolute;
  bottom: 16px;
  left: 16px;
`;

export const LinkToLandingPage = () => {
  const { t } = useTranslation();
  return (
    <StyledLinkToLanding href="https://qolio.ru/">
      <Button type="link">{t('loginPage.form.buttons.goToSite')}</Button>
    </StyledLinkToLanding>
  );
};

const StyledLogo = styled.img`
  position: absolute;
  left: 16px;
  top: 16px;
`;

export const AuthLogo = () => (
  <a href="/" style={{ zIndex: '10' }}>
    <StyledLogo src={colorLogo} />
  </a>
);
