import { Col, Modal, Row, Spin, Typography, List, Checkbox } from 'antd';
import { actions, operations } from 'redux/lists/usersToUnitList';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserPreview from 'components/UserPreview';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isEqual, throttle } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getUnitsByIds } from 'redux/selectors/units';
import { getRolesByIds } from 'redux/selectors/roles';
import styled from 'styled-components';
import Search from 'components/Inputs/Search';
import SModal from 'components/Standard/SModal';

const { Text } = Typography;

const StyledModal = styled(Modal)`
  && {
    .ant-modal-close-x {
      line-height: 64px;
    }
  }
`;

export const UnitEmployeesModal = ({ unitId, onAdd, onCancel }) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const dispatch = useDispatch();

  const loading = useSelector(state => state.usersToUnitList.loading);
  const { totalPages, page, filters, search } = useSelector(
    state => state.usersToUnitList,
    isEqual
  );
  const users = useSelector(
    state => state.usersToUnitList.ids?.map(id => state.usersResource.byIds[id]),
    isEqual
  );

  const unitsByIds = useSelector(getUnitsByIds, isEqual);
  const rolesByIds = useSelector(getRolesByIds, isEqual);

  const onOk = () => {
    onAdd(selectedUsers);
  };

  const onSearch = search => {
    dispatch(actions.clearList());
    dispatch(actions.setSearch(search));
    dispatch(
      operations.load({
        search,
        page: { ...page, number: 1 },
        filters,
        sort: 'last_name',
        include: 'unit,role.levels'
      })
    );
  };

  useEffect(() => {
    if (unitId) {
      // dispatch(actions.clearList());
      dispatch(
        operations.load({
          search,
          page: { ...page, number: 1 },
          filters,
          sort: 'last_name',
          include: 'unit,role.levels'
        })
      );
    } else {
      dispatch(actions.clearList());
      setSelectedUsers([]);
    }

    return () => {
      dispatch(actions.clearList());
      setSelectedUsers([]);
    };
  }, [dispatch, unitId]);

  const hasNextPage = totalPages > page.number;

  const renderUser = user => {
    const isUserInUnit = unitId === user.unitId;
    return (
      <List.Item style={{ padding: '0' }}>
        <Col span={10}>
          <Row gutter={[8, 0]} align="middle">
            <Col>
              <Checkbox
                checked={selectedUsers.includes(user.id) || isUserInUnit}
                onChange={e =>
                  setSelectedUsers(
                    e.target.checked
                      ? [...selectedUsers, user.id]
                      : selectedUsers.filter(selectedId => selectedId !== user.id)
                  )
                }
                disabled={isUserInUnit}
              />
            </Col>
            <Col style={{ maxWidth: 'calc(100% - 24px)' }}>
              <UserPreview showAvatar disabled user={user} />
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <Text>{unitsByIds[user.unitId]?.name}</Text>
        </Col>
        <Col span={6}>
          <Text>{rolesByIds[user.roleId]?.name}</Text>
        </Col>
      </List.Item>
    );
  };

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      dispatch(actions.updatePage({ number: newPage }));
      dispatch(
        operations.load({
          search,
          page: { ...page, number: newPage },
          filters,
          sort: 'last_name',
          include: 'unit,role.levels'
        })
      );
    }
  }, 250);

  return (
    <SModal
      width="887px"
      title={
        <Row align="middle" justify="space-between" style={{ paddingRight: '25px' }}>
          <Col>{t('organizationStructure.employeesList')}</Col>
          <Col>
            <Search
              loading={loading}
              placeholder={t('organizationStructure.tabs.employees.table.filters.employeesSearch')}
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </Col>
        </Row>
      }
      visible={!isEmpty(unitId)}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('organizationStructure.tabs.units.modal.ok')}
      cancelText={t('organizationStructure.tabs.units.modal.cancel')}
      size="big"
    >
      <>
        <List.Item style={{ borderBottom: '1px solid var(--gray_4)', padding: '0' }}>
          <Col span={10}>
            <Row>
              <Col style={{ width: '24px' }} />
              <Col>
                <Text strong>{t('general.employee')}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Text strong>{t('general.unit')}</Text>
          </Col>
          <Col span={6}>
            <Text strong>{t('general.role')}</Text>
          </Col>
        </List.Item>
        <PerfectScrollbar
          onScrollY={onScrollY}
          options={{ suppressScrollX: true }}
          style={{ maxHeight: '385px' }}
        >
          <List dataSource={users} renderItem={renderUser} rowKey="id" />
          <Spin spinning={loading}>
            <List.Item />
          </Spin>
        </PerfectScrollbar>
      </>
    </SModal>
  );
};
