import uniqid from 'uniqid';
import { QUESTION_ANALYTICS, QUESTION_ANALYTICS_HISTORY } from './constants';

const defaultState = {
  loading: false,
  byIds: {},
  historyByIds: {},
  errors: []
};

export default function(state = defaultState, action) {
  const actions = {
    [QUESTION_ANALYTICS.LOAD_STARTED]: () => ({ ...state, loading: true }),
    [QUESTION_ANALYTICS.LOAD_SUCCESS]: payload => ({
      ...state,
      loading: false,
      byIds: { ...state.byIds, ...payload }
    }),
    [QUESTION_ANALYTICS.LOAD_FAILED]: error => ({
      ...state,
      errors: [...state.errors, error]
    }),

    [QUESTION_ANALYTICS_HISTORY.LOAD_STARTED]: () => ({ ...state, loading: true }),
    [QUESTION_ANALYTICS_HISTORY.LOAD_SUCCESS]: payload => ({
      ...state,
      loading: false,
      historyByIds: { ...state.historyByIds, ...payload }
    }),
    [QUESTION_ANALYTICS_HISTORY.LOAD_FAILED]: error => ({
      ...state,
      errors: [...state.errors, error]
    }),
    default: () => state
  };
  return (actions[action.type] || actions.default)(action.payload);
}
