import { Col, Select } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import CustomTag from 'components/Tags/CustomTag';
import FilterName from 'pages/ClientInteractionsPage/components/FilterName';
import RangeInputs from 'components/Inputs/RangeInputs';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import {
  CHECKLIST_DEFINITION_STATUS,
  DATE_PICKER_MODES,
  FLAGS_COLORS,
  FLAGS_TO_COLORS_RELATIONS,
  PERMISSIONS
} from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { get, isEmpty, isEqual } from 'lodash';
import isNil from 'lodash/isNil';
import React from 'react';
import Icon from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import {
  getChecklistsDefinitionsByIds,
  getChecklistsDefinitionsByParams
} from 'redux/selectors/checklists';
import { getCurrentUserPermissions, getUsersWithPermissions } from 'redux/selectors/users';
import { toogleFilterGroup } from 'redux/ui/clientInteractions/reducer';
import SpecificUsersSelect from 'components/Inputs/SpecificUsersSelect';
import {
  DownOutlined,
  FlagFilled,
  InfoCircleOutlined,
  MessageFilled,
  RightOutlined
} from '@ant-design/icons';
import { IconButton } from 'components/Buttons';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import Text from 'antd/lib/typography/Text';
import { getChecklistDefinitionQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { CategoryName, CategoryNameBlock, FilterContainer } from './styled';

const { Option } = Select;

const TableReviewsFilters = ({
  disabled,
  loadingCheckilstDefinitions,
  loadingTaskDefinitions,
  taskDefinitions = [],
  filters,
  checklistDefinitions,
  loadingUsers,
  reviewers = [],
  updateFilters,
  toogleFilterGroup,
  collapsedFilterGroups = [],
  isVisibleReviewerName
}) => {
  const ConditionalInfoIconTooltip = withConditionalRender({ hide: !disabled }, IconButton);
  const { t } = useTranslation();
  const isCollapsedFilterGroup = collapsedFilterGroups.includes('tableReviewsFilters');
  const filterActiveUsers = Object.values(reviewers).filter(r => r.active);
  const clientInteractionsList = useSelector(state => state.clientInteractionsList);
  const checklistDefinitionsList = clientInteractionsList.filters.checklistDefinitionsIds;
  const isSelectedOneChecklist = checklistDefinitionsList && checklistDefinitionsList?.length === 1;
  const checklistDefinitionId = checklistDefinitionsList ? checklistDefinitionsList[0] : '';
  const checklistDefinitionsByIds = useSelector(
    state => getChecklistsDefinitionsByIds(state),
    isEqual
  );
  const checklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistQuestions = useSelector(
    state => getChecklistDefinitionQuestions(state, checklistDefinition),
    isEqual
  );

  const handleGetMaxScoreForQuestion = () => {
    if (filters.questionIds) {
      return checklistQuestions.find(question => question.id === filters.questionIds[0])?.max;
    }
  };

  const handleGetMinScoreForQuestion = () => {
    if (filters.questionIds) {
      return checklistQuestions.find(question => question.id === filters.questionIds[0])?.min;
    }
  };

  return (
    <>
      <Col span={24}>
        <CategoryNameBlock
          type="flex"
          align="middle"
          justify="space-between"
          onClick={() => toogleFilterGroup('tableReviewsFilters')}
        >
          <Col span={22}>
            <CategoryName>
              {t('clientInteractionsPage.tableFilters.tableReviewsFilters.title')}
              <ConditionalInfoIconTooltip
                tooltip={{
                  title: t('clientInteractionsPage.tableFilters.tableReviewsFilters.infoTooltip')
                }}
                button={{
                  icon: <Icon icon={InfoCircleOutlined} />,
                  size: 'icon',
                  style: { width: 24 }
                }}
              />
            </CategoryName>
          </Col>
          <Col span={2}>
            {isCollapsedFilterGroup ? <Icon icon={DownOutlined} /> : <Icon icon={RightOutlined} />}
          </Col>
        </CategoryNameBlock>
      </Col>

      {isCollapsedFilterGroup && (
        <Col span={24}>
          {/* Формы оценки */}
          <FilterContainer>
            <ChecklistDefinitionsSelect
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableReviewsFilters.form.checklistsPlaceholder'
              )}
              checklistDefinitions={checklistDefinitions}
              disabled={disabled}
              mode="multiple"
              style={{ width: '100%' }}
              loading={loadingCheckilstDefinitions}
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t(
                  'clientInteractionsPage.tableFilters.tableReviewsFilters.form.checklistsPlaceholder'
                )}: ${selectedKeys.length}`
              }
              allowClear
              onChange={checklistDefinitionsIds => {
                updateFilters({
                  checklistDefinitionsIds,
                  checklistScoreFrom: undefined,
                  checklistScoreTo: undefined,
                  questionIds: undefined,
                  questionScoreFrom: undefined,
                  questionScoreTo: undefined
                });
              }}
              value={filters.checklistDefinitionsIds || []}
              optionLabelProp="label"
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'searchValue' })
              }
            />
          </FilterContainer>

          {/* score */}
          <FilterContainer>
            <FilterName>
              {t('clientInteractionsPage.tableFilters.tableReviewsFilters.form.reviewResult')}
            </FilterName>
            <RangeInputs
              disabled={
                disabled
                // (filters.checklistDefinitionsIds && filters.checklistDefinitionsIds.length > 0)
              }
              value={{ from: filters.checklistScoreFrom, to: filters.checklistScoreTo }}
              onChange={({ from, to }) =>
                updateFilters({
                  checklistScoreFrom: isNil(from) ? undefined : `${from}`,
                  checklistScoreTo: isNil(to) ? undefined : `${to}`
                })
              }
              min={0}
              max={
                filters.checklistDefinitionsIds &&
                filters.checklistDefinitionsIds[0] &&
                get(get(checklistDefinitions, filters.checklistDefinitionsIds[0]), 'max')
              }
            />
          </FilterContainer>

          {/* question */}
          {isSelectedOneChecklist && (
            <FilterContainer>
              <Select
                style={{ width: '100%' }}
                placeholder={t(
                  'clientInteractionsPage.tableFilters.tableReviewsFilters.form.questions'
                )}
                onChange={questionIds => {
                  updateFilters({
                    questionIds: questionIds ? [questionIds] : [],
                    questionScoreFrom: undefined,
                    questionScoreTo: undefined
                  });
                }}
                value={filters.questionIds || []}
                optionLabelProp="searchValue"
                disabled={disabled}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'searchValue' })
                }
              >
                {checklistQuestions &&
                  checklistQuestions.map(question => (
                    <Option key={question.id} value={question.id} searchValue={question.name}>
                      <Text>{question.name}</Text>
                    </Option>
                  ))}
              </Select>
            </FilterContainer>
          )}

          {/* from to question */}
          {isSelectedOneChecklist && (
            <FilterContainer>
              <FilterName>
                {t('clientInteractionsPage.tableFilters.tableReviewsFilters.form.questionResult')}
              </FilterName>
              <RangeInputs
                disabled={disabled}
                value={{ from: filters.questionScoreFrom, to: filters.questionScoreTo }}
                onChange={({ from, to }) =>
                  updateFilters({
                    questionScoreFrom: isNil(from) ? undefined : `${from}`,
                    questionScoreTo: isNil(to) ? undefined : `${to}`
                  })
                }
                min={handleGetMinScoreForQuestion()}
                max={handleGetMaxScoreForQuestion()}
              />
            </FilterContainer>
          )}

          {/* reviewers */}
          {isVisibleReviewerName && (
            <FilterContainer>
              <SpecificUsersSelect
                placeholder={t(
                  'clientInteractionsPage.tableFilters.tableReviewsFilters.form.reviewAuthor'
                )}
                disabled={disabled}
                mode="multiple"
                style={{ width: '100%' }}
                onChange={reviewersIds => updateFilters({ reviewersIds })}
                updateFilters={updateFilters}
                items={filterActiveUsers}
                keyName="reviewersIds"
                allowClear
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${t(
                    'clientInteractionsPage.tableFilters.tableReviewsFilters.form.reviewAuthorShort'
                  )}: ${selectedKeys.length}`
                }
                loading={loadingUsers}
                value={filters.reviewersIds || []}
                optionLabelProp="label"
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'label' })
                }
                usersToSelect={filterActiveUsers}
                hideUserName
              />
            </FilterContainer>
          )}
          {/* review created_at */}
          <FilterContainer>
            <CustomDatePicker
              onChange={filters => {
                updateFilters({
                  reviewSymbolicTimeRange: filters.reviewSymbolicTimeRange,
                  reviewTimeFrom: filters.reviewTimeFrom,
                  reviewTimeTo: filters.reviewTimeTo
                });
              }}
              value={{
                symbolicTimeRange: filters.reviewSymbolicTimeRange,
                timeFrom: filters.reviewTimeFrom,
                timeTo: filters.reviewTimeTo
              }}
              mode={DATE_PICKER_MODES.review}
              isClientInteractionPage
              allowClear
              loading={loadingTaskDefinitions || loadingCheckilstDefinitions}
              disabled={disabled}
            />
          </FilterContainer>
          {/* flags and comments */}
          <FilterContainer>
            <Select
              disabled={disabled}
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableReviewsFilters.form.commentsRatingFlags'
              )}
              mode="multiple"
              style={{ width: '100%' }}
              onChange={commentsRatingFlags => updateFilters({ commentsRatingFlags })}
              value={filters.commentsRatingFlags || []}
              optionLabelProp="label"
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'searchValue' })
              }
            >
              <Option
                key="green"
                value="green"
                searchValue={t(FLAGS_COLORS.GREEN.title)}
                label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.green} />}
              >
                <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.green} />
              </Option>
              <Option
                key="yellow"
                value="yellow"
                searchValue={t(FLAGS_COLORS.YELLOW.title)}
                label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.yellow} />}
              >
                <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.yellow} />
              </Option>
              <Option
                key="red"
                value="red"
                searchValue={t(FLAGS_COLORS.RED.title)}
                label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.red} />}
              >
                <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.red} />
              </Option>
              <Option
                key="empty"
                value="empty"
                searchValue={t(FLAGS_COLORS.WITHOUT_FLAG.title)}
                label={<Icon icon={MessageFilled} color={FLAGS_TO_COLORS_RELATIONS.without_flag} />}
              >
                <Icon icon={MessageFilled} color={FLAGS_TO_COLORS_RELATIONS.without_flag} />
              </Option>
            </Select>
          </FilterContainer>

          {/* tasksDefinitions */}
          <FilterContainer>
            <Select
              placeholder={t(
                'clientInteractionsPage.tableFilters.tableReviewsFilters.form.tasksDefinitions'
              )}
              mode="multiple"
              style={{ width: '100%' }}
              allowClear
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys => `Теги: ${selectedKeys.length}`}
              onChange={taskDefinitionsIds => updateFilters({ taskDefinitionsIds })}
              loading={loadingTaskDefinitions}
              disabled={disabled || loadingTaskDefinitions}
              value={filters.taskDefinitionsIds || []}
              optionLabelProp="label"
              filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            >
              {taskDefinitions.map(({ id, labelName, labelColor }) => (
                <Option key={id} value={id} label={labelName}>
                  <CustomTag color={labelColor} text={labelName} />
                </Option>
              ))}
            </Select>
          </FilterContainer>

          {/* reviews count */}
          <FilterContainer>
            <FilterName>
              {t('clientInteractionsPage.tableFilters.tableReviewsFilters.form.reviewsCount')}
            </FilterName>
            <RangeInputs
              precision={0}
              min={filters.isReviewed === 'true' ? 1 : 0}
              value={{ from: filters.reviewsCountFrom, to: filters.reviewsCountTo }}
              onChange={({ from, to }) =>
                updateFilters({
                  reviewsCountFrom: isNil(from) ? undefined : `${from}`,
                  reviewsCountTo: isNil(to) ? undefined : `${to}`
                })
              }
              disabled={disabled}
            />
          </FilterContainer>
        </Col>
      )}

      {/* checklistDefinitions */}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const currentUserId = state.reduxTokenAuth.currentUser.attributes.id;
  const communicationTables = state.communicationTablesResource.byIds;
  const { filters } = state.clientInteractionsList;
  const { tableLoading, tableId, collapsedFilterGroups } = state.uiClientInteractions;
  const loadingUsers = state.usersResource.loading;

  const checklistDefinitions = getChecklistsDefinitionsByParams(state, {
    params: {
      unitsIds: filters.unitsIds,
      statuses: [
        CHECKLIST_DEFINITION_STATUS.ARCHIVED.value,
        CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
      ],
      communicationTypes: filters.communicationsTypes
    }
  });

  const disabled =
    filters.isReviewed !== 'true' &&
    currentUserId === get(communicationTables, `${tableId}.creatorId`, '');

  if (loadingUsers) return { filters, loadingUsers: true, checklistDefinitions, disabled };
  const isVisibleReviewerName = getCurrentUserPermissions(state).includes(
    PERMISSIONS.CAN_SEE_REVIEWER_NAME
  );

  const reviewers = getUsersWithPermissions(state, { permissions: [PERMISSIONS.CAN_MAKE_REVIEW] });

  return {
    loadingCheckilstDefinitions: state.checklistDefinitionsResource.loading,
    loadingTaskDefinitions: state.taskDefinitionsResource.loading,
    taskDefinitions: Object.values(state.taskDefinitionsResource.byIds),
    filters: ownProps?.hasConflicts ? {} : filters,
    checklistDefinitions,
    reviewers,
    loadingUsers,
    disabled:
      disabled ||
      tableLoading ||
      isEmpty(tableId) ||
      ownProps?.selectedWorkPlanTaskConfigurationId ||
      ownProps?.hasConflicts,
    collapsedFilterGroups,
    isVisibleReviewerName
  };
};

const mapDispatchToProps = {
  updateFilters: actions.updateFilters,
  toogleFilterGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(TableReviewsFilters);
