export default {
  workPlanTaskConfigurationEditor: {
    newTaskTitle: 'Новая задача',
    taskStarted: 'Задача запущена',
    taskSaved: 'Задача сохранена',
    deleteTask: 'Удалить',
    saveTask: 'Сохранить',
    startTask: 'Запустить',
    copyTask: 'Создать копию',
    taskInfo: {
      totalCommunications: 'всего коммуникаций',
      checkedOperators: 'проверяемых сотрудников',
      reviewers: 'проверяющих'
    },
    delete: {
      title: 'Удалить задачу?',
      taskDeleted: 'Задача удалена',
      cantDeleteTask: 'Не удалось удалить задачу'
    },
    schedule: {
      taskConfig: 'Общие настройки выборки',
      taskName: 'Название задачи',
      distribution: 'Распределение',
      distributionSettings: {
        communicationsNumberByEachOperator: 'Заданное кол-во по каждому сотруднику',
        communicationsPercentageByEachOperator: 'Заданный процент по каждому сотруднику',
        communicationsNumberByOperators: 'Заданное количество по группе сотрудников'
      },
      period: 'Период',
      timeFrom: 'Дата начала',
      timeTo: 'Дата завершения',
      repeatEvery: 'Повторять каждые',
      repeat: 'Повторения',
      toRepeat: 'Повторeние',
      dayTimeFrom: 'Время начала',
      dayTimeTo: 'Время завершения',
      periodForTask: 'Период выполнения задачи',
      periodTask: 'Период выполнения',
      date: 'Дата',
      time: 'Время',
      start: 'Начать',
      end: 'Завершить в',
      stopTask: 'Остановить задачу',
      customPeriod: 'Настраиваемый период',
      scheduleChangePeriodTask:
        'При изменении периода выполнения, тип повторения будет сброшен в значение "Не повторять"',
      info: {
        schedulePeriodTask: 'Период за который необходимо осуществить проверку выборки',
        scheduleStopTask: 'Выберите дату когда необходимо остановить повторение выборки'
      }
    },
    filters: {
      deleteFilters: 'Удалить фильтры',
      maxCount: 'Количество проверок',
      clientInteractionType: 'Тип коммуникации',
      addUsers: 'Проверить сотрудников',
      add: 'Добавить',
      usersAdded: 'Пользователей',
      addFilters: 'Добавить тип коммуникации',
      datesRange: 'Проверить даты',
      invalid:
        'Для того чтобы установить проверяющих, необходимо: добавить тип коммуникации, выбрать количество коммуникаций к проверке и выбрать сотрудников, которых необходимо проверять',
      valid: 'Для запуска задачи необходимо выбрать проверяющих'
    },
    clientInteractionFilters: {
      operators: 'Сотрудников',
      addFilter: 'Добавить фильтр',
      totalCountForAll: 'Общее кол-во по всем',
      setConfiguration: {
        operatorsToCheck: 'Выберите сотрудников для проверки',
        communicationsToCheck: 'Установите коммуникации на проверку',
        directionOfCommunications: 'Установите направление коммуникаций',
        durationOfCommunications: 'Установите длительность коммуникаций',
        communicationPartsCount: 'Установите количество сообщений'
      },
      deleteFiltersModalTitle: 'Удалить фильтры вместе с типом коммуникации?',
      quantityByOperator: 'Кол-во по сотруднику',
      percentageByOperator: '% по сотруднику',
      operatorsToReview: 'Сотрудники на пров-ку',
      searchFilter: 'Введите название фильтра'
    },
    reviewersWithoutPermissions:
      'Проверяющий не может быть добавлен к задаче т.к. не имеет прав доступа к записям одного или нескольких операторов.',
    changeTypeDistribution:
      'При изменении типа распределения, значения распределений для проверяющих установленные вручную будут сброшены. Продолжить?',
    distribution: {
      addReviewer: 'Добавить проверяющего',
      manual: 'Вручную',
      auto: 'Автоматически'
    },
    addUsersModal: {
      users: 'Сотрудники',
      unit: 'Отдел',
      role: 'Роль'
    }
  }
};
