import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import React from 'react';
import { useSelector } from 'react-redux';
import { orderBy, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import ObjectionComment from './ObjectionComment';
import { Space } from 'antd';

const QuestionObjectionCard = ({ appeal, question, objection, review }) => {
  const { t } = useTranslation();
  const appellationComments = useSelector(
    state =>
      Object.values(state.appellationCommentsResource.byIds).filter(
        appealComment =>
          appealComment?.questionId === question?.id && appealComment?.appellationId === appeal?.id
      ),
    isEqual
  );

  const commentsByIds = useSelector(state => state.commentsResource.byIds, isEqual);

  const reviewComments = review?.commentsIds.reduce((acc, reviewCommentId) => {
    const reviewComment = commentsByIds[reviewCommentId];
    if (reviewComment?.metadata?.questionId !== question.id) return acc;

    return [...acc, reviewComment];
  }, []);

  const allComments = orderBy([...appellationComments, ...reviewComments], 'createdAt');

  return (
    <SRow backgroundColor="#eff3fe" borderRadius="8px" border="1px solid #839df2">
      <SCol span={24} padding="16px">
        <SText fontSize="16px">{`${t('general.criterion')}: `}</SText>
        <SText fontSize="16px" fontWeight={500} marginRight="10px">
          {question?.name}
        </SText>
        <SText fontSize="16px" color="var(--blue_6)">
          {`Оценка: ${objection?.answerBefore}`}
        </SText>
      </SCol>
      <SCol
        span={24}
        background="#ffffff"
        padding="16px"
        borderBottomLeftRadius="inherit"
        borderBottomRightRadius="inherit"
      >
        <Space value={4} direction="vertical" style={{ width: '100%' }}>
          {allComments.map(comment => (
            <ObjectionComment key={comment.id} comment={comment} appeal={appeal} />
          ))}
        </Space>
      </SCol>
    </SRow>
  );
};

export default QuestionObjectionCard;
