import { Col, Row, Select, Tooltip, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import UserPreview from 'components/UserPreview';
import moment from 'core/moment';
import {
  CLIENT_INTERACTION_DIRECTIONS,
  CLIENT_INTERACTIONS_TYPES,
  CLIENT_INTERACTIONS_TYPES_LITERALS,
  PERMISSIONS
} from 'core/utils/constants';
import { isEqual, isEmpty, get, findIndex, compact } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCol from 'components/Standard/SCol';
import { operations } from 'redux/lists/usersListReducer';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import { getCurrentUserPermissions, getUsersWithPermissions } from 'redux/selectors/users';
import SCard from 'components/Standard/SCard';
import pluralize from 'pluralize';
import { IconButton } from 'components/Buttons';
import { StyledSearch, StyledSelect, StyledCol } from 'components/PhoneCallPlayer/styled';
import { useHistory } from 'react-router-dom';
import { utils } from 'react-media-player';
import { Info } from '../Info';
import SText from '../Standard/SText';

const { Text } = Typography;

const loadUsers = ({ dispatch }) =>
  dispatch(
    operations.load({
      pagination: 'false',
      include: 'unit,role.levels',
      sort: 'last_name'
    })
  );

const CustomSelectDropdown = React.memo(({ menu, onSearch, onChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoading = useSelector(state => state.usersResource.loading);

  useEffect(() => {
    loadUsers({ dispatch });
  }, []);

  return (
    <SCard
      loading={userLoading}
      headPadding="0 12px"
      bodyPadding="0px"
      rounded="4px"
      shadowed
      width="100%"
      maxWidth="340px"
      minWidth="340px"
      title={
        <StyledSearch
          allowClear
          placeholder={t('components.recordPlayer.phoneCallInformation.searchPlaceholder')}
          onSearch={value => onSearch && onSearch(value)}
          onChange={e => onChange && onChange(e.target.value)}
        />
      }
    >
      {menu}
    </SCard>
  );
});

const PhoneCallInformation = ({ call, fromNewReview = false }) => {
  const history = useHistory();
  const [search, setSearch] = React.useState('');
  const operator = useSelector(state => state.usersResource.byIds[call?.operatorId], isEqual);
  const { loading: userLoading, failed } = useSelector(state => state.usersResource);
  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);
  // const rolesByIds = useSelector(state => state.rolesResource.byIds, isEqual);
  const reviewersById = useSelector(state =>
    getUsersWithPermissions(state, { permissions: [PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION] })
  );
  const reviewers = Object.values(reviewersById).filter(({ active }) => active);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // * hacking BE properties
  const callType = (
    call?.clientInteractionType ||
    call?.communicationType ||
    pluralize.singular(call?.type || '')
  ).replace('-', '_');

  const canChangeOperator =
    currentUserPermissions.includes(PERMISSIONS.CAN_UPDATE_CLIENT_INTERACTION_OPERATOR) &&
    CLIENT_INTERACTIONS_TYPES.PHONE_CALL === fromNewReview
      ? call?.communicationType
      : callType && get(history, 'location.pathname', '') !== '/library';

  const onSelectNewOperator = userId => {
    dispatch(
      clientInteractionsResource.operations.updateClientInteractionOperator({
        id: call.id,
        operatorId: userId
      })
    );
  };

  const addOperatorToReviewers = findIndex(reviewers, ({ id }) => id === operator?.id) === -1;

  const getSlicedContentWithTooltip = value => {
    if (!value) {
      return '';
    }
    const currentLength = value?.length; // Длина переданной строки
    const maxLength = 15; // Ограничение длины строки в 15 символов
    const substring = `${value.substring(0, maxLength)}...`; // Выводиться первые 15 символов с (...) в конце
    const stringToRender = currentLength > maxLength ? substring : value; // Строка которая пойдёт на рендер
    return (
      <Tooltip title={value}>
        <SText ellipsis>{stringToRender}</SText>
      </Tooltip>
    );
  };

  return (
    <Row
      type="flex"
      align="middle"
      justify="space-between"
      gutter={[16, 0]}
      style={{ marginBottom: 6 }}
    >
      <Col span={24}>
        <Row type="flex" align="start" justify="start" gutter={[16, 0]}>
          <SCol display="flex" paddingRight="24px !important">
            {canChangeOperator ? (
              <>
                <StyledSelect
                  onClick={() => {
                    if (failed) {
                      loadUsers({ dispatch });
                    }
                  }}
                  onChange={onSelectNewOperator}
                  defaultValue={
                    <UserPreview
                      userId={operator.id}
                      user={operator}
                      disabled
                      showAvatar
                      showUnit
                    />
                  }
                  value={operator.id}
                  disabled={!canChangeOperator}
                  key={operator.id}
                  loading={userLoading}
                  bordered={false}
                  showArrow={canChangeOperator}
                  dropdownMatchSelectWidth={false}
                  getPopupContainer={node => node.parentNode}
                  dropdownClassName="change-operator-dropdown"
                  dropdownStyle={{ padding: 0 }}
                  dropdownRender={menu => (
                    <CustomSelectDropdown menu={menu} onSearch={setSearch} onChange={setSearch} />
                  )}
                >
                  {compact([addOperatorToReviewers && operator, ...reviewers])
                    .filter(
                      ({ name }) =>
                        isEmpty(search) || name?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0
                    )
                    .map(({ id }) => (
                      <Select.Option key={id} value={id}>
                        <UserPreview
                          userId={id}
                          user={reviewersById[operator]}
                          disabled
                          showAvatar
                          showUnit
                        />
                      </Select.Option>
                    ))}
                </StyledSelect>
                <IconButton
                  tooltip={{
                    title: t('components.recordPlayer.phoneCallInformation.tooltipButtonInfo'),
                    overlayInnerStyle: {
                      width: '400px'
                    }
                  }}
                  button={{
                    icon: <QuestionCircleOutlined style={{ fontSize: 16 }} />,
                    size: 'icon'
                  }}
                />
              </>
            ) : (
              operator?.id && (
                <UserPreview userId={operator.id} user={operator} disabled showAvatar showUnit />
              )
            )}
          </SCol>
          <StyledCol>
            <Text>
              <Text strong>{t('components.recordPlayer.phoneCallInformation.callDate')}</Text>
              {moment(call.startedAt).format('DD/MM/YYYY, HH:mm')}
            </Text>
          </StyledCol>
          <StyledCol>
            <Text strong>{t('components.recordPlayer.phoneCallInformation.callType')}</Text>
            <Text>
              {call.direction === 'incoming'
                ? t(CLIENT_INTERACTION_DIRECTIONS.INCOMING.title)
                : t(CLIENT_INTERACTION_DIRECTIONS.OUTCOMING.title)}
            </Text>
          </StyledCol>
          <StyledCol>
            <Text>
              <Text strong>{t('components.recordPlayer.phoneCallInformation.callNumber')}</Text>
              {getSlicedContentWithTooltip(call?.clientPhoneNumber)}
            </Text>
          </StyledCol>
          {call?.duration && (
            <StyledCol>
              <Text>
                <Text strong>{`${t('customCommunicationPage.form.fields.duration')}: `}</Text>
                <Text>{utils.formatTime(call?.duration)}</Text>
              </Text>
            </StyledCol>
          )}
          {fromNewReview && (
            <>
              {call?.communicationType && (
                <StyledCol>
                  <Text>
                    <Text strong>
                      {t('components.recordPlayer.phoneCallInformation.communicationType')}
                    </Text>
                    {t(CLIENT_INTERACTIONS_TYPES_LITERALS[call.communicationType])}
                  </Text>
                </StyledCol>
              )}
              {call?.metadata?.additional && (
                <StyledCol>
                  <Text>
                    <Text strong>
                      {t('components.recordPlayer.phoneCallInformation.additionally')}
                    </Text>
                    {getSlicedContentWithTooltip(call.metadata.additional)}
                  </Text>
                </StyledCol>
              )}
              {call?.communicationId && (
                <StyledCol>
                  <Info
                    iconProps={{ margin: '0 5px 0 0' }}
                    tooltip={{
                      title: t('customCommunicationPage.form.messages.communicationIdTooltip')
                    }}
                  />
                  <Text>
                    <Text strong>
                      {t('components.recordPlayer.phoneCallInformation.communicationID')}
                    </Text>
                    {getSlicedContentWithTooltip(call.communicationId)}
                  </Text>
                </StyledCol>
              )}
              {call?.metadata?.mediaUrls?.length > 0 && (
                <StyledCol>
                  <Text>
                    <Text strong>
                      {t('components.recordPlayer.phoneCallInformation.attachedFile')}
                    </Text>
                    <Text
                      style={{ cursor: 'pointer', color: '#597ef7' }}
                      onClick={() => window.open(call.metadata.mediaUrls[0])}
                    >
                      Открыть
                    </Text>
                  </Text>
                </StyledCol>
              )}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PhoneCallInformation;
