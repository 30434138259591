import styled from 'styled-components';

export const SPlayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background-color: #fff;
  width: 450px;
`;
