import { INTEGRATIONS } from 'core/utils/constants';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import api from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import { saveBackgroundJob } from '../backgroundJob/operations';

export const createUisIntegration = ({ name, clientSecret, endpoints }) => async dispatch => {
  try {
    const integration = await dispatch(
      integrationsResource.operations.create({
        name,
        clientSecret,
        endpoint: endpoints,
        integrationType: INTEGRATIONS.uis.type
      })
    );
    dispatch(saveBackgroundJob(integration?.currentBackgroundJob));

    return integration?.id;
  } catch (error) {
    console.log('createUisIntegration Error', { error });
  }
};

export const syncDataWithUis = ({ id, fetchFrom }) => async dispatch => {
  const response = await api.uisSyncDataByPeriod(
    { id },
    decamelizeKeys({
      fetchFrom
    })
  );

  const backgroundJob = processJsonApiObject(response.body.data);

  return dispatch(saveBackgroundJob(backgroundJob));
};
