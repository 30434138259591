import { dashboardsResource } from 'redux/resources/dashboards';
import { usersResource } from 'redux/resources/users';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { find, isEmpty, last } from 'lodash';
import { getDashboardAnalytics } from 'redux/entities/analytics/dashboard/operations';
import { setActiveDashboard } from 'redux/ui/dashboard/reducer';
import {
  loadDashboardAnalyticsStarted,
  loadDashboardAnalyticsSucceed
} from 'redux/entities/analytics/dashboard/reducer';
import { commentsResource } from '../../resources/comments';

export const loadDashboards = () => {
  return async dispatch => {
    dispatch(loadDashboardAnalyticsStarted());

    const dashboardsByIds = await dispatch(
      dashboardsResource.operations.load({ include: 'rows.widgets' })
    );

    if (!isEmpty(dashboardsByIds?.resources)) {
      const activeDashboard = find(dashboardsByIds?.resources, ['active', true]);

      if (!activeDashboard) {
        await dispatch(
          dashboardsResource.operations.updateById({
            ...Object.values(dashboardsByIds?.resources)[0],
            active: true
          })
        );
      }

      dispatch(setActiveDashboard(activeDashboard));

      const results = await Promise.all([
        dispatch(
          usersResource.operations.load({
            include: 'unit,role,level',
            pagination: 'false',
            'filters[with_inactive]': 'true',
            sort: 'last_name'
          })
        ),
        dispatch(
          checklistDefinitionsResource.operations.load({
            include: 'question_groups.question_to_group_bindings.question',
            pagination: 'false'
          })
        ),
        dispatch(
          taskDefinitionsResource.operations.load({
            pagination: 'false'
          })
        ),
        dispatch(getDashboardAnalytics({ id: activeDashboard?.id, activeDashboard }))
      ]);

      const analytics = last(results);

      if (analytics) {
        dispatch(loadDashboardAnalyticsSucceed(analytics));
      }
    }
    if (isEmpty(dashboardsByIds?.resources)) {
      dispatch(loadDashboardAnalyticsSucceed({}));
    }
    return dashboardsByIds;
  };
};

export const createDashboard = ({ ...payload }) => {
  return async dispatch => {
    dispatch(loadDashboardAnalyticsStarted());
    const newDashboard = await dispatch(dashboardsResource.operations.create({ ...payload }));
    const dashboardsByIds = await dispatch(
      dashboardsResource.operations.load({ include: 'rows.widgets' })
    );
    const activeDashboard = find(dashboardsByIds?.resources, ['active', true]);

    const results = await Promise.all([
      dispatch(
        usersResource.operations.load({
          include: 'unit,role',
          pagination: 'false',
          'filters[with_inactive]': 'true',
          sort: 'last_name'
        })
      ),
      dispatch(
        checklistDefinitionsResource.operations.load({
          include: 'question_groups.question_to_group_bindings.question',
          pagination: 'false'
        })
      ),
      dispatch(
        taskDefinitionsResource.operations.load({
          pagination: 'false'
        })
      ),
      dispatch(getDashboardAnalytics({ id: activeDashboard?.id, activeDashboard }))
    ]);

    const analytics = last(results);

    if (analytics) {
      dispatch(loadDashboardAnalyticsSucceed(analytics));
    }

    if (!isEmpty(dashboardsByIds?.resources) && activeDashboard) {
      await dispatch(setActiveDashboard(activeDashboard));
    }
    return newDashboard;
  };
};

export const updateDashboard = ({ ...payload }) => {
  return async dispatch => {
    const updatedDashboard = await dispatch(
      dashboardsResource.operations.updateById({ ...payload })
    );
    const dashboardsByIds = await dispatch(
      dashboardsResource.operations.load({ include: 'rows.widgets' })
    );
    const activeDashboard = find(dashboardsByIds?.resources, ['active', true]);
    if (!isEmpty(dashboardsByIds?.resources) && activeDashboard) {
      dispatch(setActiveDashboard(activeDashboard));

      dispatch(loadDashboardAnalyticsStarted());

      const analytics = await dispatch(
        getDashboardAnalytics({ id: activeDashboard?.id, activeDashboard })
      );
      if (analytics) {
        dispatch(loadDashboardAnalyticsSucceed(analytics));
      }
    }
    return updatedDashboard;
  };
};

export const deleteDashboard = ({ id }) => {
  return async dispatch => {
    await dispatch(dashboardsResource.operations.deleteById({ id }));
    const dashboardsByIds = await dispatch(
      dashboardsResource.operations.load({ include: 'rows.widgets' })
    );
    const activeDashboard = find(dashboardsByIds?.resources, ['active', true]);

    if (!isEmpty(dashboardsByIds?.resources) && activeDashboard) {
      dispatch(setActiveDashboard(activeDashboard));

      dispatch(loadDashboardAnalyticsStarted());

      const analytics = await dispatch(
        getDashboardAnalytics({ id: activeDashboard?.id, activeDashboard })
      );

      if (analytics) {
        dispatch(loadDashboardAnalyticsSucceed(analytics));
      }
    }
  };
};
