/* eslint-disable camelcase */
import { createReduxResource } from 'core/redux/resources';
import decamelize from 'decamelize-keys-deep';
import api from 'core/api';
import { updateResource } from 'core/redux/resources/updateResource';

export const folderItemsResource = createReduxResource({
  name: 'folderItems',
  customOperations: {
    create: {
      callEndpoint: requestOptions => {
        const { folder_id } = requestOptions;
        return api.createFolderItem({ folder_id }, decamelize(requestOptions));
      },
      onSuccess: ({ result, dispatch }) => {
        return result;
      }
    },
    delete: {
      callEndpoint: requestOptions => {
        return api.deleteFolderItem(decamelize(requestOptions));
      },
      onSuccess: ({ result, dispatch, getState, requestOptions }) => {
        const state = getState();
        const folder = state.foldersResource.byIds[requestOptions.folderId];

        if (folder) {
          updateResource({
            dispatch,
            type: 'folders',
            resource: {
              ...folder,
              itemsIds: [...folder.itemsIds].filter(id => id !== requestOptions.id)
            }
          });
        }
        return result;
      }
    }
  }
});
