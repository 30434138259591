import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Input, message, Radio, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { debounce, get, isEmpty } from 'lodash';
import { setEditingPromptWithBinding, updateEditingPrompt } from 'redux/ui/promptModal/reducer';
import { selectSearch } from 'core/utils/selectSearch';
import { promptsResource } from 'redux/resources/prompts';
import { operations as promptsQuestionAllListOperations } from 'redux/lists/promptsQuestionsAllList';
import { operations as promptsCustomFieldAllListOperations } from 'redux/lists/promptsCustomFieldsAllList';
import SModal from '../../Standard/SModal';
import LabelWithInfo from '../../LabelWithInfo';

const { Option } = Select;

const labelCol = { md: { offset: 0, span: 10 } };
const inputCol = { md: { span: 14 } };

const defaultPrompt = {};

let freeQuestionsFromAll = [];
let occupiedQuestionsFromAll = [];
let promptQuestionId = [];
let freeCustomFieldsFromAll = [];
let occupiedCustomFieldsFromAll = [];
let promptCustomFieldsIds = [];
// const defaultCategory = 'uncategorized';

const PromptModal = ({ onSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [promptsAPIloading, setPromptsAPILoading] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  const { byIds: customFieldsByIds } = useSelector(state => state.customFieldsResource);
  const allQuestions = useSelector(state => state.promptsQuestionsAllResource.byIds);
  const allCustomFields = useSelector(state => state.promptsCustomFieldsAllResource.byIds);
  const prompts = useSelector(state => state.promptsResource.byIds);
  const { prompt = defaultPrompt } = useSelector(state => state.uiPromptModal, shallowEqual);
  const { byIds } = useSelector(state => state.promptsResource, shallowEqual);
  const isNewQuestion = isEmpty(get(byIds, prompt.id, false));
  const connected = !isEmpty(prompts);

  const closeModal = useCallback(() => {
    dispatch(setEditingPromptWithBinding({ prompt: defaultPrompt }));
    setSelectedQuestions([]);
    setSelectedCustomFields([]);
  }, [dispatch]);

  // all questions
  freeQuestionsFromAll = Object.values(allQuestions).filter(item => item.availability === 'free');
  if (Object.prototype.hasOwnProperty.call(prompt, 'questions')) {
    // if (prompt.hasOwnProperty('questions')) {
    promptQuestionId = prompt.questions.map(item => item.questionId);
    // already selected question
    occupiedQuestionsFromAll = Object.values(allQuestions).filter(item => {
      return prompt.questions.some(question => question.questionId === item.id);
    });
  }
  // all custom fields
  freeCustomFieldsFromAll = Object.values(allCustomFields).filter(
    item => item.availability === 'free'
  );
  if (Object.prototype.hasOwnProperty.call(prompt, 'customFields')) {
    // if (prompt.hasOwnProperty('customFields')) {
    promptCustomFieldsIds = prompt.customFields.map(item => item.custom_field_id);
    // already selected custom fields
    occupiedCustomFieldsFromAll = Object.values(allCustomFields).filter(item => {
      return prompt.customFields.some(customField => customField.custom_field_id === item.id);
    });
  }
  const {
    id = '',
    name = '',
    value = '',
    bindingType = '',
    category,
    questions = [],
    customFields = []
  } = prompt;

  const questionsIds = questions.map(question => question.questionId);
  const customFieldsIds = customFields.map(customField => customField.custom_field_id);
  const initialValues = {
    id,
    name,
    value,
    bindingType,
    category,
    questionsIds,
    customFieldsIds
  };

  // default values
  useEffect(() => {
    if (prompt?.id) {
      form.setFieldsValue(initialValues);
    }
  }, [prompt?.id]);

  let filteredDeSelectedCustomFields = [];
  if (!isEmpty(selectedCustomFields)) {
    filteredDeSelectedCustomFields = customFieldsIds.filter(
      item => !selectedCustomFields.includes(item)
    );
  }

  const loadUpdatedData = () => {
    dispatch(
      promptsQuestionAllListOperations.load({
        organization_id: organizationId,
        type: 'questions',
        availability: 'all_availability'
      })
    );
    dispatch(
      promptsCustomFieldAllListOperations.load({
        organization_id: organizationId,
        type: 'custom_fields',
        availability: 'all_availability'
      })
    );
  };

  const clearData = () => {
    freeQuestionsFromAll = [];
    occupiedQuestionsFromAll = [];
    promptQuestionId = [];
    freeCustomFieldsFromAll = [];
    occupiedCustomFieldsFromAll = [];
    promptCustomFieldsIds = [];
  };

  const getPromptActions = (promptData, isCreatePrompt = false) => {
    const promptActions = {};
    // @todo добавить active status
    // if (promptData.category !== defaultCategory) {
    //   actionsObject.update_category = promptData.category;
    // }
    if (!isCreatePrompt) {
      // Обновить название
      promptActions.update_name = promptData.name;
      // Обновить значение
      promptActions.update_value = promptData.value;
    }
    if (
      promptData.customFieldsByIds !== customFieldsByIds &&
      promptData.questionsIds !== questionsIds
    ) {
      const filteredSelectedCustomFields =
        (selectedCustomFields &&
          selectedCustomFields.filter(item => !customFieldsIds.includes(item))) ||
        [];
      // Delete on a cross question
      if (!isCreatePrompt && !isEmpty(promptQuestionId) && isEmpty(promptData.questionsIds)) {
        promptActions.delete_questions = promptQuestionId;
      }
      // Delete on a cross custom fields
      if (
        !isCreatePrompt &&
        !isEmpty(promptCustomFieldsIds) &&
        isEmpty(promptData.customFieldsIds)
      ) {
        promptActions.delete_custom_fields = promptCustomFieldsIds;
      }
      // Actions for delete/add
      if (!isEmpty(selectedQuestions) && !promptQuestionId.includes(selectedQuestions)) {
        // add question
        promptActions.add_questions = [selectedQuestions];
        if (!isCreatePrompt && !isEmpty(prompt.questions) && !isEmpty(promptQuestionId)) {
          // delete question
          promptActions.delete_questions = promptQuestionId;
        }
      }
      if (!isEmpty(filteredSelectedCustomFields)) {
        // add custom fields
        promptActions.add_custom_fields = filteredSelectedCustomFields;
      }
      if (!isCreatePrompt && !isEmpty(filteredDeSelectedCustomFields)) {
        // delete custom fields
        promptActions.delete_custom_fields = filteredDeSelectedCustomFields;
      }
    }
    return promptActions;
  };

  const updatePrompt = async promptData => {
    const promptActions = getPromptActions(promptData);
    if (!isEmpty(promptActions)) {
      await dispatch(
        promptsResource.operations.updateById({
          id: promptData.id,
          actions: promptActions
        })
      )
        .then(() => {
          message.success(
            `${t('components.promptsList.messages.update.name')} '${promptData.name}' ${t(
              'components.promptsList.messages.update.success'
            )} `
          );
          loadUpdatedData();
          clearData();
          closeModal();
        })
        .catch(error => {
          console.log('error', error);
          closeModal();
        });
    } else {
      closeModal();
    }
  };

  const createPrompt = promptData => {
    const promptActions = getPromptActions(promptData, true);
    const payload = {
      ...prompt,
      actions: promptActions
    };
    const data = !isEmpty(promptActions) ? payload : prompt;
    // console.log(
    //   'promptData', promptData,
    //   '\promptActions', promptActions,
    //   '\npayload', payload,
    // );
    setPromptsAPILoading(true);
    onSubmit({ prompt: data, setPromptsAPILoading })
      .then(() => {
        loadUpdatedData();
        clearData();
        setPromptsAPILoading(false);
      })
      .catch(err => {
        console.log('error', err);
        setPromptsAPILoading(false);
      });
  };

  const onValuesChange = debounce((changedValues, allValues) => {
    const { ...newPrompt } = allValues;
    if (connected) {
      dispatch(updateEditingPrompt(newPrompt));
    }
    return dispatch(updateEditingPrompt(newPrompt));
  }, 250);

  // @todo локализация
  return (
    <SModal
      size="big"
      destroyOnClose
      maskClosable
      visible={!isEmpty(prompt)}
      title={t('components.promptsList.promptModal.title')}
      width={900}
      confirmLoading={promptsAPIloading}
      onCancel={closeModal}
      onOk={form.submit}
      cancelText={<></>}
      okText={isNewQuestion ? t('general.save') : t('general.update')}
    >
      <Form
        labelCol={labelCol}
        labelAlign="left"
        wrapperCol={inputCol}
        onFinish={isNewQuestion ? createPrompt : updatePrompt}
        form={form}
        colon={false}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
        scrollToFirstError
      >
        <Form.Item style={{ display: 'none' }} name="id" />
        <Form.Item
          label={
            <LabelWithInfo infoTitle={t('components.promptsList.form.fields.fieldName.infoTitle')}>
              {t('components.promptsList.form.fields.fieldName.title')}
            </LabelWithInfo>
          }
          name="name"
          rules={[
            {
              required: true,
              message: t('components.promptsList.form.fields.validateMessage'),
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder={t('components.promptsList.form.fields.fieldName.placeholder')}
            // disabled={!isNewQuestion}
          />
        </Form.Item>
        <Form.Item
          label={
            <LabelWithInfo infoTitle={t('components.promptsList.form.fields.fieldValue.infoTitle')}>
              {t('components.promptsList.form.fields.fieldValue.title')}
            </LabelWithInfo>
          }
          name="value"
          rules={[
            {
              required: true,
              message: t('components.promptsList.form.fields.validateMessage'),
              whitespace: true
            }
          ]}
        >
          <TextArea
            placeholder={t('components.promptsList.form.fields.fieldValue.placeholder')}
            // disabled={!isNewQuestion}
          />
        </Form.Item>
        {isNewQuestion && (
          <Form.Item
            label={<LabelWithInfo infoTitle="Тип">Тип</LabelWithInfo>}
            name="bindingType"
            rules={[
              {
                required: true,
                message: 'Пожалуйста выберите один из вариантов',
                whitespace: true
              }
            ]}
          >
            <Radio.Group
              style={{ width: '100%' }}
              onChange={() => {
                setSelectedQuestions(null);
                setSelectedCustomFields(null);
              }}
            >
              <Row type="flex">
                <Col span={8}>
                  <Radio value="question">Критерии</Radio>
                </Col>
                <Col span={8}>
                  <Radio value="custom_field">Кастомные поля</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
        )}
        {/* @todo add category and active status */}
        {/* <Form.Item */}
        {/*  label={ */}
        {/*    <LabelWithInfo */}
        {/*      infoTitle={t('components.promptsList.form.fields.fieldCategory.infoTitle')} */}
        {/*    > */}
        {/*      {t('components.promptsList.form.fields.fieldCategory.title')} */}
        {/*    </LabelWithInfo> */}
        {/*  } */}
        {/*  name="category" */}
        {/*  rules={[ */}
        {/*    { */}
        {/*      whitespace: true */}
        {/*    } */}
        {/*  ]} */}
        {/* > */}
        {/*  <Input placeholder={t('components.promptsList.form.fields.fieldCategory.placeholder')} /> */}
        {/* </Form.Item> */}
        {bindingType === 'question' && (
          // {!isNewQuestion && bindingType === 'question' && (
          <Form.Item
            label={
              <LabelWithInfo
                infoTitle={t('components.promptsList.form.fields.fieldQuestions.infoTitle')}
              >
                {t('components.promptsList.form.fields.fieldQuestions.title')}
              </LabelWithInfo>
            }
            name="questionsIds"
          >
            <Select
              placeholder={t('components.promptsList.form.fields.fieldQuestions.title')}
              mode="default"
              onChange={questionItem => setSelectedQuestions(questionItem)}
              value={occupiedQuestionsFromAll}
              optionLabelProp="label"
              allowClear
            >
              {occupiedQuestionsFromAll.map(item => (
                <Option value={item.id} key={item.id} label={item.name}>
                  {item.name}
                </Option>
              ))}
              {freeQuestionsFromAll.map(item => (
                <Option value={item.id} key={item.id} label={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {bindingType === 'custom_field' && (
          // {!isNewQuestion && bindingType === 'custom_field' && (
          <Form.Item
            label={
              <LabelWithInfo
                infoTitle={t('components.promptsList.form.fields.fieldCustomFields.infoTitle')}
              >
                {t('components.promptsList.form.fields.fieldCustomFields.title')}
              </LabelWithInfo>
            }
            name="customFieldsIds"
          >
            <Select
              placeholder={t('components.promptsList.form.fields.fieldCustomFields.title')}
              mode="multiple"
              onChange={customFieldItem => setSelectedCustomFields(customFieldItem)}
              value={occupiedCustomFieldsFromAll}
              optionLabelProp="label"
              allowClear
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t('components.promptsList.form.fields.fieldCustomFields.title')}: ${
                  selectedKeys.length
                }`
              }
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {occupiedCustomFieldsFromAll.map(item => (
                <Option
                  value={item.id}
                  key={item.id}
                  label={t('components.promptsList.form.fields.fieldCustomFields.title')}
                >
                  {t(item.name)}
                </Option>
              ))}
              {freeCustomFieldsFromAll.map(item => (
                <Option
                  value={item.id}
                  key={item.id}
                  label={t('components.promptsList.form.fields.fieldCustomFields.title')}
                >
                  {t(item.name)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </SModal>
  );
};

export default PromptModal;
