export default {
  general: {
    save: 'Salva',
    cancel: 'Annulla',
    canceling: 'Annulla',
    edit: 'Modificare',
    answer: 'Rispondere',
    delete: 'Elimina',
    disagree: "Non sono d'accordo",
    accept: 'Accettare',
    accepted: 'Accettare',
    reject: 'Rifiutare',
    rejected: 'Rifiutare',
    from: 'Da',
    to: 'A',
    time: 'Tempo',
    hidden: 'Nascosto',
    visibleToAll: 'Disponibile per tutti',
    update: 'Aggiornare',
    add: 'Aggiungere',
    redFlag: 'Rosso',
    yellowFlag: 'Giallo',
    greenFlag: 'Verde',
    withoutFlag: 'Senza bandiera',
    uploadImage: 'Allega immagine',
    loading: 'Caricamento in corso',
    requiredField: 'Campo obbligatorio',
    continue: 'Continuare',
    date: 'Data',
    dateAndTime: 'Data e ora',
    direction: 'Direzione',
    number: 'Numero',
    phoneNumber: 'Numero di telefono',
    tags: 'Tag',
    tag: 'Tag',
    reviewDate: 'Data di valutazione',
    clientInteractionDate: 'Data di comunicazione',
    applicationDate: 'Data di applicazione',
    applicator: 'Archiviato',
    checklistDefinition: 'Modulo di valutazione',
    checklistDefinitions: 'Moduli di valutazione',
    checklistDefinitionPlaceholder: 'Scegli un modulo di valutazione',
    selectChecklist: "Scegliere una forma di valutazione per iniziare l'ispezione",
    criterion: 'Criterio',
    criteries: 'Criteri',
    clientInteractionType: 'Tipo',
    clientInteractionTypes: 'Tipi',
    status: 'Stato',
    statuses: 'Stati',
    users: 'Utenti',
    executor: 'Esecutore',
    executors: 'Esecutori',
    role: 'Ruolo',
    roles: 'Ruoli',
    changed: 'Cambiato',
    change: 'cambiato',
    view: 'Guadare',
    unit: 'Dipartimento',
    units: 'Dipartimenti',
    settings: 'Impostazioni',
    analytics: 'Analitica',
    yes: 'Si',
    no: 'No',
    name: 'Nome e patronimico',
    lastName: 'Cognome',
    email: 'E-mail',
    goTo: 'Vai a',
    employees: 'Dipendenti',
    employee: 'Dipendenti',
    all: 'Tutti',
    initials: 'Nome e cognome',
    duration: 'Durata',
    score: 'Valutazione',
    flagsAndComments: 'Bandiere e commenti',
    close: 'Finire',
    stop: 'Fermare',
    download: 'Scarica',
    reviewer: 'Verificatore',
    reviewers: 'Revisori',
    total: 'Totale',
    author: 'Autore',
    operator: 'Operatore',
    result: 'Risultato',
    found: 'Fondare',
    comments: 'Commenti',
    title: 'Nome',
    start: 'Cominciare',
    finish: 'Completamento',
    create: 'Creare',
    created: 'Creato',
    selected: 'Selezionato',
    progress: 'Progresso',
    apply: 'Accettare',
    period: 'Periodo',
    communication: 'comunicazione',
    review: 'valutazione',
    startOfPeriod: 'Data di fine',
    endOfPeriod: 'Data di inizio',
    displayByDate: 'Visualizzazione per data: ',
    systemMessages: 'Messaggi di sistema',
    areHidden: 'sono nascosti',
    opened: 'aperti',
    showDetails: 'Mostra dettagli',
    empty: 'Non ci sono dati',
    goBack: 'Dietro',
    send: 'Inviare',
    closeText: 'Chiudere'
  }
};
