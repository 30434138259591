import React from 'react';
import QuestionDetailsModal from 'components/Questions/QuestionDetailsModal';
import SCol from 'components/Standard/SCol';
import { ANALYTICS_WIDGETS_NAMES, RATING_MODE } from 'core/utils/constants';
import { get, isEqual, mean, toUpper, uniqWith } from 'lodash';
import { customEveryByProps } from 'core/utils/customEvery';
import { useSelector } from 'react-redux';
import { getQuestionChecklistDefinitions } from 'redux/selectors/checklistItems/checklistItems';
import SRow from 'components/Standard/SRow';
import ChecklistDefinitionAnalytics from './ChecklistDefinitionAnalytics';
import EmptyForUserPage from '../EmptyForUserPage';
import AllQuestionGroups from '../AllQuestionGroups';

const AllQuestionsAnalytics = ({ loadingWidgets, widgets, userId }) => {
  const questionsByIds = useSelector(state => state.questionsResource.byIds);
  const items = get(widgets, [ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_AVERAGE_BY_QUESTIONS], {});

  const analyticsQuestions = Object.keys(
    get(widgets, ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COLOR_ZONE_BY_QUESTIONS, {})
  ).map(questionId => questionsByIds[questionId]);

  const checklistDefinitions =
    uniqWith(
      useSelector(state =>
        analyticsQuestions.reduce(
          (acc, question) => [...acc, ...getQuestionChecklistDefinitions(state, question)],
          []
        )
      ),
      (a, b) => isEqual(a.id, b.id)
    ) || [];

  const checklistDefinitionsWithSameRatingMode = customEveryByProps(
    checklistDefinitions,
    'ratingMode'
  );

  const ratingsAverage =
    checklistDefinitionsWithSameRatingMode !== false
      ? mean(Object.values(items).map(({ value }) => value))
      : 'N/A';

  const reviewsCount = get(
    widgets,
    [ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS, userId, 'value'],
    0
  );

  if (!reviewsCount && !loadingWidgets) {
    return <EmptyForUserPage />;
  }

  return (
    <SRow padding="16px" gutter={[0, 16]}>
      <ChecklistDefinitionAnalytics
        loading={loadingWidgets}
        reviewsCount={reviewsCount}
        colorZones={get(widgets, [
          ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS,
          userId
        ])}
        ratingFlags={get(widgets, [
          ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS,
          userId
        ])}
        averageScore={checklistDefinitionsWithSameRatingMode !== false ? ratingsAverage : 'N/A'}
        checklistDefinition={{
          ratingMode:
            checklistDefinitionsWithSameRatingMode !== false
              ? RATING_MODE[toUpper(checklistDefinitionsWithSameRatingMode)]
              : RATING_MODE.NUMBERS
        }}
      />
      <SCol span={24} marginTop="-16px">
        {checklistDefinitions.map(checklistDefinition => (
          <AllQuestionGroups
            widgets={widgets}
            loading={loadingWidgets}
            reviewsCount={get(widgets, [
              ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
              userId,
              'value'
            ])}
            userId={userId}
            checklistDefinition={checklistDefinition}
          />
        ))}
      </SCol>
      <QuestionDetailsModal widgets={widgets} loadingWidgets={loadingWidgets} />
    </SRow>
  );
};

export default AllQuestionsAnalytics;
