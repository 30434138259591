import React from 'react';
import { Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { useParams } from 'react-router-dom';
import SText from 'components/Standard/SText';
import moment from 'moment';
import { flatMap, get, isEqual, sum, uniq } from 'lodash';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';

const { Title } = Typography;

const AssignmentsInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { id: currentConfigurationId, task_id: currentTaskId } = useParams();

  const tasksByIds = useSelector(state => state.workPlanTasksResource.byIds, isEqual);

  const currentTask = tasksByIds[currentTaskId] || {};

  const taskAssignmentsByIds = useSelector(
    state => state.workPlanTaskAssignmentsResource.byIds,
    isEqual
  );

  const currentTaskCompletedTaskItemsCount = sum(
    get(currentTask, 'assignmentsIds', []).map(
      item => taskAssignmentsByIds[item]?.totalTaskItemsCount
    )
  );

  const workPlanTaskConfigurationsByIds = useSelector(
    state => state.workPlanTaskConfigurationsResource.byIds,
    isEqual
  );

  const currentConfiguration = get(workPlanTaskConfigurationsByIds, currentConfigurationId, {});

  const workPlanFiltersByIds = useSelector(state => state.workPlanFiltersResource.byIds);

  const currentConfigurationsFilters = get(currentConfiguration, 'filtersIds', []).map(
    id => workPlanFiltersByIds[id]
  );

  const fieldsByIds = useSelector(state => state.workPlanFilterFieldsResource.byIds, isEqual);

  const currentConfigurationsFields = flatMap(
    currentConfigurationsFilters.map(filter =>
      get(filter, 'fieldsIds', []).map(fieldId => fieldsByIds[fieldId])
    )
  );

  const getOperatorsCount = () => {
    const operatorsFields = currentConfigurationsFields?.filter(
      field => field?.key === 'operators_ids'
    );

    return uniq(flatMap(operatorsFields.map(config => config.settings.operatorsIds)))?.length || 0;
  };

  const completeTaskById = async () => {
    try {
      const res = await dispatch(
        workPlanTaskConfigurationsResource.operations.completeWorkPlanTask({
          id: currentConfigurationId,
          taskId: currentTask?.id
        })
      );
      if (res) message.success(t('messages.success.taskStopped'));
    } catch (error) {
      console.log(error);
      message.error(t('messages.error.failedStopTask'));
    }
  };

  return (
    <SCard rounded={false}>
      <SRow type="flex" align="middle" justify="space-between" gutter={[16, 0]}>
        <SCol>
          <SRow align="middle" gutter={[16, 0]}>
            <SCol>
              <Title level={5}>{currentConfiguration?.name}</Title>
            </SCol>
            <SCol>
              <SText type="secondary">{`${t('workPlanTasks.assignments.taskStartedAt')}: `}</SText>
              <SText>{moment(currentTask?.timeFrom).format('DD/MM/YYYY')}</SText>
            </SCol>
            <SCol>
              <SText type="secondary">{`${t('workPlanTasks.reviewersCount')}: `}</SText>
              <SText>{get(currentTask, 'assignmentsIds', []).length}</SText>
            </SCol>
            <SCol>
              <SText type="secondary">{`${t('workPlanTasks.operatorsCount')}: `}</SText>
              <SText>{getOperatorsCount()}</SText>
            </SCol>
            <SCol>
              <SText type="secondary">
                {`${t('workPlanTasks.assignments.completedTaskItemsCount')}: `}
              </SText>
              <SText>{currentTaskCompletedTaskItemsCount}</SText>
            </SCol>
            <SCol>
              <SText type="secondary">{`${t('general.status')}: `}</SText>
              <SText color={WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[currentTask?.status]?.color}>
                {t(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES[currentTask?.status]?.title)}
              </SText>
            </SCol>
          </SRow>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default AssignmentsInfo;
