import api from 'core/api';
import decamelize from 'decamelize-keys-deep';
import { processJsonApiObject } from 'core/jsonapi';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { message } from 'antd';
import * as actions from './reducer';

export const loadOrganization = () => {
  return async dispatch => {
    dispatch(actions.loadingOrganization());
    try {
      const response = await api.getOrganizationInfo();
      const organizationInfo = processJsonApiObject(response.body.data);
      dispatch(actions.organizationLoaded(organizationInfo));
      return organizationInfo;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const updateOrganization = ({
  name,
  address,
  email,
  phoneNumber,
  pauseAudioOnCommenting
}) => {
  return async dispatch => {
    dispatch(actions.updatingOrganization());
    try {
      const response = await api.updateOrganizationInfo(
        decamelize({
          name,
          email,
          phoneNumber,
          address,
          pauseAudioOnCommenting
        })
      );
      const organizationInfo = processJsonApiObject(response.body.data);
      dispatch(actions.organizationUpdated(organizationInfo));
      return organizationInfo;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const getUnitsIdsCommonForUsers = usersIds => {
  return async dispatch => {
    dispatch(actions.loadingCommonUnitsIdsforUsers());
    try {
      const response = await api.getUnitsIdsCommonForUsers({ users_ids: usersIds });
      // const response = await api.getUnitsIdsCommonForUsers(parseOptionsForApi({ usersIds }));
      const unitsIds = response.body.units_ids;
      dispatch(actions.commonUnitsIdsforUsersLoaded(unitsIds));
      return unitsIds;
    } catch (error) {
      console.log(error);
      dispatch(actions.catchError(error));
    }
  };
};

export const addUsersByUnit = (filters, search, setSelected, t) => {
  return async () => {
    try {
      const response = await api.addUsersByUnit(
        parseOptionsForApi({
          units_ids: filters?.unitsIds,
          roles_ids: filters?.rolesIds,
          search
        })
      );
      const users = response.body.users_ids;
      setSelected(users);
      return users;
    } catch (error) {
      console.log(error);
      message.error(t('errorPages.internalServerError.title'));
    }
  };
};
