import { get, post, destroy, put } from 'redux-bees';

const reviewsEndpoints = apiPath => ({
  getReviews: { method: get, path: `${apiPath}/reviews` },
  getReview: { method: get, path: `${apiPath}/reviews/:id` },
  createReview: { method: post, path: `${apiPath}/reviews` },
  deleteReview: { method: destroy, path: `/reviews/:id` },
  updateReview: { method: put, path: `${apiPath}/reviews/:id` }
});

export default reviewsEndpoints;
