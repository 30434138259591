export default {
  userAnalyticsPage: {
    analyticsTabs: {
      analytics: 'Аналитика',
      rates: 'Проверки',
      flagsAndComments: 'Комментарии и флаги'
    },
    widgets: {
      checklistTooltip: 'Аналитика по форме оценки',
      questionTooltip: 'Аналитика по критерию',
      reviewsCount: {
        name: 'Всего проверок',
        description: 'Общее количество проверок по форме оценки за выбранный период'
      },
      averageScore: {
        name: 'Средний балл',
        description:
          'Средний балл по форме оценки, рассчитанный из всех оценок за выбранный период. Для "оценки по всем критериям" считается как среднее арифметическое'
      },
      lowPercentage: {
        name: 'Критично',
        description: 'Количество оценок, попавших в красную зону'
      },
      mediumPercentage: {
        name: 'Нормально',
        description: 'Количество оценок, попавших в желтую зону'
      },
      highPercentage: {
        name: 'Отлично',
        description: 'Количество оценок, попавших в зеленую зону'
      },
      flags: {
        name: 'Флаги',
        description: 'Количество флагов, добавленных к оценкам по выбранной форме оценки'
      }
    },
    userReviewsTable: {
      columns: {
        clientInteractionStartedAt: 'Дата и время',
        clientPhoneNumber: 'Номер телефона',
        clientInteractionDirection: 'Направление',
        clientInteractionDuration: 'Прод-ть',
        checklistScore: 'Оценка',
        comments: 'Флаги и комментарии',
        checklistDefinition: 'Форма оценки',
        tasks: 'Теги',
        createdAt: 'Дата оценки',
        reviewerLastName: 'Автор оценки'
      },
      reviewsCount: 'Оценок:',
      colorZoneSelect: {
        placeholder: 'Зоны оценки'
      }
    },
    reviewsWithComments: {
      flagsAndCommentsCount: 'Всего флагов и комментариев:',
      flagsAndCommentsPlaceholder: 'Флаги и комментарии',
      loading: {
        load: 'Загружаем комментарии...😤',
        empty: 'Очень жаль, но не нашлось комментариев 😟',
        all: 'Это всё! 😊'
      }
    },
    reviewPreview: {
      date: 'Дата:',
      type: 'Тип:',
      value: 'Оценка:'
    },
    analyticsChartsPanel: {
      empty: 'Не нашлось оценок по данным фильтрам 😓'
    },
    mainControlsPanel: {
      checklistDefinitionsEmpty: 'Формы оценки не найдены',
      questionValues: 'Оценки по всем критериям'
    }
  }
};
