import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import RegisterFromIntegration from 'components/RegisterFromIntegration';
import SCard from 'components/Standard/SCard';
import BitrixService from 'core/services/bitrix';
import { handleError } from 'core/services/errors';
import { isEmpty, isNil } from 'lodash';
import BackgroundJob from 'pages/BackgroundJob';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { syncDataWithBitrix } from 'redux/entities/bitrixIntegration/operations';
import { signInUser } from 'redux/reducers/reduxTokenAuthReducer';
import { setShowBJModal, setShowUsersModal } from 'redux/ui/registerFromIntegration/reducer';

const { Title } = Typography;

const LoginForm = ({ onLoginSubmit, loading }) => {
  const { t } = useTranslation();

  return (
    <Form hideRequiredMark name="login" onFinish={onLoginSubmit}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: t('loginPage.form.messages.enterEmail') },
          { type: 'email', message: t('loginPage.form.messages.wrongEmail') }
        ]}
        validateTrigger={['onBlur']}
      >
        <Input placeholder={t('loginPage.form.fields.emailPlaceholder')} type="email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: t('loginPage.form.messages.enterPassword') },
          { min: 6, message: t('loginPage.form.messages.minLengthPassword') }
        ]}
        validateTrigger={['onBlur']}
      >
        <Input type="password" placeholder={t('loginPage.form.fields.passwordPlaceholder')} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={loading}>
          {t('loginPage.form.buttons.login')}
        </Button>
      </Form.Item>
    </Form>
  );
};

const BitrixLoginPage = ({
  loading,
  history,
  showUsersModal,
  setShowUsersModal,
  showBJModal,
  setShowBJModal,
  signInUser,
  syncDataWithBitrix
}) => {
  const onFinishRegister = () => {
    BitrixService.setOption('usersSelected', true);
    BitrixService.finishInstall();
    history.push('/');
  };

  const onLoginSubmit = async values => {
    const { email, password } = values;
    try {
      await signInUser({ email, password });
      const backgroundJob = BitrixService.getOption('backgroundJob');

      if (!isEmpty(backgroundJob)) {
        return setShowBJModal(true);
      }

      const usersSelected = BitrixService.getOption('usersSelected');

      if (!isNil(usersSelected) && !usersSelected) {
        return setShowUsersModal(true);
      }

      history.push('/');
    } catch (err) {
      console.log(err);
      handleError(err?.response?.status, err?.response?.data);
    }
  };

  const onBackgroundJobDone = () => {
    setShowBJModal(false);
    setShowUsersModal(true);
    BitrixService.setOption('backgroundJob', undefined);
    BitrixService.setOption('usersSelected', false);
  };

  const onBackgroundJobFailed = () => {
    Modal.error({
      title: 'Возникла ошибка при синхронизации данных с Битрикс24',
      content: 'Пожалуйста, переустановите приложение или напишите нам на info@qolio.io',
      onOk: () => {
        setShowBJModal(false);
        BitrixService.setOption('backgroundJob', undefined);
        history.push('/register');
      }
    });
  };

  return (
    <Col span={24}>
      <Row type="flex" align="middle" justify="center" style={{ height: '100%' }}>
        <Col {...{ sm: { span: 10 }, md: { span: 8 }, xxl: { span: 6 } }}>
          <SCard shadowed>
            <Row justify="space-between" type="flex" align="middle">
              <Col>
                <Title level={3}>Вход</Title>
              </Col>
              <Col>
                <Link to="/register">Регистрация</Link>
              </Col>
            </Row>

            <LoginForm onLoginSubmit={onLoginSubmit} loading={loading} />
          </SCard>
          <RegisterFromIntegration
            visible={showUsersModal}
            onProceed={onFinishRegister}
            fetchIntegrationData={syncDataWithBitrix}
          />
          <Modal
            visible={showBJModal}
            footer={null}
            closable={false}
            maskClosable
            // maskClosable={false}
          >
            <BackgroundJob
              text={
                <video autoPlay loop muted playsinline>
                  <source src={catWithSpinner} type="video/mp4" />
                </video>
              }
              spinning={false}
              onDone={onBackgroundJobDone}
              onFailed={onBackgroundJobFailed}
            />
          </Modal>
        </Col>
      </Row>
    </Col>
  );
};
const mapStateToProps = state => ({
  showBJModal: state.uiRegisterFromIntegration.showBJModal,
  showUsersModal: state.uiRegisterFromIntegration.showUsersModal
});

const mapDispatchToProps = {
  signInUser,
  setShowBJModal,
  setShowUsersModal,
  syncDataWithBitrix
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BitrixLoginPage));
