import React from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { PERMISSIONS, PERMISSIONS_DESCRIPTIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

export const UserSettingPermissions = ({ fields, getCheckboxWithTooltip }) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <SRow gutter={[8, 8]} type="flex">
      <SCol span={24}>
        <Text strong>{t('organizationStructure.tabs.roles.modal.form.profileCategory')}</Text>
      </SCol>

      <SCol span={24}>
        <SRow>
          {getCheckboxWithTooltip(
            PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB,
            t(PERMISSIONS_DESCRIPTIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB.title),
            t(PERMISSIONS_DESCRIPTIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB.description),
            [],
            fields[PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB]
          )}
        </SRow>
        <SRow>
          {getCheckboxWithTooltip(
            PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB,
            t(PERMISSIONS_DESCRIPTIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB.title),
            t(PERMISSIONS_DESCRIPTIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB.description),
            [PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB],
            fields[PERMISSIONS.CAN_REPLY_COMMENTS]
          )}
        </SRow>
        <SRow>
          {getCheckboxWithTooltip(
            PERMISSIONS.CAN_REPLY_COMMENTS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_REPLY_COMMENTS.title),
            t(PERMISSIONS_DESCRIPTIONS.CAN_REPLY_COMMENTS.description),
            [
              PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB,
              PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB
            ],
            fields[PERMISSIONS.CAN_REPLY_ALL_COMMENTS]
          )}
        </SRow>
        <SRow>
          {getCheckboxWithTooltip(
            PERMISSIONS.CAN_REPLY_ALL_COMMENTS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_REPLY_ALL_COMMENTS.title),
            t(PERMISSIONS_DESCRIPTIONS.CAN_REPLY_ALL_COMMENTS.description),
            [
              PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB,
              PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB,
              PERMISSIONS.CAN_REPLY_COMMENTS
            ]
          )}
        </SRow>
      </SCol>
    </SRow>
  );
};
