import api from 'core/api';
import decamelize from 'decamelize-keys-deep';
import { processJsonApiObject } from 'core/jsonapi';
import loadIncludedObjectsToRedux from 'core/redux/resources/loadIncludedObjects';
import { loadDashboardAnalyticsSucceed } from 'redux/entities/analytics/dashboard/reducer';
import camelcaseKeys from 'camelcase-keys';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import * as actions from '../reducer';

export const loadUnitsDashboard = params => async (dispatch, getState) => {
  dispatch(actions.loadingDashboard());
  try {
    await dispatch(checklistDefinitionsResource.operations.load({ pagination: false }));

    // * данные dashboard page
    const dashboardResponse = await api.getUnitsDashboard();
    // * данные виджетов на выбранной странице
    const dashboardPageWidgets = await api.getUnitsDashboardPart(parseOptionsForApi({ ...params }));
    // * аналитика виджетов на выбранной странице
    const analyticsResponse = await api.getUnitsDashboardAnalytics(
      parseOptionsForApi({ ...params })
    );

    const dashboard = processJsonApiObject(dashboardResponse.body.data);
    const analytics = analyticsResponse.body;

    loadIncludedObjectsToRedux({
      objects: dashboardPageWidgets.body.data,
      dispatch
    });

    dispatch(loadDashboardAnalyticsSucceed(analytics));

    dispatch(
      actions.dashboardLoaded({
        ...dashboard,
        page: {
          ...camelcaseKeys(dashboardPageWidgets.body.meta)
        },
        widgetsIds: dashboardPageWidgets.body.data.map(widget => widget.id)
      })
    );
  } catch (error) {
    console.log(error);
  }
};

export const updateUnitsDashboardPageFilters = ({ filters, page }) => async dispatch => {
  try {
    await api.updateUnitsDashboard(decamelize({ filters }));
    await dispatch(loadUnitsDashboard({ page }));
  } catch (error) {
    console.log(error);
  }
};

export const deleteUnitDashboardPageWidget = ({ id, page }) => {
  return async (dispatch, getState) => {
    try {
      await api.deleteUnitDashboardPageWidgetById({ id });
      await dispatch(loadUnitsDashboard({ page }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const createUnitDashboardPageWidget = ({ dashboardPageId, page, filters }) => {
  return async (dispatch, getState) => {
    try {
      const res = await api.createUnitDashboardPageWidget(
        decamelize({
          type: 'scores_by_units_report_history',
          page: {
            number: 1,
            size: 9
          },
          dashboardPage: dashboardPageId
        })
      );
      if (res) await dispatch(loadUnitsDashboard({ page: { number: page.page, size: 5 } }));
    } catch (error) {
      console.log(error);
    }
  };
};
