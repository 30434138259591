import { Form, message, Checkbox } from 'antd';
import { isEmpty, omit, isEqual } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import SButton from 'components/Standard/SButton';
import SInput from 'components/Standard/SInput';
import SCard from 'components/Standard/SCard';
import { updateOrganization } from 'redux/entities/organization/operations';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { SFormItem } from 'components/Standard/SFormItem';

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);

  const loading = useSelector(state => state.organizationInfo.loading);

  const { name, address, email, phoneNumber, settings } = organizationInfo;
  const { pauseAudioOnCommenting } = settings || {};

  useEffect(() => {
    form.setFieldsValue({
      name,
      address,
      email,
      phoneNumber,
      pauseAudioOnCommenting: pauseAudioOnCommenting?.enabled || false
    });
  }, [organizationInfo, form]);

  const handleSubmit = useCallback(
    async values => {
      const updatedValues = omit(
        values,
        isEmpty(values?.password) ? ['password', 'passwordConfirmation'] : ['passwordConfirmation']
      );
      try {
        const response = await dispatch(updateOrganization({ ...updatedValues }));
        if (response) {
          message.success(t('userSettingsPage.messages.dataSuccessfullyUpdated'));
        }
      } catch (error) {
        console.log(error);
        message.error(t('userSettingsPage.messages.updateDataFailed'));
      }
    },
    [dispatch]
  );

  return (
    <SCol span={24}>
      <SCard shadowed bordered loading={loading}>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          scrollToFirstError
          form={form}
          initialValues={{
            name,
            address,
            email,
            phoneNumber,
            pauseAudioOnCommenting: pauseAudioOnCommenting?.enabled || false
          }}
        >
          <SRow gutter={[16, 0]} type="flex" align="middle">
            <SCol lg={12} xl={8} xxl={6}>
              <SFormItem
                marginBottom="16px"
                label={
                  <SText strong>
                    {t('organizationSettingsPage.tabs.organizationSettings.form.organizationName')}
                  </SText>
                }
                name="name"
                rules={[
                  {
                    required: true,
                    message: t(
                      'organizationSettingsPage.messages.form.validateMessages.enterOrganizationName'
                    ),
                    whitespace: true
                  }
                ]}
              >
                <SInput size="middle" width="100%" />
              </SFormItem>
            </SCol>
            <SCol lg={12} xl={8} xxl={6}>
              <SFormItem
                marginBottom="16px"
                label={
                  <SText strong>
                    {t(
                      'organizationSettingsPage.tabs.organizationSettings.form.organizationAddress'
                    )}
                  </SText>
                }
                name="address"
              >
                <SInput size="middle" width="100%" />
              </SFormItem>
            </SCol>

            <SCol lg={12} xl={8} xxl={6}>
              <SFormItem
                marginBottom="16px"
                label={
                  <SText strong>
                    {t('organizationSettingsPage.tabs.organizationSettings.form.organizationEmail')}
                  </SText>
                }
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: t('organizationSettingsPage.messages.form.validateMessages.worngEmail')
                  }
                ]}
              >
                <SInput type="text" size="middle" width="100%" />
              </SFormItem>
            </SCol>

            <SCol lg={12} xl={8} xxl={6}>
              <SFormItem
                marginBottom="16px"
                label={
                  <SText strong>
                    {t(
                      'organizationSettingsPage.tabs.organizationSettings.form.organizationPhoneNumber'
                    )}
                  </SText>
                }
                name="phoneNumber"
              >
                <SInput type="text" size="middle" width="100%" />
              </SFormItem>
            </SCol>
            <SCol>
              <SFormItem
                name="pauseAudioOnCommenting"
                valuePropName="checked"
                marginBottom="16px"
                label={
                  <SText strong>
                    {t(
                      'organizationSettingsPage.tabs.organizationSettings.form.pauseAudioOnCommenting'
                    )}
                  </SText>
                }
              >
                <Checkbox>{t('components.datasetsModal.form.fields.isActive')}</Checkbox>
              </SFormItem>
            </SCol>
            <SCol
              lg={24}
              xl={16}
              xxl={24}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <SButton type="primary" htmlType="submit" size="middle">
                {t('userSettingsPage.buttons.update')}
              </SButton>
            </SCol>
          </SRow>
        </Form>
      </SCard>
    </SCol>
  );
};

export default OrganizationSettings;
