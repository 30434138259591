export default {
  general: {
    save: 'Сохранить',
    cancel: 'Отмена',
    canceling: 'Отменить',
    edit: 'Редактировать',
    answer: 'Ответить',
    delete: 'Удалить',
    disagree: 'Не соглашаюсь',
    accept: 'Принять',
    accepted: 'Принято',
    reject: 'Отклонить',
    rejected: 'Отклонено',
    from: 'От',
    to: 'До',
    time: 'Время',
    hidden: 'Скрытый',
    visibleToAll: 'Доступен всем',
    update: 'Обновить',
    add: 'Добавить',
    redFlag: 'Красный',
    yellowFlag: 'Желтый',
    greenFlag: 'Зеленый',
    withoutFlag: 'Без флага',
    uploadImage: 'Прикрепить изображение',
    loading: 'Загрузка',
    requiredField: 'Обязательное поле',
    continue: 'Продолжить',
    date: 'Дата',
    dateAndTime: 'Дата и время',
    direction: 'Направление',
    number: 'Номер',
    phoneNumber: 'Номер телефона',
    tags: 'Теги',
    tag: 'Тег',
    reviewDate: 'Дата оценки',
    clientInteractionDate: 'Дата коммуникации',
    applicationDate: 'Дата подачи',
    applicator: 'Подал',
    checklistDefinition: 'Форма оценки',
    checklistDefinitions: 'Формы оценки',
    checklistDefinitionPlaceholder: 'Выберите форму оценки',
    selectChecklist: 'Выберите форму оценки для начала проверки',
    criterion: 'Критерий',
    criteries: 'Критерии',
    clientInteractionType: 'Тип',
    clientInteractionTypes: 'Типы',
    status: 'Статус',
    statuses: 'Статусы',
    users: 'Пользователи',
    executor: 'Исполнитель',
    executors: 'Исполнители',
    role: 'Роль',
    roles: 'Роли',
    changed: 'Изменено',
    change: 'изменил',
    view: 'Смотреть',
    unit: 'Отдел',
    units: 'Отделы',
    settings: 'Настройки',
    analytics: 'Аналитика',
    yes: 'Да',
    no: 'Нет',
    name: 'Имя',
    lastName: 'Фамилия',
    email: 'Email',
    goTo: 'Перейти',
    employees: 'Сотрудники',
    employee: 'Сотрудник',
    all: 'Все',
    initials: 'ФИО',
    duration: 'Прод-ть',
    score: 'Оценка',
    flagsAndComments: 'Флаги и комментарии',
    close: 'Завершить',
    stop: 'Остановить',
    download: 'Скачать',
    reviewer: 'Проверяющий',
    reviewers: 'Проверяющие',
    total: 'Всего',
    author: 'Автор',
    operator: 'Оператор',
    result: 'Итог',
    found: 'Найдено',
    comments: 'Комментарии',
    title: 'Название',
    start: 'Начало',
    finish: 'Завершение',
    create: 'Создать',
    created: 'Создал',
    selected: 'Выбрано',
    progress: 'Прогресс',
    apply: 'Применить',
    period: 'Период',
    communication: 'коммуникации',
    review: 'оценки',
    startOfPeriod: 'Дата начала',
    endOfPeriod: 'Дата окончания',
    displayByDate: 'Отображать по дате: ',
    systemMessages: 'Системные сообщения',
    areHidden: 'скрыты',
    opened: 'открыты',
    showDetails: 'Показать детали',
    empty: 'Нет данных',
    goBack: 'Назад',
    send: 'Отправить',
    closeText: 'Закрыть'
  }
};
