export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'cargado con éxito',
      loadFailed: 'No se pudo cargar el archivo',
      maxFileText: 'Tamaño del archivo',
      maxFileSize: 'supera los 30 MB.',
      fileFormatText: 'Extensión de archivo incorrecta',
      fileFormatMessage: 'solo se permiten archivos mp3, wav o csv.',
      fileFormat:
        'Puede descargar archivos en formato mp3, wav o csv, de hasta 30 megabytes / archivo',
      deleteSuccess: 'Eliminado con éxito',
      editSuccess: 'Cambios guardados',
      saveEditingTooltip: 'Para guardar es necesario rellenar los campos obligatorios'
    },
    buttons: {
      loadCalls: 'Cargar las llamadas',
      downloadExample: 'Ejemplo CSV',
      delete: 'Eliminar',
      save: 'Guardar',
      cancelEditing: 'Deshacer cambios'
    },
    table: {
      columns: {
        operator: 'Operador',
        direction: 'Tipo de llamada',
        startedAt: 'Fecha de llamada',
        startedAtPlaceholder: 'Elija la hora',
        clientPhoneNumber: 'Número de teléfono',
        duration: 'Duración de la llamada',
        actions: 'Acción',
        communicationType: 'Llamada telefónica',
        fileSize: 'Tamaño',
        directionTitle: 'Dirección',
        selectOperator: 'Seleccione un operador'
      },
      uinstalledCallDirection: 'No instalado',
      confirmChange: {
        title: 'Los cambios no se guardarán',
        ok: 'Continuar',
        cancel: 'Cancelar'
      },
      buttons: {
        cancel: 'Cancelar',
        edit: 'Redactar',
        delete: 'Eliminar'
      },
      confirmDelete: {
        title: '¿Estás seguro?',
        content: 'No se puede deshacer esta acción',
        ok: 'Eliminar',
        cancel: 'Cancelación'
      },
      cancelEditing: {
        title: 'Redacción',
        content: '¿Estás seguro de que quieres deshacer los cambios?',
        ok: 'ОК',
        cancel: 'Cancelación'
      }
    }
  }
};
