import { Trash2, Power, Settings } from 'react-feather';
import { Button, Dropdown, Menu, message, Modal, Typography } from 'antd';
import CustomTag from 'components/Tags/CustomTag';
import UserPreview from 'components/UserPreview';
import { TASK_DEFINITIONS_TYPES } from 'core/utils/constants';
import truncateString from 'core/utils/truncateString';
import { compact, get } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/taskDefinitionsListReducer';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import Icon from 'components/Icon';
import STable from 'components/Standard/STable';
import { MoreOutlined } from '@ant-design/icons';
import SCard from 'components/Standard/SCard';

const TaskDefinitionsList = ({
  loading,
  page,
  taskDefinitions,
  history,
  loadTaskDefinitions,
  updateTaskDefinition,
  deleteTaskDefinition,
  taskDefinitionsByIds,
  checklistDefinitionsByIds,
  totalCount
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.clearList());
    return dispatch(actions.clearList());
  }, []);

  useEffect(() => {
    loadTaskDefinitions({ page, include: 'creator,checklist_definition', sort: '-created_at' });
  }, [page.number]);

  const handleTableChange = pagination => {
    const { pageSize, current } = pagination;
    dispatch(actions.updatePage({ size: pageSize.toString(), number: current.toString() }));
  };

  const getChecklistDefinitionName = ({ taskDefinitionId }) => {
    const taskDefinition = taskDefinitionsByIds[taskDefinitionId];
    const { checklistDefinitionId } = taskDefinition;
    const checklistDefinition = checklistDefinitionsByIds[checklistDefinitionId];
    return taskDefinition.labelType === TASK_DEFINITIONS_TYPES.AUTOMATIC
      ? truncateString(get(checklistDefinition, 'name', '-'), 30)
      : '-';
  };

  const handleMenuClick = ({ key, id }) => {
    const taskDefinition = taskDefinitionsByIds[id];
    const actions = {
      settings: () => history.push(`/settings/triggers/edit/task-definition/${id}`),
      poweroff: async () => {
        const result = await updateTaskDefinition({
          ...taskDefinition,
          status: taskDefinition.status === 'disabled' ? 'active' : 'disabled'
        });
        if (result)
          message.success(
            t('triggersSettingsPage.editTaskDefinition.messages.tagSuccessfullyUpdated')
          );
      },
      delete: () =>
        Modal.confirm({
          title: t('triggersSettingsPage.card.confirmDelete.title'),
          okText: t('triggersSettingsPage.card.confirmDelete.ok'),
          cancelText: t('triggersSettingsPage.card.confirmDelete.cancel'),
          okType: 'danger',
          onOk: async () => {
            const result = await deleteTaskDefinition({ id });
            if (result)
              message.success(
                t('triggersSettingsPage.editTaskDefinition.messages.tagSuccessfullyDeleted')
              );
          }
        })
    };
    actions[key]();
  };

  const getMenu = ({ id }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id })}>
      <Menu.Item key="settings">
        <Icon icon={Settings} />
        <Text>{t('triggersSettingsPage.card.buttons.settings')}</Text>
      </Menu.Item>
      <Menu.Item key="poweroff">
        <Icon icon={Power} />
        <Text>
          {taskDefinitionsByIds[id]?.status === 'disabled'
            ? t('triggersSettingsPage.card.buttons.enable')
            : t('triggersSettingsPage.card.buttons.disable')}
        </Text>
      </Menu.Item>
      <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
        <Icon icon={Trash2} />
        <span>{t('triggersSettingsPage.card.buttons.delete')}</span>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: t('general.tag'),
      dataIndex: 'id',
      key: 'id',
      width: 100,
      ellipsis: {
        showTitle: false
      },
      render: (id, { labelColor, labelName }) => (
        <CustomTag truncate={25} color={labelColor} text={labelName} />
      )
    },
    {
      title: t('triggersSettingsPage.card.info.author'),
      dataIndex: 'creatorId',
      key: 'creatorId',
      width: 150,
      ellipsis: true,
      render: creatorId => <UserPreview disabled userId={creatorId} showAvatar truncateSize={25} />
    },
    {
      title: t('triggersSettingsPage.card.info.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 55,
      render: createdAt => <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>
    },
    {
      title: t('triggersSettingsPage.card.info.status.title'),
      dataIndex: 'status',
      key: 'status',
      width: 60,
      render: status => (
        <Text>
          {status === 'disabled'
            ? t('triggersSettingsPage.card.info.status.disable')
            : t('triggersSettingsPage.card.info.status.enable')}
        </Text>
      )
    },
    {
      title: t('triggersSettingsPage.card.info.checklistDefinition'),
      dataIndex: 'checklistDefinitionName',
      key: 'checklistDefinitionName',
      width: 100,
      render: (checklistDefinitionName, { id }) => (
        <Text>{getChecklistDefinitionName({ taskDefinitionId: id })}</Text>
      )
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: 25,
      fixed: 'right',
      render: (taskDefinitionId, { id }) => {
        return (
          <Dropdown overlay={getMenu({ id })} trigger={['click']}>
            <Button icon={<Icon icon={MoreOutlined} />} />
          </Dropdown>
        );
      }
    }
  ];

  return (
    <SCard bodyPadding="16px">
      <STable
        dataSource={taskDefinitions}
        rowKey="id"
        size="small"
        loading={loading}
        columns={columns}
        border
        pagination={{
          pageSize: parseInt(page.size),
          current: parseInt(page.number),
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50']
        }}
        onChange={handleTableChange}
        // * NOTE: it doesn't work on resize
        scroll={{ y: window.innerWidth > 1320 ? 'calc(100vh - 286px)' : 'calc(100vh - 356px)' }}
      />
    </SCard>
  );
};

const mapStateToProps = state => {
  const { ids, loading, page, totalPages, totalCount } = state.taskDefinitionsList;
  const taskDefinitionsByIds = state.taskDefinitionsResource.byIds;
  const taskDefinitions = compact(ids.map(id => taskDefinitionsByIds[id]));
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;

  return {
    loading,
    page,
    hasNextPage: totalPages > page.number,
    taskDefinitionsByIds,
    taskDefinitions,
    checklistDefinitionsByIds,
    totalCount: parseInt(totalCount)
  };
};

const mapDispatchToProps = {
  loadTaskDefinitions: operations.load,
  deleteTaskDefinition: taskDefinitionsResource.operations.deleteById,
  updateTaskDefinition: taskDefinitionsResource.operations.updateById
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskDefinitionsList));
