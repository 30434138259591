import { Col, Modal, Row, Skeleton, Tooltip, Typography } from 'antd';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import moment from 'core/moment';
import { INTEGRATIONS, SYNC_DATA_PERIODS } from 'core/utils/constants';
import { get, isEmpty, isEqual } from 'lodash';
import BackgroundJob from 'pages/BackgroundJob';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setCurrentBackgroundJob } from 'redux/entities/backgroundJob/reducer';
import { syncDataWithSipuni } from 'redux/entities/sipuniIntegration/operations';
import { integrationsResource } from 'redux/resources/integrations';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import Icon from 'components/Icon';
import { BookOpen } from 'react-feather';
import SText from 'components/Standard/SText';
import { getManualLinkInfo } from 'pages/UserPage/Integrations/utils';
import SyncPeriod from '../IntegrationSettingsModules/SyncPeriod';
import NameAndType from '../IntegrationSettingsModules/NameAndType';
import ManageSipuniUserAccounts from '../IntegrationSettingsModules/ManageSipuniUserAccounts';

const { Title } = Typography;

const SipuniIntegrationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: userId, integrationId } = useParams();
  const [integrationType, setIntegrationType] = useState();

  const currentBackgroundJob = useSelector(
    state => state.backgroundJob.currentBackgroundJob,
    isEqual
  );

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const loadIntegration = async () => {
    setLoading(true);
    await dispatch(integrationsResource.operations.loadById({ id: integrationId }));
    setLoading(false);
  };

  useEffect(() => {
    loadIntegration();
  }, []);

  const integration = useSelector(
    state => get(state.integrationsResource.byIds, integrationId),
    isEqual
  );

  const onDelete = useCallback(() => {
    Modal.confirm({
      title: t('integrationsSettingsPage.deleteConfirm.title'),
      okText: t('integrationsSettingsPage.deleteConfirm.ok'),
      onOk: async () => {
        setLoading(true);
        await dispatch(integrationsResource.operations.deleteById({ id: integrationId }));
        history.push(`/user/${userId}/integrations-settings`);
        setLoading(false);
      }
    });
  }, [dispatch, integrationId]);

  const updateIntegration = useCallback(
    async ({ name, clientSecret, clientId }) =>
      dispatch(
        integrationsResource.operations.updateById({
          id: integrationId,
          name,
          clientSecret,
          clientId
        })
      ),
    [dispatch, integrationId]
  );

  const onSyncFrom = useCallback(
    async syncFrom => {
      const { number, momentUnit } = SYNC_DATA_PERIODS[syncFrom];
      const fetchFromISO = moment()
        .subtract(number, momentUnit)
        .toISOString();
      const response = await dispatch(
        syncDataWithSipuni({
          id: integration.id,
          fetchFrom: fetchFromISO
        })
      );
    },
    [integration?.integrationType, dispatch]
  );

  const onBackgroundJobDone = () => {};

  const onBackgroundJobFailed = () => {
    Modal.error({
      title: `Возникла ошибка при синхронизации данных с ${t(
        INTEGRATIONS[integration.integrationType].name
      )}`,
      content: 'Пожалуйста, переустановите приложение или напишите нам на info@qolio.io'
    });
  };

  const { manualTextLink, manualLink } = getManualLinkInfo(
    integration?.integrationType || integrationType
  );

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[0, 32]} style={{ marginBottom: '-16px' }}>
          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <SRow type="flex" wrap={false} justify="space-between">
                  <SCol flex="auto">
                    <Title level={4}>
                      {`${t('integrationsSettingsPage.integrationPage.title')}: ${
                        integration?.name ? integration.name : ''
                      }`}
                    </Title>
                  </SCol>
                  <SCol flex="none">
                    <Tooltip title={manualTextLink}>
                      <a target="_blank" rel="noreferrer" href={manualLink}>
                        <SRow>
                          <SCol display="flex" alignItems="center">
                            <Icon icon={BookOpen} marginTop="1px" marginRight="6px" size="20px" />
                            <SText ellipsis color="var(--link-color)">
                              {manualTextLink}
                            </SText>
                          </SCol>
                        </SRow>
                      </a>
                    </Tooltip>
                  </SCol>
                </SRow>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <NameAndType
                    canEdit
                    onUpdate={updateIntegration}
                    onDelete={onDelete}
                    integration={integration}
                    onChangeIntegrationType={type => setIntegrationType(type)}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t()}</Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <SyncPeriod okText={t('integrationsSettingsPage.sync')} onOk={onSyncFrom} />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('integrationsSettingsPage.integrationPage.users')}</Title>
              </Col>
              <Col span={24}>
                {loading ? (
                  <SCard>
                    <Skeleton active />
                  </SCard>
                ) : (
                  <ManageSipuniUserAccounts
                    integrationId={integrationId}
                    integrationInProcess={false}
                    okText={t('integrationsSettingsPage.save')}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Modal
        visible={!isEmpty(currentBackgroundJob)}
        onCancel={() => dispatch(setCurrentBackgroundJob(null))}
        footer={
          <SButton onClick={() => dispatch(setCurrentBackgroundJob(null))} type="primary">
            {t('integrationsSettingsPage.proceed')}
          </SButton>
        }
      >
        {currentBackgroundJob && (
          <BackgroundJob
            text={
              <video autoPlay loop muted playsinline>
                <source src={catWithSpinner} type="video/mp4" />
              </video>
            }
            description={
              <>
                <div>{t('backgroundJob.description')}</div>
              </>
            }
            spinning={false}
            onDone={onBackgroundJobDone}
            onFailed={onBackgroundJobFailed}
          />
        )}
      </Modal>
    </Row>
  );
};

export default SipuniIntegrationPage;
