import { transform } from 'lodash';
import { beatifyFloat } from 'core/utils/convertFloat';

const preparePeriodKeys = ({ widgetValue }) => {
  const emptyKeys = transform(
    widgetValue,
    (acc, value, key) => {
      const { history } = value;
      Object.assign(acc, history);
    },
    {}
  );
  return Object.keys(emptyKeys).sort((a, b) => new Date(a) - new Date(b));
};

export const prepareData = ({ widgetValue }) => {
  return Object.keys(widgetValue)
    .map(key => ({
      id: widgetValue[key]?.unit_name,
      data: preparePeriodKeys({ widgetValue }).map(historyData => ({
        x: historyData,
        y: beatifyFloat(widgetValue[key]?.history[historyData], 1, false)
      }))
    }));
};
