export default {
  messages: {
    success: {
      appealSended: 'Апелляция отправлена',
      appealCanceled: 'Апелляция отменена',
      appealDeleted: 'Апелляция удалена',
      processingCompleted: 'Обработка завершена',
      commentSaved: 'Комментарий сохранен',
      commentAdded: 'Комментарий добавлен',
      answerSended: 'Ответ отправлен',
      templateUpdated: 'Шаблон обновлен',
      templateAdded: 'Шаблон добавлен',
      templateDeleted: 'Шаблон удален',
      integrationCompleted: 'Интеграция завершена',
      filtersApplied: 'Фильтры применены',
      newTokenGenerated: 'Сгенерирован новый токен',
      userMoved: 'Пользователь успешно перемещён',
      taskStopped: 'Задача остановлена',
      taskCompleted: 'Задача завершена',
      configurationDeleted: 'Конфигурация удалена',
      repeatingCanceled: 'Повторения остановлены',
      settingsUpdated: 'Параметры обновлены',
      transcriptionSent: 'Коммуникация отправлена на транскрибацию'
    },
    warning: {
      failedTemplateUpdate: 'Не удалось обновить шаблон',
      failedTemplateSave: 'Не удалось сохранить шаблон',
      failedSettingsUpdate: 'Не удалось обновить параметры',
      limitExportReviews:
        'Вы пытаетесь загрузить более 5000 оценок. Сокрытите период для уменьшения количества экспортируемых оценок.'
    },
    error: {
      failedInstallIntegration:
        'Не удалось завершить интеграцию. Обратитесь к нам на info@qolio.io',
      failedRegisterOrganization:
        'Не удалось зарегистрировать организацию. Пожалуйста, попробуйте снова или напишите нам на info@qolio.io',
      failedMoveUser: 'Не удалось переместить пользователя',
      failedStopTask: 'Не удалось остановить задачу',
      failedDeleteConfiguration: 'Не удалось удалить конфигурацию',
      failedStopRepeating: 'Не удалось остановить повторения',
      failedCloseTask: 'Не удалось завершить задачу'
    }
  }
};
