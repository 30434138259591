import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  duration: 0,
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  playbackRate: 1.0,
  volume: 1,
  currentCallId: null,
  isPlaying: false,
  wasPlayed: false,
  showAddToLibraryModal: false,
  url: null,
  updatingUrl: true
};

export const updatePlayerState = createAction('uiRecordPlayer/updatePlayerState');
export const setCurrentCallId = createAction('uiRecordPlayer/setCurrentCallId');
export const togglePlaying = createAction('uiRecordPlayer/togglePlaying');
export const stopPlaying = createAction('uiRecordPlayer/stopPlaying');
export const setUpdatingUrl = createAction('uiRecordPlayer/setUpdatingUrl');
export const setUrl = createAction('uiRecordPlayer/setUrl');

export const recordPlayerReducer = createReducer(defaultState, {
  [updatePlayerState]: (state, { payload }) => ({ ...state, ...payload }),
  [togglePlaying]: state => ({ ...state, isPlaying: !state.isPlaying }),
  [stopPlaying]: state => ({ ...state, isPlaying: false }),
  [setCurrentCallId]: (state, { payload }) => {
    const isNew = state.currentCallId !== payload;
    return { ...state, currentCallId: payload, playedSeconds: isNew ? 0 : state.playedSeconds };
  },
  [setUpdatingUrl]: (state, { payload }) => ({ ...state, updatingUrl: payload }),
  [setUrl]: (state, { payload }) => ({ ...state, updatingUrl: false, url: payload })
});

export default recordPlayerReducer;