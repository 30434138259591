import { Button, Modal, Skeleton } from 'antd';
import CopyLinkButton from 'components/CopyLinkButton';
import AttachTag from 'components/PhoneCallPlayer/RecordPlayer/AttachTag';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { getScore } from 'core/utils/ratingsCalculations';
import { debounce, get, isEmpty, isEqual, pick } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChecklistDefinitionQuestionGroupsWithQuestions,
  getChecklistDefinitionQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { getCurrentUser, getCurrentUserPermissions } from 'redux/selectors/users';
import {
  clearAddingCommentsMassAction,
  setAddingCommentsMassAction,
  setAddingCommentsToQuestionId,
  updateAnswer,
  updateCurrentChecklist
} from 'redux/ui/checklistManager/reducer';
import { addComment, setAddingComment } from 'redux/ui/clientInteractionPage/reducer';
import Editor from 'components/Comments/Editor';
import DOMPurify from 'dompurify';

import SButton from 'components/Standard/SButton';
import { Edit3 } from 'react-feather';
import Icon from 'components/Icon';
import styled from 'styled-components';
import { InlineCommentText } from 'components/Comments/InlineCommentText';
import QuestionsContainer from './QuestionsContainer';
import {
  ChecklistDefinitionContainer,
  Score,
  ScoreContainer,
  StyledCollapse,
  StyledPanel
} from '../styled';
import { AppealHead } from './AppealHead';
import { getAppellationsByIds } from '../../../../redux/selectors/appeals';
import {
  getAnswersIndexDB,
  getCommentsIndexDB,
  getFileIndexDB,
  saveCommentIndexDB
} from '../../../../core/indexDB/indexDB';
import {
  toggleIsShowModalRestoreDraftsClose,
  toggleIsShowModalRestoreDraftsOpen
} from '../../../../redux/ui/indexDB/reducer';
import { uploadedFilesResource } from '../../../../redux/resources/uploadedFiles';

const ChecklistBody = ({
  id: communicationId,
  title,
  customCommunicationView,
  reviewId,
  addingComment,
  onAutoFail,
  comments,
  onDeleteComment,
  onUpdateComment,
  allowCommenting,
  handleCommentSave,
  customCommunicationType,
  communication
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenCommentEditor, setOpenCommentEditor] = useState(false);
  // * используется для хранения состояния которое нужно применить если при редактировании комментария была нажата кнопка отмены
  const [initialChecklistCommentState, setInitialChecklistCommentState] = useState(null);
  const review = useSelector(state => state.reviewsResource.byIds[reviewId]);
  const isShowModalRestoreDraft = useSelector(state => state.uiIndexDB.isShowModalRestoreDrafts);
  const {
    loading: uiChecklistManagerLoading,
    appealId,
    checklistManagerState,
    currentChecklist,
    questionIdToAnswerValue,
    addingCommentsMassAction
  } = useSelector(
    state =>
      pick(state.uiChecklistManager, [
        'loading',
        'appealId',
        'checklistManagerState',
        'currentChecklist',
        'questionIdToAnswerValue',
        'addingCommentsMassAction'
      ]),
    isEqual
  );
  useEffect(async () => {
    const answers = await getAnswersIndexDB(
      communicationId,
      currentChecklist?.checklistDefinitionId
    );
    const comments = await getCommentsIndexDB(
      communicationId,
      currentChecklist?.checklistDefinitionId
    );
    if (
      (answers?.length !== 0 || comments?.length !== 0) &&
      currentChecklist?.checklistDefinitionId
    ) {
      await dispatch(toggleIsShowModalRestoreDraftsOpen());
    } else {
      dispatch(toggleIsShowModalRestoreDraftsClose());
    }
  }, [currentChecklist?.checklistDefinitionId, communicationId]);
  const editorRef = useRef();

  const loading = useSelector(
    state => uiChecklistManagerLoading || state.checklistDefinitionsResource.loading
  );

  const checklistDefinition = useSelector(
    state => getChecklistsDefinitionsByIds(state)[currentChecklist?.checklistDefinitionId],
    isEqual
  );

  const questionGroups = useSelector(
    state => getChecklistDefinitionQuestionGroupsWithQuestions(state, checklistDefinition),
    isEqual
  );

  const questionsWithValuesAndBindings = useSelector(
    state =>
      getChecklistDefinitionQuestions(state, checklistDefinition).map(question => ({
        ...question,
        value: questionIdToAnswerValue[question.id]
      })),
    isEqual
  );

  const appeal = useSelector(state => getAppellationsByIds(state)[appealId]);

  const reviewAuthorId = review?.reviewerId;
  const currentUserId = useSelector(state => getCurrentUser(state)?.id);

  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);
  const canEditReview = currentUserPermissions.includes(PERMISSIONS.CAN_EDIT_REVIEW);
  const disabledByState =
    checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED ||
    (review && canEditReview ? false : review && reviewAuthorId !== currentUserId);

  const handleCommentChange = debounce(async e => {
    if (!review?.id) {
      await saveCommentIndexDB(communicationId || 'new', currentChecklist?.checklistDefinitionId, {
        text: e.text,
        id: Date.now(),
        general: true
      });
    }
    dispatch(updateCurrentChecklist({ comment: e.text }));
  }, 100);

  const score = getScore({
    checklist: currentChecklist,
    checklistDefinition,
    checklistManagerState:
      appeal && !appeal.questionObjectionsProcessed
        ? CHECKLIST_MANAGER_STATES.EDITING
        : checklistManagerState,
    questionsWithValuesAndBindings
  });

  const comment = currentChecklist?.comment || '';

  // Скрыть/Показать все комментарии
  const handleOpenAllComments = () => {
    // Скрыть комменатрии
    if (addingCommentsMassAction.length >= 1) {
      dispatch(setAddingCommentsToQuestionId(null));
      dispatch(clearAddingCommentsMassAction());
      return;
    }
    // Показать все комменатрии
    questionGroups.forEach(questionGroup => {
      questionGroup.questions.forEach(question => {
        const { id } = question;
        dispatch(setAddingCommentsToQuestionId(id));
        dispatch(setAddingCommentsMassAction(id));
      });
    });
  };
  const handleRestoreDrafts = async () => {
    const answers = await getAnswersIndexDB(
      communicationId,
      currentChecklist?.checklistDefinitionId
    );
    const comments = await getCommentsIndexDB(
      communicationId,
      currentChecklist?.checklistDefinitionId
    );
    answers.forEach(answer => {
      dispatch(updateAnswer(answer));
    });
    for (const comment of comments) {
      if (typeof comment?.id === 'number') {
        dispatch(updateCurrentChecklist({ comment: comment.text }));
      } else {
        dispatch(addComment(comment));
      }

      if (comment.uploadedFilesIds) {
        for (const id of comment.uploadedFilesIds) {
          try {
            const file = await getFileIndexDB(id);
            if (file) {
              dispatch(uploadedFilesResource.actions.loadByIdSucceed(file));
            }
          } catch (error) {
            console.error(`Error loading file with ID: ${id}`, error);
          }
        }
      }
    }
    await dispatch(toggleIsShowModalRestoreDraftsClose());
  };
  const handleSaveToDraftComments = async () => {
    if (!review?.id) {
      await saveCommentIndexDB(communicationId, currentChecklist?.checklistDefinitionId, {
        text: comment,
        id: Date.now()
      });
    }
  };

  if (customCommunicationView) {
    return !isEmpty(currentChecklist) ? (
      <SCard loading={loading}>
        <SRow>
          <SCol span={24}>
            <SRow>
              <ChecklistDefinitionContainer>
                <SRow type="flex" justify="space-between" align="middle" style={{ width: '100%' }}>
                  <SCol>
                    <SText strong>{title}</SText>
                  </SCol>
                  <SCol>
                    <SRow
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={[16, 0]}
                      margin="auto -4px"
                    >
                      <SCol>
                        <AppealHead isCustomCommunication reviewId={review?.id} />
                      </SCol>
                      <SCol>
                        <AttachTag block={false} reviewId={review?.id} />
                      </SCol>
                      {review?.id && customCommunicationType !== 'phone_call' && (
                        <SCol>
                          <CopyLinkButton reviewId={review?.id} />
                        </SCol>
                      )}
                      <SCol>
                        <ScoreContainer>
                          <span>{t('components.checklistManager.totalScore')}</span>
                          <Score>{score}</Score>
                        </ScoreContainer>
                      </SCol>
                    </SRow>
                  </SCol>
                </SRow>
              </ChecklistDefinitionContainer>
            </SRow>
          </SCol>

          {checklistDefinition?.isGroupable ? (
            questionGroups.map(({ name, id, questions = [] }) => (
              <StyledCollapse bordered={false} defaultActiveKey="key" key={id}>
                <StyledPanel header={<SText strong>{name}</SText>} key="key" style={{ border: 0 }}>
                  <SRow gutter={[16, 16]}>
                    <QuestionsContainer
                      communicationId={communicationId}
                      reviewId={review?.id}
                      questions={questions}
                      customCommunicationView={customCommunicationView}
                      disabled={disabledByState}
                      onAutoFail={onAutoFail}
                      comments={comments}
                      checklistDefinition={checklistDefinition}
                      onDeleteComment={onDeleteComment}
                      onUpdateComment={onUpdateComment}
                      allowCommenting={allowCommenting}
                      handleCommentSave={handleCommentSave}
                      communication={communication}
                    />
                  </SRow>
                </StyledPanel>
              </StyledCollapse>
            ))
          ) : (
            <SRow width="100%" gutter={[16, 16]}>
              <QuestionsContainer
                communicationId={communicationId}
                reviewId={review?.id}
                questions={get(questionGroups[0], 'questions')}
                customCommunicationView={customCommunicationView}
                disabled={disabledByState}
                onAutoFail={onAutoFail}
                comments={comments}
                checklistDefinition={checklistDefinition}
                onDeleteComment={onDeleteComment}
                onUpdateComment={onUpdateComment}
                allowCommenting={allowCommenting}
                handleCommentSave={handleCommentSave}
                communication={communication}
              />
            </SRow>
          )}
        </SRow>
        <Modal
          title={t('components.checklistManager.generalCommentModal.title')}
          visible={addingComment}
          onCancel={() => dispatch(setAddingComment(false))}
          cancelButtonProps={{ style: { display: 'none' } }}
          okText={t('components.checklistManager.generalCommentModal.ok')}
          cancelText={t('components.checklistManager.generalCommentModal.cancel')}
          onOk={() => dispatch(setAddingComment(false))}
        >
          {disabledByState ? (
            <SCard>
              <InlineCommentText
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comment, {
                    ALLOWED_ATTR: ['target', 'href']
                  })
                }}
              />
            </SCard>
          ) : (
            <Editor
              commentState={{ text: comment }}
              setCommentState={handleCommentChange}
              itsGeneralComment
            />
          )}
        </Modal>
      </SCard>
    ) : null;
  }

  return loading ? (
    <SCard bordered shadowed>
      <Skeleton active />
    </SCard>
  ) : (
    <SRow>
      <SCol span={24} marginTop="-12px">
        <SRow paddingTop="12px">
          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={handleOpenAllComments}
            disabled={!allowCommenting}
          >
            {addingCommentsMassAction.length >= 1
              ? 'Скрыть все комментарии'
              : 'Открыть все комментарии'}
          </Button>
        </SRow>
        {isShowModalRestoreDraft ? (
          <SRow paddingTop="12px">
            <Button type="default" style={{ width: '100%' }} onClick={handleRestoreDrafts}>
              Восстановить черновик
            </Button>
          </SRow>
        ) : null}
        {questionGroups.map(({ questions, name, id }) => (
          <SRow gutter={[8, 8]} key={id} paddingTop="12px">
            <SCol span={24}>
              {checklistDefinition?.isGroupable && (
                <SText fontSize="18px" fontWeight="400" lineHeight="24px">
                  {name}
                </SText>
              )}
            </SCol>
            <QuestionsContainer
              communicationId={communicationId}
              reviewId={review?.id}
              questions={questions}
              customCommunicationView={customCommunicationView}
              disabled={disabledByState}
              onAutoFail={onAutoFail}
              comments={comments}
              checklistDefinition={checklistDefinition}
              onDeleteComment={onDeleteComment}
              onUpdateComment={onUpdateComment}
              allowCommenting={allowCommenting}
              handleCommentSave={handleCommentSave}
              communication={communication}
            />
          </SRow>
        ))}
      </SCol>
      <SCol span={24} paddingTop="16px" paddingBottom="8px">
        <SText>{t('components.checklistManager.generalCommentModal.title')}</SText>
        {disabledByState ? (
          <SCard width="100%" paddingTop="8px">
            <InlineCommentText
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(comment, {
                  ALLOWED_ATTR: ['target', 'href']
                })
              }}
            />
          </SCard>
        ) : (
          <SCard
            bordered
            shadowed={
              isOpenCommentEditor
                ? 'inset 0px -1px 0px #597ef7, inset 0px 1px 0px #597ef7, inset -1px 0px 0px #597ef7, inset 1px 0px 0px #597ef7'
                : false
            }
            border={isOpenCommentEditor ? '2px solid #C5D2FB' : null}
          >
            {isOpenCommentEditor ? (
              <SRow>
                <SCol width="100%" type="flex" justify="space-between">
                  <Editor
                    ref={editorRef}
                    commentState={{ text: comment }}
                    setCommentState={handleCommentChange}
                    allowHidden={false}
                    actionsComponent={
                      <>
                        <SButton
                          onClick={() => {
                            handleCommentChange({ text: initialChecklistCommentState });
                            setOpenCommentEditor(!isOpenCommentEditor);
                          }}
                        >
                          {t('general.cancel')}
                        </SButton>
                        <SButton
                          type="primary"
                          onClick={() => {
                            setOpenCommentEditor(!isOpenCommentEditor);
                            setInitialChecklistCommentState(initialChecklistCommentState);
                            handleSaveToDraftComments();
                          }}
                        >
                          {t('general.send')}
                        </SButton>
                      </>
                    }
                  />
                </SCol>
              </SRow>
            ) : (
              <SRow>
                <SCol span={22}>
                  <InlineCommentText
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(comment, {
                        ALLOWED_ATTR: ['target', 'href']
                      })
                    }}
                  />
                </SCol>
                <SCol span={2}>
                  <SRow type="flex" align="end">
                    <SCol>
                      <StyledButton
                        size="icon"
                        icon={<Icon icon={Edit3} size="20px" />}
                        color="var(--black_2)"
                        backgroundColor="transparent"
                        onClick={() => {
                          setOpenCommentEditor(!isOpenCommentEditor);
                          setInitialChecklistCommentState(comment);
                        }}
                      />
                    </SCol>
                  </SRow>
                </SCol>
              </SRow>
            )}
          </SCard>
        )}
      </SCol>
    </SRow>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
`;

export default ChecklistBody;
