export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'caricato con successo',
      loadFailed: 'Impossibile caricare il file',
      maxFileText: 'Dimensione del file',
      maxFileSize: 'supera i 30 MB.',
      fileFormatText: 'Estensione del file non corretta',
      fileFormatMessage: 'sono consentiti solo file mp3, wav o csv.',
      fileFormat: 'È possibile scaricare file in formato mp3, wav o csv, fino a 30 megabyte / file',
      deleteSuccess: 'Rimosso con successo',
      editSuccess: 'Modifiche salvate',
      saveEditingTooltip: 'Per salvare, è necessario compilare i campi obbligator'
    },
    buttons: {
      loadCalls: 'Caricare le chiamate',
      downloadExample: 'Esempio CSV',
      delete: 'Rimuovere',
      save: 'Mantenere',
      cancelEditing: 'Annulla modifiche'
    },
    table: {
      columns: {
        operator: 'Operatore',
        direction: 'Tipo di chiamata',
        startedAt: 'Data di chiamata',
        startedAtPlaceholder: 'Scegli il tempo',
        clientPhoneNumber: 'Numero di telefono',
        duration: 'Durata della chiamata',
        actions: 'Azioni',
        communicationType: 'Telefonata',
        fileSize: 'Dimensione',
        directionTitle: 'Direzione',
        selectOperator: 'Seleziona un operatore'
      },
      uinstalledCallDirection: 'Non installato',
      confirmChange: {
        title: 'Le modifiche non verranno salvate',
        ok: 'Continuare',
        cancel: 'Annulla'
      },
      buttons: {
        cancel: 'Annulla',
        edit: 'Modificare',
        delete: 'Elimina'
      },
      confirmDelete: {
        title: 'Sicuro?',
        content: 'Non è possibile annullare questa azione',
        ok: 'Rimuovere',
        cancel: 'Cancellazione'
      },
      cancelEditing: {
        title: 'Redazione',
        content: 'Sei sicuro di voler annullare le modifiche?',
        ok: 'ОК',
        cancel: 'Cancellazione'
      }
    }
  }
};
