export const setTranscriptionAnchors = (commentText, transcriptionParts) => {
  if (typeof commentText !== 'string' || !Array.isArray(transcriptionParts)) {
    return '';
  }

  // Функция для нахождения самой длинной общей подстроки между двумя строками.
  const longestCommonSubstring = (str1, str2) => {
    const matrix = Array(str1.length + 1)
      .fill(null)
      .map(() => Array(str2.length + 1).fill(0));
    let maxLength = 0;
    let endIndex = 0;

    for (let i = 1; i <= str1.length; i++) {
      for (let j = 1; j <= str2.length; j++) {
        if (str1[i - 1] === str2[j - 1]) {
          matrix[i][j] = matrix[i - 1][j - 1] + 1;
          if (matrix[i][j] > maxLength) {
            maxLength = matrix[i][j];
            endIndex = i;
          }
        }
      }
    }

    return str1.substring(endIndex - maxLength, endIndex);
  };

  // Функция для извлечения всех текстов, взятых в одинарные кавычки, из строки.
  const extractQuotedTexts = text => {
    const regex = /'([^']+)'/g;
    return [...text.matchAll(regex)].map(match => match[1]);
  };

  // Функция для нахождения совпадающих текстов между комментарием и транскрипцией.
  const findMatchingTexts = (commentText, transcription) => {
    // Извлекаем все цитаты из комментария.
    const quotedTexts = extractQuotedTexts(commentText);
    // Для каждой цитаты ищем самое длинное совпадение в частях транскрипции.
    return quotedTexts.map(originalText => {
      const longestMatch = transcription.reduce((longest, part) => {
        const match = longestCommonSubstring(originalText, part.text);
        return match.length > longest.length ? match : longest;
      }, '');

      // Находим часть транскрипции, которая содержит найденное совпадение.
      const foundTranscriptionComment = transcription.find(part =>
        part.text.toLowerCase().includes(longestMatch.toLowerCase())
      );

      return {
        originalText,
        anchorText: foundTranscriptionComment ? foundTranscriptionComment.text : null
      };
    });
  };

  // Функция для создания текста с якорями на основе найденных совпадений.
  const createAnchoredText = (matches, originalText) => {
    let modifiedText = originalText;
    matches.forEach(({ originalText, anchorText }) => {
      if (anchorText) {
        const anchorWithoutDot = anchorText.replace(/\.$/, '');
        const lowerAnchorText = anchorWithoutDot.toLowerCase();

        const regex = new RegExp(`('${originalText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}')`, 'g');

        modifiedText = modifiedText.replace(regex, (match, p1) => {
          return `<a href="#${lowerAnchorText}">${p1}</a>`;
        });
      }
    });
    return modifiedText;
  };

  const matches = findMatchingTexts(commentText, transcriptionParts);
  return createAnchoredText(matches, commentText);
};
