export default {
  clientInteractionPage: {
    review: 'Valutazioni: ',
    messages: {
      emptyQuestionsComments: 'Impossibile salvare la convalida vuota. Valuta almeno un criterio',
      emptyQuestionsValues:
        "Non e' possibile salvare solo i commenti sui criteri. Aggiungi un punteggio a uno o piu' criteri",
      needToChangeEditMode:
        "Per eseguire l'azione, passa alla modalita' di mofica della valutazione",
      commentSuccessfullyUpdated: 'Commento aggiornato',
      updateCommentFailed: 'Impossibile aggiornare il commento',
      closeDrawer: {
        title: 'Chiudere la valutazione?',
        content: 'Una volta chiuso, i risultati della scansione non verranno salvati.'
      }
    },
    alerts: {
      disabledUser: {
        title: "Uff.. Questo utente non e' attivo 😢",
        description: "Per procedere alla valutazione, attiva l'utente.",
        button: 'Vai alle impostazioni'
      }
    }
  }
};
