import { Form, Select, Modal } from 'antd';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SDatePicker from 'components/Standard/SDatePicker';
import SInput from 'components/Standard/SInput';
import SRow from 'components/Standard/SRow';
import SSelect from 'components/Standard/SSelect';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  COMMUNICATIONS_DISTRIBUTION_METHODS,
  CONVERT_PERIOD_STRING,
  PERIOD_REPEAT
} from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import SText from 'components/Standard/SText';
import { Info } from 'components/Info';
import { HelpCircle } from 'react-feather';
import { isEmpty } from 'validate.js';

const { Option } = Select;

const ScheduleSettings = ({ onChange, schedule, name, onChangeName }) => {
  const { t } = useTranslation();
  const [periodTimeFrom, setPeriodTimeFrom] = useState(schedule?.periodTimeFrom);
  const [periodTimeTo, setPeriodTimeTo] = useState(schedule?.periodTimeTo);
  const [repeatEvery, setRepeatEvery] = useState(
    schedule?.repeatEvery || PERIOD_REPEAT.never.value
  );
  const [repeatEveryCount, setRepeatEveryCount] = useState(schedule?.repeatEveryCount || 1);
  const [stopRepeatingAt, setStopRepeatingAt] = useState(schedule?.stopRepeatingAt || undefined);
  const [distributionType, setDistributionType] = useState(
    schedule?.distributionType ||
      COMMUNICATIONS_DISTRIBUTION_METHODS.COMMUNICATIONS_NUMBER_BY_OPERATORS.value
  );

  useEffect(() => {
    onChange({
      periodTimeFrom,
      periodTimeTo,
      repeatEvery,
      stopRepeatingAt,
      distributionType,
      repeatEveryCount
    });
  }, [
    distributionType,
    periodTimeFrom,
    periodTimeTo,
    repeatEvery,
    repeatEveryCount,
    stopRepeatingAt
  ]);

  const { RangePicker } = SDatePicker;

  const disabledPeriodTimeTo = current => {
    return current && current <= periodTimeTo;
  };

  const disabledStopRepeatingAt = disabledPeriodTimeTo;

  const isDisabledByPeriod = ({ period }) => {
    const addedPeriod = moment(periodTimeFrom)
      ?.clone()
      ?.add(1, CONVERT_PERIOD_STRING[period]);

    return moment(periodTimeTo)?.isAfter(addedPeriod);
  };

  return (
    <SRow gutter={[0, 12]} style={{ marginBottom: '-6px' }}>
      <SCol span={24}>
        <SCard bordered shadowed width="100%">
          <SRow gutter={[0, 10]} style={{ marginBottom: '-11px' }} justify="space-between">
            <SCol span={24} style={{ paddingTop: 0 }}>
              <SText fontSize="24px" fontWeight="500">
                {t('workPlanTaskConfigurationEditor.schedule.taskConfig')}
              </SText>
            </SCol>
            <SCol span={24}>
              <Form layout="vertical">
                <SRow gutter={[16, 0]} align="bottom">
                  <SCol span={6}>
                    <Form.Item
                      label={
                        <>
                          <SText fontWeight={500}>
                            {t('workPlanTaskConfigurationEditor.schedule.taskName')}
                          </SText>
                          <SText
                            color="var(--red_8)"
                            style={{
                              fontFamily: 'SimSun, sans-serif',
                              marginLeft: 2,
                              fontWeight: 500
                            }}
                          >
                            *
                          </SText>
                        </>
                      }
                    >
                      <SInput
                        placeholder={t('workPlanTaskConfigurationEditor.schedule.taskName')}
                        size="large"
                        width="100%"
                        value={name}
                        onChange={e => onChangeName(e.target.value)}
                      />
                    </Form.Item>
                  </SCol>

                  <SCol span={6}>
                    <Form.Item
                      label={
                        <SText fontWeight={500}>
                          {t('workPlanTaskConfigurationEditor.schedule.distribution')}
                        </SText>
                      }
                    >
                      <SSelect
                        size="large"
                        width="100%"
                        value={distributionType}
                        onChange={setDistributionType}
                      >
                        {Object.values(COMMUNICATIONS_DISTRIBUTION_METHODS).map(method => (
                          <Option key={method.value} value={method.value}>
                            {t(method.title)}
                          </Option>
                        ))}
                      </SSelect>
                    </Form.Item>
                  </SCol>

                  <SCol span={6}>
                    <Form.Item
                      label={
                        <>
                          <SText fontWeight={500}>
                            {t('workPlanTaskConfigurationEditor.schedule.periodTask')}
                          </SText>
                          <SText
                            fontWeight={500}
                            color="var(--red_8)"
                            style={{ fontFamily: 'SimSun, sans-serif', marginLeft: 2 }}
                          >
                            *
                          </SText>
                          <Info
                            iconProps={{ marginLeft: '4px' }}
                            icon={HelpCircle}
                            tooltip={{
                              title: t(
                                'workPlanTaskConfigurationEditor.schedule.info.schedulePeriodTask'
                              )
                            }}
                          />
                        </>
                      }
                    >
                      <RangePicker
                        style={{ height: '40px', width: '100%' }}
                        showTime={{
                          defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')]
                        }}
                        format="DD/MM/YYYY HH:mm"
                        value={[periodTimeFrom, periodTimeTo]}
                        disabledDate={current => current && current < moment().startOf('day')}
                        onChange={val => {
                          if (!val || isEmpty(val)) {
                            setPeriodTimeFrom(undefined);
                            setPeriodTimeTo(undefined);
                            return undefined;
                          }

                          if (repeatEvery !== PERIOD_REPEAT.never.value) {
                            return Modal.confirm({
                              title: t(
                                'workPlanTaskConfigurationEditor.schedule.scheduleChangePeriodTask'
                              ),
                              onOk: () => {
                                setPeriodTimeFrom(val[0]);
                                setPeriodTimeTo(val[1]);
                                setRepeatEvery(PERIOD_REPEAT.never.value);
                                setStopRepeatingAt(undefined);
                              },
                              okText: t('general.continue'),
                              cancelText: t('general.cancel')
                            });
                          }

                          setPeriodTimeFrom(val[0]);
                          setPeriodTimeTo(val[1]);
                        }}
                      />
                    </Form.Item>
                  </SCol>

                  <SCol span={6}>
                    <Form.Item
                      label={
                        <SText fontWeight={500}>
                          {t('workPlanTaskConfigurationEditor.schedule.toRepeat')}
                        </SText>
                      }
                    >
                      <SSelect
                        size="large"
                        width="100%"
                        value={repeatEvery}
                        onChange={setRepeatEvery}
                      >
                        {Object.values(PERIOD_REPEAT).map(period => (
                          <Option
                            disabled={
                              period.value === PERIOD_REPEAT.never.value
                                ? false
                                : isDisabledByPeriod({ period: period.value })
                            }
                            value={period.value}
                          >
                            {t(period.title)}
                          </Option>
                        ))}
                      </SSelect>
                    </Form.Item>
                  </SCol>

                  {repeatEvery !== PERIOD_REPEAT.never.value && (
                    <SCol span={6}>
                      <Form.Item
                        label={
                          <>
                            <SText fontWeight={500}>
                              {t('workPlanTaskConfigurationEditor.schedule.stopTask')}
                            </SText>
                            <Info
                              iconProps={{ marginLeft: '4px' }}
                              icon={HelpCircle}
                              tooltip={{
                                title: t(
                                  'workPlanTaskConfigurationEditor.schedule.info.scheduleStopTask'
                                )
                              }}
                            />
                          </>
                        }
                      >
                        <SDatePicker
                          format="DD/MM/YYYY HH:mm"
                          placeholder={t('workPlanTaskConfigurationEditor.schedule.date')}
                          width="100%"
                          height="40px"
                          value={stopRepeatingAt}
                          onChange={setStopRepeatingAt}
                          disabledDate={disabledStopRepeatingAt}
                          showTime={{ defaultValue: moment('23:59', 'HH:mm') }}
                          showNow={false}
                        />
                      </Form.Item>
                    </SCol>
                  )}
                </SRow>
              </Form>
            </SCol>
          </SRow>
        </SCard>
      </SCol>
    </SRow>
  );
};

export default ScheduleSettings;
