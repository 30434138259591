export default {
  checklistsPage: {
    title: 'Формы оценки',
    info:
      'Нажмите "Создать новую форму оценки" для настройки новой формы оценки. Вы можете редактировать количество критериев, шкалу и все условия оценки в нем в статусе "Черновик". Чтобы начать оценивать коммуникации по этой форме оценки нажмите "Опубликовать". В списке ниже, сохранены все формы оценки в разных статусах: Черновик, Опубликован и Архив. В архив помещайте те формы оценки, по которым вы уже не хотите производить оценку. Нажмите "Создать критерий для формы оценки" для нового пункта оценки, который может быть включен в любую форму оценки. Чтобы увидеть все критерии всех форм оценки нажмите кнопку "Критерии".',
    infoButton: 'Подробнее здесь',
    buttons: {
      createChecklists: 'Создать форму оценки',
      createQuestion: 'Создать критерий',
      createPrompt: 'Создать промпт',
      checklists: 'Формы оценки',
      questions: 'Критерии',
      prompts: 'Промпты'
    },
    messages: {
      question: 'Критерии',
      questionSuccessfullyCreated: 'успешно создан',
      questionSuccessfullyUpdated: 'успешно обновлен',
      createQuestionfailed: 'Не удалось создать критерий',
      updateQuestionfailed: 'Не удалось обновить критерий'
    },
    addQuestionModal: {
      title: 'Добавить критерий',
      buttons: {
        libraryQuestion: 'Критерий из библиотеки',
        newQuestion: 'Новый критерий'
      },
      messages: {
        disabledByWeight:
          'Вы не можете добавить критерий, так как вес критериев формы оценки достиг 100%.',
        info:
          'Создайте новый критерий или добавьте критерий из библиотеки. \n Библиотека содержит критерии которые возможно добавлены в другие формы оценки.'
      }
    },
    checklistInfo: {
      emptyName: 'Имя формы оценки не установлено',
      copy: '(Копия)',
      messages: {
        copySuccessfullyCreated: 'Копия создана',
        createCopyfailed: 'Не удалось создать копию',
        checklistSuccessfullyDeleted: 'Формы оценки удалена',
        checklistSuccessfullyPublished: 'Форма оценки опубликована',
        completedQuestionsSumm: 'Сумма весов критериев в форме оценки должна составить 100%',
        emptyChecklistQuestions: 'Для публикации в форме оценки должны быть критерии',
        checklistSuccessfullyArchived: 'Форма оценки перемещёна в архив'
      },
      confirmArchive: {
        title: 'Переместить форму оценки в архив?',
        description: 'Архивированные формы оценки недоступны для выбора при проведении проверки',
        ok: 'В архив',
        cancel: 'Отмена'
      },
      confirmDelete: {
        title: 'Удалить форму оценки?',
        description:
          'Удаленные форму оценки нельзя восстановить. Если данная форма оценки для вас не актуальна, вы можете перенести ее в архив',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      confirmPublish: {
        title: 'Опубликовать форму оценки?',
        description:
          ' После публикации формы оценки вы не сможете редактировать: метод и расчет оценки, шкалу оценки и веса критерия. \n Перед публикацией убедитесь что все настроено верно',
        ok: 'Продолжить',
        cancel: 'Отмена'
      },
      menu: {
        setting: 'Перейти в настройки формы оценки',
        copy: 'Создать копию',
        archive: 'Переместить в архив',
        delete: 'Удалить форму оценки'
      },
      breadcrumbs: {
        checklists: 'Формы оценки',
        checklistSettings: 'Настройки формы оценки',
        checklistQuestions: 'Настройки критериев'
      },
      buttons: {
        publish: 'Опубликовать форму оценки',
        actions: 'Действия'
      },
      items: {
        ratingMethod: 'Метод оценки: ',
        ratingCalculation: 'Расчет оценки: ',
        scaleType: 'Шкала для оценки: ',
        communicationTypes: 'Типы коммуникаций: ',
        units: 'Отделы: ',
        totalChecklistScore: 'Итоговый балл по форме оценки: ',
        checklistScore: 'Балл по форме оценки: ',
        totalSumPercentage: 'Совокупный вес всех критериев в форме оценки, в процентах',
        sumPercentage: 'Вес критериев'
      },
      modal: {
        title: 'Настройка зон оценки',
        ok: 'Опубликовать',
        cancel: 'Отмена'
      }
    },
    checklistItems: {
      messages: {
        question: 'Критерий',
        questionSuccessfullyCreated: 'создан и добавлен в форму оценки',
        questionSuccessfullyUpdated: 'обновлён',
        createQuestionFailed: 'Не удалось создать или обновить критерий'
      }
    },
    editQuestionGroupModal: {
      title: 'Настройки группы критериев',
      ok: {
        create: 'Создать',
        update: 'Обновить'
      },
      cancel: 'Отмена',
      alert: {
        description:
          'Объединяйте критерии формы оценки в группы, чтобы отслеживать результаты и их динамику по одному общему навыку сотрудника. Это помогает структурировать саму форму оценки и систему оценки знаний и компетенций сотрудников.'
      },
      form: {
        fields: {
          name: 'Название',
          namePlaceholder: 'Введите название группы критериев',
          nameInfo:
            'Название группы которое будет отображаться в форме оценки при проверке и на странице аналитики',
          percentageCalculationStartingWithZero:
            'У группы может быть отрицательное процентное значение',
          percentageCalculationStartingWithZeroPlaceholder:
            'В этом случае расчет производится от нуля.'
        },
        messages: {
          enterName: 'Пожалуйста введите название'
        }
      }
    },
    question: {
      confirmDelete: {
        title: 'Вы уверены что хотите удалить критерий из формы оценки?',
        description: 'Он будет по прежнему доступен в списке критериев',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      menu: {
        edit: 'Редактировать критерий',
        copy: 'Создать копию',
        delete: 'Удалить критерий'
      },
      weight: 'Вес критерия',
      tooltip: {
        title: 'Критерий с автофейлом'
      }
    },
    questionGroup: {
      ratingMethod: 'Метод расчета не установлен',
      confirmDelete: {
        title: 'Удалить группу?',
        description: 'Критерии в удаляемой группе также будут удалены из текущей формы оценки',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      menu: {
        edit: 'Редактировать группу',
        copy: 'Создать копию',
        delete: 'Удалить группу'
      },
      sumRates: 'Балл по группе',
      sumRatesInfo: 'Совокупный балл по группе',
      sumPercentage: 'Вес группы',
      sumPercentageInfo: 'Совокупный вес всех критериев в группе, в процентах',
      buttons: {
        addQuestion: 'Добавить критерий',
        addQuestionGroup: 'Добавить группу'
      }
    },
    questionsFromLibraryModal: {
      title: 'Выберите критерии из библиотеки',
      ok: 'Добавить',
      cancel: 'Отмена',
      alert: {
        title: 'В данном списке отображаются критерии только с подходящей шкалой и зонами оценки'
      }
    }
  }
};
