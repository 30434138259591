import { destroy, put, post } from 'redux-bees';

// * calls
const reviewLibraryItemsEndpoints = apiPath => ({
  createReviewLibraryItem: {
    method: post,
    path: `/api/v3/libraries/create`
  },
  updateReviewLibraryItem: {
    method: put,
    path: `${apiPath}/library_items/:id`
  },
  deleteReviewLibraryItem: {
    method: destroy,
    path: `${apiPath}/library_items/:id`
  }
});

export default reviewLibraryItemsEndpoints;
