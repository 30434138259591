import { message, Modal, Space } from 'antd';
import SButton from 'components/Standard/SButton';
import SText from 'components/Standard/SText';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { getScore } from 'core/utils/ratingsCalculations';
import { every, get, isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { getChecklistDefinitionQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { getCurrentUser, getCurrentUserPermissions } from 'redux/selectors/users';
import { setChecklistManagerState } from 'redux/ui/checklistManager/reducer';
import { getAppellationsByIds } from 'redux/selectors/appeals';
import { AppealHead } from './components/AppealHead';

const ChecklistManagerFooter = ({ onSubmit, reviewId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isReviewSubmit, setReviewSubmit] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const {
    checklistManagerState,
    currentChecklist,
    questionIdToAnswerValue,
    appealId,
    previousChecklistManagerState
  } = useSelector(state => state.uiChecklistManager, isEqual);
  const checklistDefinitionsByIds = useSelector(
    state => getChecklistsDefinitionsByIds(state),
    isEqual
  );
  const loadingSubmitButton = useSelector(
    state => state.checklistsResource.updateByIdStarted || state.checklistsResource.createStarted
  );

  const reviewAuthorId = useSelector(state =>
    get(state.reviewsResource.byIds[reviewId], 'reviewerId', '')
  );
  const { commentsToSave } = useSelector(state => state.uiChecklistEditor);
  const appeal = useSelector(state => getAppellationsByIds(state)[appealId], isEqual);
  const checklistDefinitionId = get(currentChecklist, 'checklistDefinitionId');
  const checklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistQuestions = useSelector(
    state => getChecklistDefinitionQuestions(state, checklistDefinition),
    isEqual
  );
  const currentUserId = useSelector(state => get(getCurrentUser(state), 'id', ''));
  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);
  const canEditReview = currentUserPermissions.includes(PERMISSIONS.CAN_EDIT_REVIEW);
  let questions = [];

  if (!isEmpty(currentChecklist) && !isEmpty(checklistDefinition)) {
    questions = checklistDefinition ? checklistQuestions : [];
  }

  const answeredQuestionsValues = Object.values(questionIdToAnswerValue);

  const allQuestionsAnswered =
    questions.length === answeredQuestionsValues.length &&
    every(answeredQuestionsValues, value => !isNil(value)) &&
    !isEmpty(answeredQuestionsValues);

  useEffect(() => {
    if (allQuestionsAnswered) setReviewSubmit(true);
  }, [answeredQuestionsValues]);

  const onEditButton = () => dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));

  const onSubmitButton = async event => {
    event.preventDefault();

    if (isEmpty(currentChecklist)) {
      return message.info(
        t('components.checklistManager.checklistManagerHead.emptyChecklist.title'),
        4
      );
    }

    // if (isEmpty(currentChecklist)) {
    //   return Modal.confirm({
    //     centered: true,
    //     title: t('components.checklistManager.checklistManagerHead.emptyChecklistModal.title'),
    //     content: t(
    //       'components.checklistManager.checklistManagerHead.emptyChecklistModal.description'
    //     ),
    //     okText: t('components.checklistManager.checklistManagerHead.emptyChecklistModal.ok'),
    //     cancelText: t(
    //       'components.checklistManager.checklistManagerHead.emptyChecklistModal.cancel'
    //     ),
    //     loading: isLoading,
    //     onOk: async () => {
    //       setReviewSubmit(true);
    //       setLoading(true);
    //       await onSubmit();
    //       setLoading(false);
    //     }
    //   });
    // }

    if (!allQuestionsAnswered)
      return Modal.confirm({
        centered: true,
        title: t('components.checklistManager.checklistManagerHead.allQuestionsAnswered.title'),
        content: t(
          'components.checklistManager.checklistManagerHead.allQuestionsAnswered.description'
        ),
        okText: t('components.checklistManager.checklistManagerHead.allQuestionsAnswered.ok'),
        cancelText: t(
          'components.checklistManager.checklistManagerHead.allQuestionsAnswered.cancel'
        ),
        loading: isLoading,
        onOk: async () => {
          setReviewSubmit(true);
          setLoading(true);
          await onSubmit();
          setLoading(false);
        }
      });

    if (!isEmpty(commentsToSave)) {
      return Modal.confirm({
        centered: true,
        title: t('components.checklistManager.checklistManagerHead.unsentComments.title'),
        content: t('components.checklistManager.checklistManagerHead.unsentComments.description'),
        okText: t('components.checklistManager.checklistManagerHead.unsentComments.ok'),
        cancelText: t('components.checklistManager.checklistManagerHead.unsentComments.cancel'),
        loading: isLoading,
        onOk: async () => {
          setReviewSubmit(true);
          setLoading(true);
          await onSubmit();
          setLoading(false);
        }
      });
    }
    await onSubmit();
  };

  const questionsWithValuesAndBindings = questions.map(question => ({
    ...question,
    value: questionIdToAnswerValue[question.id],
    percentage: get(question, 'binding.percentage', 1)
  }));

  const score = getScore({
    checklist: currentChecklist,
    checklistDefinition,
    checklistManagerState:
      appeal && !appeal.questionObjectionsProcessed
        ? CHECKLIST_MANAGER_STATES.EDITING
        : checklistManagerState,
    questionsWithValuesAndBindings
  });

  const componentStates = {
    [CHECKLIST_MANAGER_STATES.EDITING]: (
      <SRow
        type="flex"
        style={{ marginBottom: 0, borderTop: '1px solid #DFE1E8' }}
        backgroundColor="#FFFFFF"
        padding={16}
        align="middle"
        justify="space-between"
      >
        <SText fontSize="18px" ellipsis lineHeight="24px" fontWeight={400}>
          {`${t('general.result')}: ${score}`}
        </SText>
        <SButton
          type="primary"
          loading={loadingSubmitButton}
          showLoad
          onClick={onSubmitButton}
          size="big"
          width="auto"
          fontSize="14px"
        >
          {previousChecklistManagerState === CHECKLIST_MANAGER_STATES.SAVED
            ? t('components.checklistManager.checklistManagerHead.buttons.updateReview')
            : t('components.checklistManager.checklistManagerHead.buttons.saveReview')}
        </SButton>
      </SRow>
    ),
    [CHECKLIST_MANAGER_STATES.SAVED]: (
      <SRow
        type="flex"
        display="flex"
        style={{ marginBottom: 0, borderTop: '1px solid #DFE1E8' }}
        backgroundColor="#FFFFFF"
        padding={16}
        align="middle"
        justify="space-between"
        wrap={false}
      >
        <SCol flex="none">
          <SText fontSize="18px" ellipsis lineHeight="24px" fontWeight={400}>
            {`${t('general.result')}: ${score}`}
          </SText>
        </SCol>
        <SCol display="flex" flex="auto" justifyContent="flex-end">
          <Space size={[8, 0]} direction="horizontal">
            {(currentUserId === reviewAuthorId || canEditReview) && (
              <SButton
                type="default"
                onClick={onEditButton}
                disabled={appeal}
                size="big"
                width="auto"
                fontSize="14px"
              >
                {previousChecklistManagerState === CHECKLIST_MANAGER_STATES.INITIAL
                  ? t('components.checklistManager.checklistManagerHead.buttons.updateReview')
                  : t('components.checklistManager.checklistManagerHead.buttons.editReview')}
              </SButton>
            )}
            <AppealHead reviewId={reviewId} />
          </Space>
        </SCol>
      </SRow>
    )
  };

  return (
    <>
      <Prompt
        when={checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING && !isReviewSubmit}
        message={t('components.checklistManager.checklistManagerHead.messages.unsavedScore')}
      />
      <SCol span={24}>{componentStates[checklistManagerState]}</SCol>
    </>
  );
};

export default React.memo(withRouter(ChecklistManagerFooter));
