import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { CLIENT_INTERACTION_TYPES, SYMBOLIC_TIME_RANGE } from 'core/utils/constants';
import { orderBy, get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { customFieldsResource } from 'redux/resources/customFields';
import { rolesResource } from 'redux/resources/roles';
import { statusesResource } from 'redux/resources/status';
import { unitsResource } from 'redux/resources/units';
import uniqid from 'uniqid';
import ClientInteractionFilters from './ClientInteractionFilters';

const defaultClientInteractions = {};

const FiltersSettings = ({
  onChange,
  clientInteractionsConfigs = defaultClientInteractions,
  schedule,
  allSelected,
  selected,
  isSelected,
  toggle,
  setSelected
}) => {
  const { t } = useTranslation();
  const [clientInteractions, setClientInteractions] = useState(clientInteractionsConfigs);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(rolesResource.operations.load({ include: 'levels' }));
    dispatch(statusesResource.operations.load());
    dispatch(customFieldsResource.operations.load({ pagination: false }));
    dispatch(unitsResource.operations.load());
    dispatch(statusesResource.operations.load());
  }, []);

  const addClientInteraction = () => {
    const id = uniqid();
    const data = {
      ...clientInteractions,
      [id]: {
        id,
        position: Object.values(clientInteractions).length + 1,
        clientInteractionType: CLIENT_INTERACTION_TYPES.PHONE_CALL.value,
        clientInteractionsMaxCount: 0,
        fields: [
          {
            key: 'clientInteractionsPeriod',
            settings: {
              clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.LAST_SEVEN_DAYS,
              clientInteractionTimeFrom: undefined,
              clientInteractionTimeTo: undefined
            }
          }
        ]
      }
    };

    setClientInteractions(data);
    onChange(data);
  };

  const onClientInteractionUpdate = communcation => {
    const data = {
      ...clientInteractions,
      [communcation.id]: { ...clientInteractions[communcation.id], ...communcation }
    };
    setClientInteractions(data);
    onChange(data);
  };

  const onDeleteClientInteraction = id => {
    const withDeleted = {
      ...clientInteractions,
      [id]: {
        ...clientInteractions[id],
        _destroy: '1'
      }
    };
    setClientInteractions(withDeleted);
    onChange(withDeleted);
  };

  return (
    <SRow gutter={[0, 12]} style={{ marginBottom: '-6px' }}>
      <SCol span={24}>
        {!isEmpty(clientInteractions) && (
          <>
            {orderBy(Object.values(clientInteractions), 'position').reduce(
              (acc, clientInteractionType) => {
                if (get(clientInteractionType, '_destroy')) return acc;
                const component = (
                  <SCard bordered shadowed width="100%" marginBottom="16px">
                    <ClientInteractionFilters
                      clientInteractionType={clientInteractionType}
                      fields={clientInteractionType.fields}
                      schedule={schedule}
                      onChange={onClientInteractionUpdate}
                      onDelete={onDeleteClientInteraction}
                      allSelected={allSelected}
                      selected={selected}
                      isSelected={isSelected}
                      toggle={toggle}
                      setSelected={setSelected}
                    />
                  </SCard>
                );

                return [...acc, component];
              },
              []
            )}
          </>
        )}
        <SCard bordered shadowed width="100%">
          <SButton
            type="link"
            padding="0"
            icon={<Icon icon={Plus} />}
            onClick={addClientInteraction}
          >
            {t('workPlanTaskConfigurationEditor.filters.addFilters')}
          </SButton>
        </SCard>
      </SCol>
    </SRow>
  );
};

export default FiltersSettings;
