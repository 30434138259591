import { createAction, createReducer } from '@reduxjs/toolkit';
import { SYMBOLIC_TIME_RANGE } from 'core/utils/constants';

const defaultState = {
  viewType: 'employees',
  filters: {
    checklistDefinitionsIds: 'all',
    unitsIds: [],
    clientInteractionTimeFrom: undefined,
    clientInteractionTimeTo: undefined,
    clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH
  }
};

export const updateFilters = createAction('uiTeamAnalyticsPage/updateFilters');
export const changeViewType = createAction('uiTeamAnalyticsPage/changeViewType');

export const teamAnalyticsPageReducer = createReducer(defaultState, {
  [updateFilters]: (state, { payload }) => {
    return { ...state, filters: { ...state.filters, ...payload } };
  },
  [changeViewType]: (state, { payload }) => {
    return { ...state, viewType: payload };
  }
});

export default teamAnalyticsPageReducer;
