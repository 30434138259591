import SCard from 'components/Standard/SCard';
import React from 'react';
import PhoneCallInformation from './PhoneCallInformation';
import RecordPlayer from './RecordPlayer';

const PhoneCallPlayer = ({
  call,
  review,
  comments,
  messages,
  onMessageClick,
  allowAttachTags = true,
  showTags = true,
  shouldLoad = true,
  fromLibrary = false,
  fromNewReview = false
}) => {
  return (
    <SCard loading={!call && !comments && !review} bordered shadowed>
      <PhoneCallInformation call={call} fromNewReview={fromNewReview} />
      <RecordPlayer
        comments={comments}
        call={call}
        review={review}
        allowAttachTags={allowAttachTags}
        showTags={showTags}
        shouldLoad={shouldLoad}
        messages={messages}
        onMessageClick={onMessageClick}
        fromLibrary={fromLibrary}
        fromNewReview={fromNewReview}
      />
    </SCard>
  );
};

export default React.memo(PhoneCallPlayer);
