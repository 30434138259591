import { Tooltip, Typography } from 'antd';
import { RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { valueFormatter } from 'core/utils/valueFormatter';
import { Trans } from 'react-i18next';
import { get, isNaN, keyBy, mean, sum } from 'lodash';
import moment from 'moment';
import React from 'react';
import PeriodInfo from 'components/PeriodInfo';
import { valueToColorZoneColor, getDatesArray } from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({ filters, timeFrom, timeTo, checklistDefinition }) => {
  const { isWeekendView, historyGroupingPeriod } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });

  const datesColumns = datesArray.map(day => ({
    title: (
      <PeriodInfo startDate={day} groupingPeriod={historyGroupingPeriod} isVisibleYear={false} />
    ),
    dataIndex: moment(day).format('YYYY-MM-DD'),
    key: moment(day).format('YYYY-MM-DD'),
    align: 'center',
    width: datesArray.length < 15 ? null : 90,
    render: (text, { key, item }) => {
      return {
        props: {
          style: {
            background: text?.color
          }
        },
        children:
          key === 'header'
            ? text?.value
            : (text?.value || text?.value === 0) && (
                <Text strong={key === 'footer'}>
                  {valueFormatter({
                    value: text?.value,
                    ratingMode: checklistDefinition.ratingMode
                  })}
                </Text>
              )
      };
    }
  }));

  return [
    {
      title: <Trans i18nKey="dashboardPage.tableChecklistItemsHistory.table.columns.dates" />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 270,
      ellipsis: true,
      render: (text, { key }) => {
        return {
          props: {
            style: {
              padding: '12px 5px'
            }
          },
          children: (
            <Tooltip title={<Trans i18nKey={text} />} placement="topLeft">
              <Text strong={key === 'footer'}>
                <Trans i18nKey={text} />
              </Text>
            </Tooltip>
          )
        };
      }
    },
    ...datesColumns,
    {
      title: <Trans i18nKey="dashboardPage.tableChecklistItemsHistory.table.columns.totalScore" />,
      dataIndex: 'totalScore',
      key: 'totalScore',
      align: 'center',
      fixed: 'right',
      width: 140,
      render: (text, record) => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: (
            <Text strong>
              {record.key === 'header'
                ? text?.value
                : valueFormatter({
                    value: text?.value,
                    ratingMode: checklistDefinition.ratingMode
                  })}
            </Text>
          )
        };
      }
    }
  ];
};

export const prepareTableData = ({
  filters,
  timeFrom,
  timeTo,
  checklistItems,
  checklistDefinition,
  reviewsCountHistory,
  averageScoreByQuestions,
  checklistDefinitionAverageScore,
  checklistDefinitionAverageScoreHistory,
  averageScoreByQuestionsHistory,
  averageScoreByQuestionGroupsHistory
}) => {
  const { viewMode, isWeekendView, historyGroupingPeriod } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });

  const checklistItemsByIds = keyBy(checklistItems, 'id');

  const checklistItem =
    viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
      ? checklistItems.filter(item => item.type === 'checklist-questions')
      : checklistItems.filter(item => item.type !== 'checklist-questions');

  const widgetItem =
    viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
      ? averageScoreByQuestionsHistory
      : averageScoreByQuestionGroupsHistory;

  const filteredWidgetValues = keyBy(
    Object.values(widgetItem).filter(wItem =>
      checklistItem.map(cItem => cItem.id).includes(wItem.id)
    ),
    'id'
  );

  const zoneDetector = ({ itemId }) => {
    // * если режим вопросы, и чек-лист в цифрах = зона вопроса
    // * если режим вопросы, и чек-лист в процентах = зона чек-листа
    // * если режим группа, и чек-лист в цифрах = не рисуем зону
    // * если режим группа, и чек-лист в процентах = зона чек-листа

    if (viewMode === WIDGET_VIEW_MODES.QUESTIONS.value) {
      return checklistDefinition.ratingMode === RATING_MODE.NUMBERS
        ? checklistItemsByIds[itemId]?.colorZones
        : checklistDefinition.colorZones;
    }
    if (viewMode !== WIDGET_VIEW_MODES.QUESTIONS.value) {
      return checklistDefinition.ratingMode === RATING_MODE.NUMBERS
        ? undefined
        : checklistDefinition.colorZones;
    }
  };

  const mapChecklistItemsScore = () => {
    return checklistItem.reduce((acc, item) => {
      const history = datesArray.reduce((acc, day) => {
        const formattedDayString = moment(day).format('YYYY-MM-DD');
        return {
          ...acc,
          [formattedDayString]: {
            value: parseFloat(filteredWidgetValues[item.id]?.history[formattedDayString]),
            color: valueToColorZoneColor({
              value: parseFloat(filteredWidgetValues[item.id]?.history[formattedDayString]),
              colorZones: zoneDetector({ itemId: item.id })
            })
          }
        };
      }, {});

      const getChecklistItemTotalScore = () => {
        const questionGroupTotalScore = mean(
          Object.values(get(widgetItem, `${item.id}.history`, {}))
            .map(value => parseFloat(value))
            .filter(value => !isNaN(value))
        );

        const questionTotalScore = get(averageScoreByQuestions, `${item.id}.value`);

        return viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
          ? questionTotalScore
          : questionGroupTotalScore;
      };

      const data = {
        key: item.id,
        name: item.name,
        ...history,
        totalScore: {
          value: getChecklistItemTotalScore(),
          color: valueToColorZoneColor({
            value: getChecklistItemTotalScore(),
            colorZones: zoneDetector({ itemId: item.id })
          })
        },
        item
      };
      return [...acc, data];
    }, []);
  };

  return [
    {
      key: 'header',
      name: 'dashboardPage.tableChecklistItemsHistory.table.columns.reviewsCount',
      ...datesArray.reduce((acc, day) => {
        const formattedDayString = [moment(day).format('YYYY-MM-DD')];
        return {
          ...acc,
          [formattedDayString]: {
            value: get(reviewsCountHistory, formattedDayString, ''),
            color: undefined
          }
        };
      }, {}),
      totalScore: {
        value: sum(Object.values(reviewsCountHistory)),
        color: undefined
      }
    },
    ...mapChecklistItemsScore(),
    {
      key: 'footer',
      name: 'dashboardPage.tableChecklistItemsHistory.table.columns.average',
      ...datesArray.reduce((acc, day) => {
        const formattedDayString = [moment(day).format('YYYY-MM-DD')];
        return {
          ...acc,
          [formattedDayString]: {
            value: parseFloat(get(checklistDefinitionAverageScoreHistory, formattedDayString, '')),
            color: valueToColorZoneColor({
              value: get(checklistDefinitionAverageScoreHistory, formattedDayString, ''),
              colorZones: checklistDefinition.colorZones
            })
          }
        };
      }, {}),
      totalScore: {
        value: parseFloat(checklistDefinitionAverageScore),
        color: valueToColorZoneColor({
          value: checklistDefinitionAverageScore,
          colorZones: checklistDefinition.colorZones
        })
      }
    }
  ];
};
