import { Col, Divider, Row } from 'antd';
import ClientInteractionDrawer from 'components/ClientInteractionDrawer';
import SCard from 'components/Standard/SCard';
import { PERMISSIONS } from 'core/utils/constants';
import { get, isEqual, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { getCurrentUser, getUserWithRoleAndUnitById } from 'redux/selectors/users';
import { loadCurrentUserById } from 'redux/ui/userAnalyticsPage/operations';
import { useDispatch, useSelector } from 'react-redux';
import Forbidden from 'pages/ErrorPages/Forbidden';
import SCol from 'components/Standard/SCol';
import styled from 'styled-components';
import UserAppeals from './UserAppeals/UserAppeals';
import UserAnalyticsPage from './AnalyticsChartsPanel/UserAnalyticsPage';
import NavigationMenu from './NavigationMenu';
import OrganizationSettingsPage from './OrganizationSettings/OrganizationSettingsPage';
import ReviewsWithComments from './ReviewsWithCommentsList/ReviewsWithComments';
import UserInfoPanel from './UserInfoPanel';
import UserReviewsTable from './UserReviewsTable';
import UserSettings from './UserSettings/UserSettingsPage';
import IntegrationsPage from './Integrations';
import UserCustomFieldsPage from './UserCustomFields/UserCustomFieldsPage';
import DatasetAiPage from '../DatasetAi/DatasetAiPage';
import AiSettingsPage from '../AiSettings/AiSettingsPage';

const StyledDivider = styled(Divider)`
  &.ant-divider {
    margin-top: 0;
  }
`;

const UserPage = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { id: userId } = useParams();
  const viewedUser = useSelector(state => getUserWithRoleAndUnitById(state, userId), isEqual);
  const currentUser = useSelector(getCurrentUser, isEqual);

  const isSelfPage = userId === currentUser.id;

  const initialLoad = async () => {
    setLoading(true);
    if (!isSelfPage && currentUser?.id)
      await dispatch(loadCurrentUserById({ userId: currentUser?.id }));
    await dispatch(loadCurrentUserById({ userId }));
    setLoading(false);
  };

  useEffect(() => {
    initialLoad();
  }, [userId]);

  const { t } = useTranslation();

  const currentUserPermissions = get(currentUser, 'role.permissions', []);
  const viewedUserPermissions = get(viewedUser, 'role.permissions', []);
  const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);

  const canManageIntegrations = currentUserPermissions.includes(
    PERMISSIONS.CAN_MANAGE_INTEGRATIONS
  );

  const canManageOrgSettings = currentUserPermissions.includes(
    PERMISSIONS.CAN_MANAGE_ORGANIZATION_STRUCTURE
  );

  const canAiAnalyzeClientInteractions = currentUserPermissions.includes(
    PERMISSIONS.CAN_AI_ANALYZE_CLIENT_INTERACTIONS
  );

  const viewedUserHasAnalytics = viewedUserPermissions.includes(
    PERMISSIONS.CAN_PERFORM_CLIENT_INTERACTION
  );

  const canViewAnalytics = isSelfPage
    ? viewedUserHasAnalytics &&
      currentUserPermissions.includes(PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_CHARTS_TAB)
    : viewedUserHasAnalytics &&
      currentUserPermissions.includes(PERMISSIONS.CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE);

  const canViewReviews =
    viewedUserHasAnalytics &&
    currentUserPermissions.includes(PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_REVIEWS_TAB);

  const canViewOperatorAppellations = currentUserPermissions.includes(
    PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_OPERATORS_APPELLATIONS_TAB
  );

  const canAiAnalysisClientInteractionsInOrganization = get(
    organizationInfo,
    'settings.ai.analysis.enabled',
    false
  );

  if (!canAiAnalyzeClientInteractions) {
    if (location.pathname.includes('dataset-ai') || location.pathname.includes('ai-settings')) {
      return <Redirect to={`/user/${currentUser.id}/user-settings`} />;
    }
  }

  if (
    !isSelfPage &&
    !currentUserPermissions.includes(PERMISSIONS.CAN_SEE_UNIT_OPERATORS_ANALYTICS_PAGE)
  ) {
    return <Forbidden data-testid="forbidden" />;
  }

  return (
    <Row>
      <Helmet>
        <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
      </Helmet>
      <Col span={5}>
        <SCard bodyPadding="0" rounded={false} minHeight="calc(100vh - 50px)" height="100%">
          <UserInfoPanel userId={userId} loading={loading} />
          <StyledDivider />
          <NavigationMenu
            isSelfPage={isSelfPage}
            canManageOrgSettings={canManageOrgSettings}
            canManageIntegrations={canManageIntegrations}
            canViewAnalytics={canViewAnalytics}
            canViewReviews={canViewReviews}
            currentUserPermissions={currentUserPermissions}
            canViewOperatorAppellations={canViewOperatorAppellations}
            canAiAnalyzeClientInteractions={canAiAnalyzeClientInteractions}
            canAiAnalysisClientInteractionsInOrganization={
              canAiAnalysisClientInteractionsInOrganization
            }
          />
        </SCard>
      </Col>

      <SCol span={19}>
        {loading ? (
          <SCard margin="16px" loading={loading} />
        ) : (
          <Row>
            <Col span={24}>
              <Switch>
                {(isSelfPage || canManageOrgSettings) && (
                  <Route path="/user/:id/user-settings">
                    <UserSettings />
                  </Route>
                )}

                {isSelfPage && canManageOrgSettings && (
                  <Route path="/user/:id/organization-settings/:tab">
                    <OrganizationSettingsPage />
                  </Route>
                )}

                {isSelfPage && canManageOrgSettings && (
                  <Redirect
                    from="/user/:id/organization-settings"
                    exact
                    to="/user/:id/organization-settings/units"
                  />
                )}

                {isSelfPage && canManageIntegrations && (
                  <Route path="/user/:id/integrations-settings">
                    <IntegrationsPage />
                  </Route>
                )}

                {(isSelfPage || canManageOrgSettings) &&
                  canAiAnalysisClientInteractionsInOrganization && (
                    <Route path="/user/:id/ai-settings">
                      <AiSettingsPage />
                    </Route>
                  )}

                {(isSelfPage || canManageOrgSettings) &&
                  canAiAnalysisClientInteractionsInOrganization && (
                    <Route path="/user/:id/dataset-ai">
                      <DatasetAiPage />
                    </Route>
                  )}

                {canViewAnalytics && (
                  <Route path="/user/:id/charts">
                    <UserAnalyticsPage />
                  </Route>
                )}

                {canViewOperatorAppellations && (
                  <Route path="/user/:id/appeals">
                    <UserAppeals />
                  </Route>
                )}

                {isSelfPage && canAiAnalysisClientInteractionsInOrganization && (
                  <Route path="/user/:id/custom-fields">
                    <UserCustomFieldsPage />
                  </Route>
                )}

                {canViewReviews && (
                  <>
                    <Route path="/user/:id/reviews">
                      <UserReviewsTable />
                    </Route>
                    <Route path="/user/:id/comments">
                      <ReviewsWithComments />
                    </Route>
                  </>
                )}

                {!isNil(viewedUser) && !canViewAnalytics && !canViewReviews && isSelfPage && (
                  <Redirect from="/user/:id/charts" to="/user/:id/user-settings" />
                )}
              </Switch>
            </Col>
          </Row>
        )}
      </SCol>

      <ClientInteractionDrawer shouldLoad />
    </Row>
  );
};

export default UserPage;
