import createListReducer from 'core/redux/lists/listReducer';
import { datasetsFileListResource } from 'redux/resources/datasetFileList';

export const listParams = {
  name: 'datasetsFileList',
  loader: datasetsFileListResource.operations.load,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const datasetsFileListReducer = reducer;

export { actions, datasetsFileListReducer, operations };
