export default {
  organizationSettingsPage: {
    tabs: {
      info: {
        tabName: 'Información',
        form: {
          currentPaymentPlan: 'Tarifa actual',
          registrationDate: 'Fecha de registro',
          usersCount: 'Registrado por el usuario',
          monthlyReviewsCount: 'Cantidad de llamadas evaluadas en el mes en curso'
        }
      },
      organizationSettings: {
        tabName: 'Ajustes de la organización',
        form: {
          organizationName: 'Nombre de la organización',
          organizationAddress: 'Dirección jurídica',
          organizationEmail: 'Correo electrónico de contacto',
          organizationPhoneNumber: 'Número de teléfono de contacto',
          pauseAudioOnCommenting: 'Detener la llamada mientras escribe un comentario'
        },
        customFields: { title: 'Información adicional sobre comunicaciones' }
      },
      paymentSettings: {
        tabName: 'Ajustes de pago',
        form: {
          currentPlanSlug: 'Tarifa',
          usersCount: 'Cantidad de usuarios',
          paymentSymbolicPeriod: 'Período de pago',
          paymentCurrency: 'Divisa de pago'
        }
      }
    },
    buttons: {
      updateData: 'Actualizar datos',
      paymentPlansInfo: 'Información sobre las tarifas',
      createPaymentRequest: 'Solicitar factura para el pago'
    },
    selectOptions: {
      paymentSymbolicPeriod: {
        month: '1 mes',
        year: '1 año'
      }
    },
    textHeaders: {
      paymentSumm: 'Suma a pagar:',
      paymentPlanDescription: 'Descripción de la tarifa: '
    },
    modal: {
      title: 'Información sobre la compañía',
      buttons: {
        cancel: 'Cancelar',
        send: 'Enviar'
      },
      form: {
        companyName: 'Nombre de la compañía',
        companyInn: 'CIF',
        companyPhone: 'Número de teléfono',
        companyEmail: 'Correo lectrónico'
      }
    },
    messages: {
      dataSuccessfullyUpdated: 'Datos actualizados',
      requestSuccessfullySended: 'Solicitud enviada',
      requestSendedFailed: 'No se logró enviar la solicitud',
      form: {
        validateMessages: {
          enterOrganizationName: 'Introduzca el nombre de la organización',
          enterEmail: 'Por favor introduzca su correo electrónico',
          worngEmail: 'Usted introdujo un correo electrónico incorrecto',
          enterPlanSlug: 'Por favor, elija la tarifa',
          enterUsersCount: 'Por favor introduzca la cantidad de usuarios',
          enterPaymentSymbolicPeriod: 'Por favor elija el período de pagos',
          enterPaymentCurrency: 'Por favor elija la divisa de pago',
          enterInn: 'Por favor introduzca el CIF',
          enterPhoneNumber: 'Por favor introduzca el número de teléfono'
        }
      }
    },
    other: {
      policyPT1: 'Estoy de acuerdo con',
      policyPT2: 'el contrato de oferta pública',
      policyPT3: 'y la política de privacidad'
    }
  }
};
