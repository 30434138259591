import api from 'core/api';
import decamelize from 'decamelize-keys-deep';
import { processJsonApiObject } from 'core/jsonapi';

export const passwordResetRequest = ({ ...params }) => {
  return async dispatch => {
    try {
      const decamelizedParams = decamelize({ ...params });
      const response = await api.passwordReset({
        ...decamelizedParams
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const passwordUpdateRequest = ({ ...params }) => {
  return async dispatch => {
    try {
      const decamelizedParams = decamelize({ ...params });
      const response = await api.passwordUpdate({
        ...decamelizedParams
      });
      const result = processJsonApiObject(response.body.data);
      return result;
    } catch (error) {
      console.log(error);
    }
  };
};
