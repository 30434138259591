import React from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Col } from 'antd';
import { ResponsivePie } from '@nivo/pie';
import { FLAGS_COLORS_LITERALS_RUS, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { preparePieData } from 'pages/DashboardPage/utils';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { useSelector } from 'react-redux';
import { tooltipSetter } from '../../utils';
import EmptyWidget from '../../Components/EmptyWidget';
import { WidgetCard } from '../../styled';

const RatingFlagsCount = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();

  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);

  let pieData = get(widgetsAnalyticsByIds, widgetData.id, []);
  const lastItem = Array.isArray(pieData) ? pieData[pieData.length - 1] : pieData;
  const dateLastUpdate = lastItem ? lastItem.dateLastUpdate : null;

  if (Array.isArray(pieData) && lastItem) {
    pieData = pieData.filter((item, idx) => idx !== pieData.length - 1);
  } else if (typeof widgetValue === 'object' && pieData !== null) {
    if (Object.prototype.hasOwnProperty.call(pieData, 'dateLastUpdate')) {
      pieData = { ...pieData };
      delete pieData.dateLastUpdate;
    }
  }

  const { id, type, filters, name } = widgetData;

  return (
    <>
      {isEmpty(pieData) ||
      loadingWidgetId === id ||
      isEmpty(
        preparePieData({ pieData, colorTypes: FLAGS_TO_COLORS_RELATIONS }).filter(
          item => item.value
        )
      ) ||
      isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={6} key={id}>
          <WidgetCard>
            <SMLWidgetHeader
              name={name}
              type={type}
              widgetData={widgetData}
              filters={filters}
              dateLastUpdate={dateLastUpdate}
            />

            <ResponsivePie
              data={preparePieData({ pieData, colorTypes: FLAGS_TO_COLORS_RELATIONS })}
              margin={{ top: 20, right: 40, bottom: 60, left: 40 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              colors={shape => shape.color}
              tooltip={tooltip =>
                tooltipSetter({
                  name: t(FLAGS_COLORS_LITERALS_RUS[tooltip.id]),
                  value: tooltip.value
                })
              }
              borderWidth={1}
              borderColor={{ from: 'color' }}
              enableRadialLabels={false}
              slicesLabelsSkipAngle={10}
              slicesLabelsTextColor="#333333"
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default RatingFlagsCount;
