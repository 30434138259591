export default {
  organizationSettingsPage: {
    tabs: {
      info: {
        tabName: 'Information',
        form: {
          currentPaymentPlan: 'Current tariff plan',
          registrationDate: 'Registration date',
          usersCount: 'Users registered',
          monthlyReviewsCount: 'Number of evaluated calls for the current month'
        }
      },
      organizationSettings: {
        tabName: 'Organisation settings',
        form: {
          organizationName: 'Organisation name',
          organizationAddress: 'Registered address',
          organizationEmail: 'Contact email',
          organizationPhoneNumber: 'Contact phone number',
          pauseAudioOnCommenting: 'Stopping the call while writing a comment'
        },
        customFields: { title: 'Additional information' }
      },
      paymentSettings: {
        tabName: 'Payment settings',
        form: {
          currentPlanSlug: 'Tariff plan',
          usersCount: 'The number of users',
          paymentSymbolicPeriod: 'Payment period',
          paymentCurrency: 'Payment currency'
        }
      }
    },
    buttons: {
      updateData: 'Update data',
      paymentPlansInfo: 'Tariff plans information',
      createPaymentRequest: 'Request a bill for the payment'
    },
    selectOptions: {
      paymentSymbolicPeriod: {
        month: '1 month',
        year: '1 year'
      }
    },
    textHeaders: {
      paymentSumm: 'Payment sum:',
      paymentPlanDescription: 'Tariff plan description: '
    },
    modal: {
      title: 'Company information',
      buttons: {
        cancel: 'Cancel',
        send: 'Send'
      },
      form: {
        companyName: 'Company name',
        companyInn: 'TIN/UNN',
        companyPhone: 'Phone number',
        companyEmail: 'Email'
      }
    },
    messages: {
      dataSuccessfullyUpdated: 'Data updated',
      requestSuccessfullySended: 'Request sent',
      requestSendedFailed: 'Failed to send request',
      form: {
        validateMessages: {
          enterOrganizationName: 'Enter company name',
          enterEmail: 'Please enter email',
          worngEmail: 'Invalid email',
          enterPlanSlug: 'Please choose tariff plan',
          enterUsersCount: 'Please enter the number of users',
          enterPaymentSymbolicPeriod: 'Please choose payment period',
          enterPaymentCurrency: 'Please choose payment currency',
          enterInn: 'Please enter TIN or UNN',
          enterPhoneNumber: 'Please enter рhone number'
        }
      }
    },
    other: {
      policyPT1: 'I agree with',
      policyPT2: 'public offer agreement',
      policyPT3: 'and privacy policy'
    }
  }
};
