import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';

export const DeactivatedUserLinkToCommunication = ({ children, notFieldsFilled = false }) => {
  const currentUser = useSelector(state => getCurrentUser(state));
  const { t } = useTranslation();
  // Если не заполнены все поля (/upload)
  if (notFieldsFilled) {
    return (
      <div
        style={{ display: 'inline', opacity: '.5' }}
        onClickCapture={e => {
          e.stopPropagation();
          e.preventDefault();

          Modal.info({
            okText: t('clientInteractionsPage.clientInteractionsTable.notFieldsFilled.ok'),
            title: t('clientInteractionsPage.clientInteractionsTable.notFieldsFilled.title'),
            content: (
              <div>
                <p>
                  {t('clientInteractionsPage.clientInteractionsTable.notFieldsFilled.description')}
                </p>
              </div>
            )
          });
        }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      style={{ display: 'inline', opacity: '.5' }}
      onClickCapture={e => {
        e.stopPropagation();
        e.preventDefault();

        Modal.info({
          okText: t(
            'clientInteractionsPage.clientInteractionsTable.deactivatedUserLinkToCommunicationModal.ok'
          ),
          cancelText: t(
            'clientInteractionsPage.clientInteractionsTable.deactivatedUserLinkToCommunicationModal.cancel'
          ),
          title: t(
            'clientInteractionsPage.clientInteractionsTable.deactivatedUserLinkToCommunicationModal.title'
          ),
          content: (
            <div>
              <p>
                {t(
                  'clientInteractionsPage.clientInteractionsTable.deactivatedUserLinkToCommunicationModal.description'
                )}
              </p>
              <a href={`/user/${currentUser?.id}/organization-settings/employees/`}>
                {t(
                  'clientInteractionsPage.clientInteractionsTable.deactivatedUserLinkToCommunicationModal.button'
                )}
              </a>
            </div>
          )
        });
      }}
    >
      {children}
    </div>
  );
};
