import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SSelect from 'components/Standard/SSelect';
import SButton from 'components/Standard/SButton';
import { useHistory } from 'react-router-dom';
import { actions } from 'redux/lists/workPlanTaskConfigurationsListReducer';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import HowToItUseButton from '../../../components/HowToItUseButton';

const Filters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;
  const history = useHistory();

  const { filters } = useSelector(state => state.workPlanTaskConfigurationsList);

  return (
    <SCard rounded={false}>
      <SRow type="flex" justify="space-between">
        <SCol>
          <SSelect
            width="200px"
            placeholder={t('general.status')}
            onChange={status => dispatch(actions.updateFilters({ status }))}
            value={filters.status}
            allowClear
          >
            {Object.values(WORK_PLAN_TASK_CONFIGURATIONS_STATUSES).reduce((acc, item) => {
              if (item.value === WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.stopped.value) return acc;
              const component = (
                <Option key={item.value} value={item.value}>
                  {t(item.title)}
                </Option>
              );
              return [...acc, component];
            }, [])}
          </SSelect>
        </SCol>
        <SCol>
          <SRow gutter={[16, 0]}>
            <SCol>
              <HowToItUseButton
                videoLink="https://wiki.qolio.ru/qolio-or-baza-znanii/videoinstrukcii/raspredelenie-nagruzki-video-obzor-funkcii"
                docsLink="https://wiki.qolio.ru/qolio-or-baza-znanii/vozmozhnosti-platformy/raspredelenie-nagruzki-obshii-obzor-funkcii"
                tourCode={274543}
              />
            </SCol>
            <SCol>
              <SButton
                type="primary"
                onClick={() => history.push('/work-plan/task-configurations/editor/new')}
              >
                {t('general.create')}
              </SButton>
            </SCol>
          </SRow>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default Filters;
