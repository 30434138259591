import { createAction, createReducer } from '@reduxjs/toolkit';
import uniqid from 'uniqid';

const defaultState = {
  byIds: {}
};

export const loadQuestionsByIds = createAction('Question/loadByIds');
export const createQuestion = createAction('Question/create');
export const updateQuestion = createAction('Question/update');
export const deleteQuestion = createAction('Question/delete');

export const questionsReducer = createReducer(defaultState, {
  [loadQuestionsByIds]: (state, action) => ({
    ...state,
    byIds: { ...state.byIds, ...action.payload }
  }),
  [createQuestion]: (state, action) => ({
    ...state,
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload
    }
  }),
  [updateQuestion]: (state, action) => ({
    ...state,
    byIds: {
      ...state.byIds,
      [action.payload.id]: action.payload
    }
  }),
  [deleteQuestion]: (state, action) => {
    const newByIds = { ...state.byIds };
    delete newByIds[action.payload.id];
    return { ...state, byIds: newByIds };
  }
});
