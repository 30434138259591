import isEmpty from 'lodash/isEmpty';
import api from 'core/api';
import { BACKGROUND_JOB_STATUSES } from 'core/utils/constants';
import { processJsonApiObject } from 'core/jsonapi';
import { setCurrentBackgroundJob, clearCurrentBackgroundJob } from './reducer';

export const pendingStatuses = [
  BACKGROUND_JOB_STATUSES.QUEUED,
  BACKGROUND_JOB_STATUSES.PENDING,
  BACKGROUND_JOB_STATUSES.RUNNING
];

export const saveBackgroundJob = backgroundJob => dispatch => {
  try {
    dispatch(setCurrentBackgroundJob(backgroundJob));
    return backgroundJob;
  } catch (error) {
    console.log(error);
  }
};

export const clearBackgroundJob = () => dispatch => {
  try {
    return dispatch(clearCurrentBackgroundJob());
  } catch (error) {
    console.log(error);
  }
};

export const checkBackgroundJob = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const stateBJ = state.backgroundJob.currentBackgroundJob;

    if (!isEmpty(stateBJ)) {
      const response = await api.getBackgroundJob({
        id: stateBJ.id
      });

      const backgroundJob = processJsonApiObject(response.body.data);

      dispatch(
        pendingStatuses.includes(backgroundJob.status)
          ? saveBackgroundJob(backgroundJob)
          : clearBackgroundJob()
      );

      return backgroundJob;
    } else {
      dispatch(clearCurrentBackgroundJob());
    }
  } catch (error) {
    console.log(error);
  }
};
