import { Col, Row } from 'antd';
import CommentsPanel from 'components/Comments/CommentsPanel';
import PhoneCallPlayer from 'components/PhoneCallPlayer';
import { pick } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { getCommentsFilteredByPermission } from 'redux/selectors/comments';
import { getCurrentUserPermissions } from 'redux/selectors/users';

const ClientInteraction = ({ call, reviewId, comments, commentsLoading, fromLibrary = false }) => {
  return (
    <Row gutter={[16, 16]} style={{ position: 'sticky', top: '5%' }}>
      <Col span={24}>
        <PhoneCallPlayer
          call={call}
          comments={comments}
          allowAttachTags={false}
          review={{ id: reviewId }}
          fromLibrary={fromLibrary}
        />
      </Col>

      <Col span={24}>
        <CommentsPanel
          reviewId={reviewId}
          comments={comments}
          loading={commentsLoading}
          showCommentsList
          allowCommenting={false}
          allowActions={false}
          useAffix
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { reviewId } = state.reviewLibraryItemsResource.byIds[ownProps.id];
  const review = state.reviewsResource.byIds[reviewId];
  if (!review || review.loading || state.reviewsResource.loading) {
    return { loading: true, reviewId };
  }
  const currentUserPermissions = getCurrentUserPermissions(state);
  const comments = Object.values(pick(state.commentsResource.byIds, review.commentsIds || []));
  const filteredCommentsByPermission = getCommentsFilteredByPermission({
    currentUserPermissions,
    comments
  });
  const call = state.phoneCallsResource.byIds[review.phoneCallId];

  if (!call) {
    return { loading: true, reviewId };
  }

  return {
    commentsLoading: state.commentsResource.loading,
    reviewId,
    call,
    comments: filteredCommentsByPermission
  };
};

export default connect(mapStateToProps, null)(ClientInteraction);
