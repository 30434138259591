import createListReducer from 'core/redux/lists/listReducer';
import { phoneCallsResourceV3 } from 'redux/resources/callsV3';

const defaultState = {
  callsByIds: {},
  editingCallId: null,
  filters: {
    direction: 'all',
    status: 'all'
  }
};

const { actions, reducer, operations } = createListReducer({
  name: 'uploadedCallsV3',
  defaultState,
  rebuildQuery: true,
  loader: phoneCallsResourceV3.operations.load
});

const uploadedCallsReducerV3 = reducer;

export { actions, uploadedCallsReducerV3, operations };
