export default {
  clientInteractionPage: {
    review: 'Evaluations: ',
    messages: {
      emptyQuestionsComments: 'You cannot save an empty check. Evaluate at least one criterion',
      emptyQuestionsValues:
        'You cannot save only comments to questions. Add a score to one or more questions on the scorecard.',
      needToChangeEditMode: 'Go to the evaluation editing mode to perform the action.',
      commentSuccessfullyUpdated: 'Comment successfully updated.',
      updateCommentFailed: 'Failed to update comment.',
      closeDrawer: {
        title: 'Close evaluation?',
        content: 'When closed, the scan results will not be saved.'
      }
    },
    alerts: {
      disabledUser: {
        title: 'Uh... this user is not active 😢',
        description: 'To perform the evaluation, make the user active.',
        button: 'Go to Settings.'
      }
    }
  }
};
