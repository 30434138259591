import { TagOutlined } from '@ant-design/icons';
import { Col, Dropdown, Input, Menu, Row, Tooltip } from 'antd';
import CustomTag from 'components/Tags/CustomTag';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { getReviewTaskDefinitionsIds } from 'redux/selectors/taskDefinitions';
import { getCurrentUser } from 'redux/selectors/users';
import { setChecklistManagerState } from 'redux/ui/checklistManager/reducer';
import { addTasks } from 'redux/ui/clientInteractionPage/reducer';
import styled from 'styled-components';
import TasksSection from './TasksSection';

const AttachTag = ({
  allowAttachTags = true,
  showTags = true,
  block = true,
  loadTasksDefinitions,
  setChecklistManagerState,
  taskDefinitionsByIds,
  reviewTaskDefinitionsIds,
  uiTaskDefinitionsIds = [],
  addTasks,
  reviewId,
  currentUserId,
  currentReviewAuthorId,
  truncate
}) => {
  useEffect(() => {
    if (showTags && reviewId && reviewTaskDefinitionsIds?.length > 0) {
      addTasks({ reviewId, taskDefinitionsIds: reviewTaskDefinitionsIds });
    }
  }, [reviewTaskDefinitionsIds]);
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const canEditReviews = useSelector(state =>
    get(getCurrentUser(state), 'role.permissions', []).includes(PERMISSIONS.CAN_EDIT_REVIEW)
  );
  const onChange = ({ key }) => {
    if (key !== 'item_0') {
      setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING);
      addTasks({
        reviewId,
        taskDefinitionsIds: uiTaskDefinitionsIds.includes(key)
          ? uiTaskDefinitionsIds.filter(id => id !== key)
          : [...uiTaskDefinitionsIds, key]
      });
      setSearchTerm('');
    }
  };

  const isAllowToEdit = () => {
    if (!reviewId) return true;
    if (reviewId && (currentUserId === currentReviewAuthorId || canEditReviews)) return true;
    return false;
  };

  const allowToEditTags = isAllowToEdit() && allowAttachTags;
  const onSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const filteredTaskDefinitions = Object.values(taskDefinitionsByIds).filter(taskDefinition => {
    if (
      taskDefinition?.status === 'disabled' &&
      !uiTaskDefinitionsIds.includes(taskDefinition.id)
    ) {
      return false;
    }
    return taskDefinition.labelName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const menu = (
    <Menu selectedKeys={uiTaskDefinitionsIds} onClick={onChange}>
      <Menu.Item onClick={e => e.domEvent.stopPropagation()}>
        <Input
          placeholder={t('organizationStructure.tabs.employees.table.filters.employeesSearch')}
          value={searchTerm}
          onChange={onSearchChange}
          onClick={e => e.stopPropagation()}
          style={{ minWidth: '150px' }}
        />
      </Menu.Item>
      {filteredTaskDefinitions.map(taskDefinition => (
        <StyledMenuItem value={taskDefinition.id} key={taskDefinition.id}>
          <CustomTag
            color={taskDefinition.labelColor}
            text={taskDefinition.labelName}
            truncate={truncate}
          />
        </StyledMenuItem>
      ))}
    </Menu>
  );

  return (
    <Row
      type="flex"
      style={{ width: block ? '100%' : 'auto', height: '100%', marginBottom: '-4px' }}
      align="middle"
    >
      <Col>{showTags && <TasksSection reviewId={reviewId} truncate={truncate} />}</Col>
      {allowToEditTags && (
        <Col style={{ minWidth: '24px', height: '16px' }}>
          <Tooltip title={t('components.recordPlayer.attachTag.title')} placement="topLeft">
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomLeft"
              getPopupContainer={triggerNode => {
                return triggerNode.parentNode;
              }}
              overlayClassName="ant-dropdown-placement-bottomLeft"
              overlayStyle={{ maxHeight: '340px', overflow: 'hidden auto' }}
              onVisibleChange={isVisible => {
                if (isVisible) {
                  loadTasksDefinitions({ pagination: 'false' });
                } else {
                  setSearchTerm('');
                }
              }}
            >
              <StyledIcon />
            </Dropdown>
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

const StyledIcon = styled(TagOutlined)`
  &.anticon {
    font-size: var(--font-size);
    position: absolute;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  &&.ant-dropdown-menu-item-selected {
    background: var(--primary-light);
  }
`;

const mapStateToProps = (state, ownProps) => {
  const reviewId = get(ownProps, 'reviewId', undefined);
  const reviewsByIds = state.reviewsResource.byIds;
  const uiTaskDefinitionsIds = state.uiClientInteractionPage.tasks.taskDefinitionsIds;
  const taskDefinitionsByIds = Object.values(state.taskDefinitionsResource.byIds).filter(
    item => item.labelType === 'manual'
  );

  const reviewTaskDefinitionsIds = getReviewTaskDefinitionsIds(state, reviewId);
  const currentReviewAuthorId = get(reviewsByIds, `${reviewId}.reviewerId`, '');
  const currentUserId = get(getCurrentUser(state), 'id', '');

  return {
    taskDefinitionsByIds,
    uiTaskDefinitionsIds,
    reviewTaskDefinitionsIds,
    currentUserId,
    currentReviewAuthorId
  };
};

const mapDispatchToProps = {
  loadTasksDefinitions: taskDefinitionsResource.operations.load,
  setChecklistManagerState,
  addTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachTag);
