import React from 'react';
import { isEmpty } from 'lodash';
import { Empty, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CommentsPanel from './CommentsPanel';

export const CommentsModal = ({
  visible,
  comments,
  onCancel,
  allowCommenting,
  onCommentSave,
  onDeleteComment,
  onUpdateComment,
  onCancelComment,
  editingCommentId,
  communication
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      destroyOnClose
      width={768}
      visible={visible}
      footer={false}
      onCancel={onCancel}
      title={t('general.comments')}
      keyboard={!editingCommentId}
    >
      {isEmpty(comments) && (
        <Empty
          description={t('components.commentsModal.empty')}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}

      <CommentsPanel
        communication={communication}
        comments={comments}
        showCommentsList
        allowCommenting={allowCommenting}
        addingComment
        allowSelectTimestamp={false}
        onCancelComment={onCancelComment}
        onCommentSave={onCommentSave}
        onDeleteComment={onDeleteComment}
        onUpdateComment={onUpdateComment}
      />
    </Modal>
  );
};
