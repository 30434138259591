import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DATE_PICKER_MODES,
  DATE_PICKER_MODES_CUSTOM_KEYS,
  SYMBOLIC_TIME_RANGE_LITERALS
} from 'core/utils/constants';
import { CloseCircleFilled } from '@ant-design/icons';
import SText from 'components/Standard/SText';
import SButton from 'components/Standard/SButton';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SCard from 'components/Standard/SCard';
import styled from 'styled-components';
import useEvent from '@react-hook/event';
import { every, isEmpty } from 'lodash';
import uniqid from 'uniqid';
import PeriodPicker from './components/PeriodPicker';
import CalendarPicker from './components/Calendar';
import { handleClick, onChangePeriod } from './utils';

const StyledButton = styled(Button)`
  &.ant-btn {
    padding: 0 11px;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }
`;

const datePickerPositions = {
  left: 'calc(100% - 750px)',
  right: '-1',
  center: 'calc(50% - 375px)'
};

const CustomDatePicker = ({
  onChange,
  value,
  position,
  height = '32px',
  top = 34,
  mode = DATE_PICKER_MODES.default,
  filtersMode,
  disabled = false,
  isClientInteractionPage = false,
  loading,
  allowClear,
  page
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  // * отображение кнопки очистки даты
  const [isHoverButton, setHoverButton] = useState(false);

  // * позиция дейтпикера для страницы коммуникаций
  const [pickerPosition, setPickerPosition] = useState(
    ref.current?.getBoundingClientRect()?.y - 361 - top * 2
  );

  const isEmptyValue = every(Object.values(value), isEmpty);
  const { symbolicTimeRange, timeFrom, timeTo } = value;

  // * локальные значения нужны для того чтобы хранить значения до нажатия на кнопку "применить"
  const [symbolicPeriod, setSymbolicPeriod] = useState(symbolicTimeRange);

  const [timePeriod, setTimePeriod] = useState({
    timeFrom: moment(timeFrom),
    timeTo: moment(timeTo)
  });

  // * если есть filtersMode, то применяем его, если нет то применяет переданный mode
  const [checkboxMode, setCheckboxMode] = useState(
    filtersMode || (mode === DATE_PICKER_MODES.default ? DATE_PICKER_MODES.clientInteraction : mode)
  );

  const [pickerId, setPickerId] = useState(uniqid());
  const [openSelect, setOpenSelect] = useState(false);

  const setInitalValues = () => {
    setSymbolicPeriod(symbolicTimeRange);
    setTimePeriod({
      timeFrom: moment(timeFrom),
      timeTo: moment(timeTo)
    });
    setCheckboxMode(
      filtersMode ||
        (mode === DATE_PICKER_MODES.default ? DATE_PICKER_MODES.clientInteraction : mode)
    );
  };

  // * обновляем локальные значения по мере загрузки данных
  useEffect(() => {
    setInitalValues();
    onChangePeriod({
      period: symbolicTimeRange,
      setTimePeriod,
      setSymbolicPeriod,
      timeFrom: moment(timeFrom),
      timeTo: moment(timeTo)
    });
  }, [value]);

  const onClick = () => {
    setOpenSelect(false);
    setHoverButton(false);

    if (checkboxMode === DATE_PICKER_MODES.clientInteraction) {
      return onChange({
        clientInteractionTimeFrom: !symbolicPeriod
          ? timePeriod.timeFrom.startOf('day').toISOString(true)
          : undefined,
        clientInteractionTimeTo: !symbolicPeriod
          ? timePeriod.timeTo.endOf('day').toISOString(true)
          : undefined,
        clientInteractionSymbolicTimeRange: !symbolicPeriod ? undefined : symbolicPeriod,
        reviewTimeFrom: undefined,
        reviewTimeTo: undefined,
        reviewSymbolicTimeRange: undefined
      });
    }

    if (checkboxMode === DATE_PICKER_MODES.review) {
      return onChange({
        reviewTimeFrom: !symbolicPeriod
          ? timePeriod.timeFrom.startOf('day').toISOString(true)
          : undefined,
        reviewTimeTo: !symbolicPeriod
          ? timePeriod.timeTo.endOf('day').toISOString(true)
          : undefined,
        reviewSymbolicTimeRange: !symbolicPeriod ? undefined : symbolicPeriod,
        clientInteractionTimeFrom: undefined,
        clientInteractionTimeTo: undefined,
        clientInteractionSymbolicTimeRange: undefined
      });
    }

    // * реализация работы кастомных ключей
    return onChange({
      [DATE_PICKER_MODES_CUSTOM_KEYS[mode].timeFrom]: !symbolicPeriod
        ? timePeriod.timeFrom.startOf('day').toISOString(true)
        : undefined,
      [DATE_PICKER_MODES_CUSTOM_KEYS[mode].timeTo]: !symbolicPeriod
        ? timePeriod.timeTo.endOf('day').toISOString(true)
        : undefined,
      [DATE_PICKER_MODES_CUSTOM_KEYS[mode].symbolicTimeRange]: !symbolicPeriod
        ? undefined
        : symbolicPeriod
    });
  };

  useEvent(document, 'click', e => {
    handleClick({ e, setOpenSelect, pickerId, isClientInteractionPage, setInitalValues });
  });

  const getPeriodValue = () => {
    if (isEmptyValue) return 'Период';

    const text = !symbolicPeriod
      ? `${moment(timePeriod.timeFrom).format('DD/MM/YYYY')} ~ ${moment(timePeriod.timeTo).format(
          'DD/MM/YYYY'
        )}`
      : `${t(SYMBOLIC_TIME_RANGE_LITERALS[symbolicPeriod])}`;

    return `${text}${
      mode === DATE_PICKER_MODES.default
        ? `, по дате ${
            filtersMode === DATE_PICKER_MODES.clientInteraction || filtersMode === undefined
              ? 'коммуникации'
              : 'оценки'
          }`
        : ''
    }`;
  };

  const StyledIcon = styled(CloseCircleFilled)`
    &&.anticon {
      color: rgba(0, 0, 0, 0.25);
      font-size: 12px;
      position: absolute;
      right: 10px;
      top: 9px;
    }

    &&.anticon:hover {
      color: #8c8c8c;
    }
  `;

  return (
    <SRow
      style={{ position: 'relative', width: '100%' }}
      onMouseEnter={() => setHoverButton(true)}
      onMouseLeave={() => setHoverButton(false)}
    >
      <StyledButton
        style={{ height }}
        disabled={disabled || loading}
        className="date-picker-select-btn"
        ref={ref}
        onClick={() => {
          setPickerPosition(ref.current?.getBoundingClientRect()?.y - 361 - top * 2);
          setOpenSelect(!openSelect);
          // * если пикер был открыт, там поменяли значение и на него опять нажали - сбросить значения на предыдущие
          if (openSelect) setInitalValues();
        }}
        id={pickerId}
      >
        <SText
          id={pickerId}
          disabled={disabled}
          width="95%"
          display="inline"
          color={isEmptyValue && '#bfbfbf'}
        >
          {getPeriodValue()}
        </SText>
        {allowClear && !isEmptyValue && isHoverButton && (
          <StyledIcon
            onClick={() => {
              if (checkboxMode === DATE_PICKER_MODES.clientInteraction) {
                return onChange({
                  clientInteractionTimeFrom: undefined,
                  clientInteractionTimeTo: undefined,
                  clientInteractionSymbolicTimeRange: undefined
                });
              }

              if (checkboxMode === DATE_PICKER_MODES.review) {
                return onChange({
                  reviewTimeFrom: undefined,
                  reviewTimeTo: undefined,
                  reviewSymbolicTimeRange: undefined
                });
              }

              // * реализация работы кастомных ключей
              return onChange({
                [DATE_PICKER_MODES_CUSTOM_KEYS[mode].timeFrom]: undefined,
                [DATE_PICKER_MODES_CUSTOM_KEYS[mode].timeTo]: undefined,
                [DATE_PICKER_MODES_CUSTOM_KEYS[mode].symbolicTimeRange]: undefined
              });
            }}
          />
        )}
      </StyledButton>
      {openSelect && (
        <SCard
          className="custom-dropdown"
          width="749px"
          borderRadius="8px"
          bodyPadding="0px"
          style={{
            position: isClientInteractionPage ? 'fixed' : 'absolute',
            top: isClientInteractionPage ? pickerPosition : top,
            left: datePickerPositions[position],
            zIndex: 5,
            filter: 'drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.08)',
            border: '1px solid #E6E8ED'
          }}
        >
          <SRow>
            <SCol paddingTop="16px" span={6} borderRight="1px solid #E6E8ED" paddingBottom="6px">
              <PeriodPicker
                value={symbolicPeriod}
                onChange={period =>
                  onChangePeriod({
                    period,
                    setTimePeriod,
                    setSymbolicPeriod,
                    timeFrom: timePeriod.timeFrom,
                    timeTo: timePeriod.timeTo
                  })
                }
              />
            </SCol>
            <SCol span={18} paddingTop="16px">
              <CalendarPicker
                timePeriod={timePeriod}
                setTimePeriod={setTimePeriod}
                symbolicPeriod={symbolicPeriod}
                setSymbolicPeriod={setSymbolicPeriod}
                checkboxMode={checkboxMode}
                setCheckboxMode={setCheckboxMode}
                showModeSwitch={mode === DATE_PICKER_MODES.default}
                onChangePeriod={onChangePeriod}
                page={page}
              />
            </SCol>
          </SRow>
          <SRow>
            <SCol style={{ borderTop: '1px solid #F1F1F1' }} span={24}>
              <SButton
                onClick={onClick}
                className="apply-btn"
                type="primary"
                width="calc(100% - 48px)"
                margin="16px 24px "
                padding="0"
              >
                <SText className="apply-btn" color="#FFFFFF">
                  {t('general.apply')}
                </SText>
              </SButton>
            </SCol>
          </SRow>
        </SCard>
      )}
    </SRow>
  );
};

export default CustomDatePicker;
