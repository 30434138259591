import api from 'core/api';
import { createReduxResource } from 'core/redux/resources';

export const integrationsResource = createReduxResource({
  name: 'integrations',
  customOperations: {
    updateCustomHttpTelephonyAuthorizationToken: {
      callEndpoint: ({ id }) => api.updateCustomHttpTelephonyAuthorizationToken({ id }, {}),
      onSuccess: ({ result: integration, dispatch }) => {
        dispatch({ type: 'integrations/updateByIdSucceed', payload: integration });
        return integration;
      }
    },
    updateCustomHttpTextAuthorizationToken: {
      callEndpoint: ({ id }) => api.updateCustomHttpTextAuthorizationToken({ id }, {}),
      onSuccess: ({ result: integration, dispatch }) => {
        dispatch({ type: 'integrations/updateByIdSucceed', payload: integration });
        return integration;
      }
    },
    getAvailableIntegrations: {
      callEndpoint: api.getAvailableIntegrations,
      onSuccess: ({ result }) => result.data
    }
  }
});
