export default {
  menu: {
    appMenu: {
      analytics: 'Analitica',
      reports: 'Relazione',
      dashboards: 'Cruscotto',
      dynamicsOfEvaluationsByUnits: 'Dinamica delle valutazioni per unità',
      communications: 'Elenco delle comunicazioni',
      tasks: 'Compiti',
      library: 'Libreria delle chiamate',
      addReview: 'Nuovo controllo',
      team: 'Squadra',
      kpi: 'KPI Analitica',
      settings: 'Utensili',
      checklistsSettings: 'Moduli di valutazione',
      tagsSettings: 'Tag',
      calibrations: 'Sessioni di calibrazione',
      workPlanTaskConfigurations: 'Distribuzione del carico',
      appeals: 'Appelli',
      autoFailScores: 'Dinamica negli errori critici'
    },
    userMenu: {
      profile: 'Area personale',
      profileSettings: 'Impostazioni del profilo',
      organizationSettings: "Impostazioni dell'organizzazione",
      organizationStructureSettings: 'Dipendenti e diritti di accesso',
      integrationsSettings: 'Integrazioni',
      logout: 'Uscita',
      organizationId: 'Codice organizzazione',
      copyOrganizationId: "Copia il codice dell'organizzazione",
      copyOrganizationIdSuccess: 'Codice organizzazione copiato',
      customFields: 'Campi personalizzati',
      aiSettings: 'Regolazioni'
    },
    helpMenu: {
      helpCenter: 'Centro assistenza',
      updates: 'Ultimi aggiornamenti',
      support: 'Contatta il supporto',
      suggestAnIdea: "Proporre un'idea",
      hotKeys: 'Еasti di scelta rapida'
    },
    alerts: {
      rolesAlert: {
        title:
          "I ruoli Amministratore e Operatore sono di base, senza possibilita' di eliminarli. Ulteriori informazioni su ruoli, diritti di accesso e relative impostazioni ",
        button: 'qui'
      }
    },
    workPlanTasks: 'Compiti'
  }
};
