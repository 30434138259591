export default {
  organizationStructure: {
    moved: 'Moverse',
    moveToUnit: 'Mover al departamento',
    employeesList: 'Lista de empleados agregados',
    addEmployee: 'Agregar empleado',
    tabs: {
      units: {
        title: 'Estructura de la compañía',
        modal: {
          title: 'Añadir un departamento',
          ok: 'Añadir',
          cancel: 'Cancelar',
          form: {
            name: 'Nombre del departamento',
            namePlaceholder: 'Introduzca el nombre del departamento',
            messages: { errors: { enterName: 'Por favor introduzca el nombre del departamento' } }
          }
        },
        confirmDelete: {
          title: '¿Eliminar el departamento?',
          ok: 'Eliminar',
          cancel: 'Cancelar'
        }
      },
      employees: {
        title: 'Empleados',
        table: {
          columns: {
            name: 'Empleado',
            unit: 'Departamento',
            managedUnits: 'Departamentos adicionales',
            unitsTooltip:
              'El departamento en el que se encuentra el usuario y al que quedan registradas sus comunicaciones. El usuario tiene acceso a los datos del departamento especificado, así como a los datos de los departamentos que están por debajo del departamento del usuario en términos de jerarquía (en la misma rama de la jerarquía), dentro de los derechos de acceso establecidos para el rol de usuario .',
            managedUnitsTooltip:
              'Acceso para ver y modificar datos sobre comunicaciones, cheques, usuarios, recursos, formularios de evaluación dentro de la configuración de derechos de acceso en la sección "Derechos de acceso y niveles de empleados"',
            role: 'Papel',
            level: 'Nivel',
            access: 'Acceso',
            status: 'Estado'
          },
          filters: {
            totalCountEmployees: 'Cantidad de usuarios:',
            employeesSearch: 'Búsqueda',
            unitsIdsPlaceholder: 'Elija en departamento',
            unitsIdsMaxPlaceholder: 'Departamentos:',
            rolesIdsPlaceholder: 'Elija eun papel',
            rolesIdsMaxPlaceholder: 'Papel:',
            statusPlaceholder: 'Elija un estado',
            invitationStatusPlaceholder: 'Elija el nivel de acceso'
          },
          buttons: { addEmployee: 'Agragar usuario' },
          menu: {
            items: {
              activate: 'Activar',
              deactivate: 'Desactivar',
              sendInvite: 'Enviar invitación',
              editEmployee: 'Redactar',
              employeeProfile: 'Pasar al perfil',
              deleteEmployee: 'Eliminar',
              deleteEmployeeStatus: 'Elimina'
            },
            messages: { invitationSended: 'Enviada la invitación' }
          }
        },
        modal: {
          title: 'Ajustes del usuario',
          buttons: {
            sendInvite: 'Enviar la invitación al sistema',
            cancel: 'Cancelar',
            save: 'Guardar'
          },
          messages: {
            employee: 'Empleado',
            created: 'agregado',
            updated: 'actualizado'
          },
          form: {
            fields: {
              lastName: 'Apellido',
              lastNamePlaceholder: 'Introduzca el apellido',
              firstName: 'Nombre',
              firstNamePlaceholder: 'Introduzca el nombre',
              email: 'Correo electrónico',
              emailPlaceHolder: 'Introduzca el correo electrónico',
              integrationId: 'Id en el sistema a integrar',
              integrationIdPlaceholder: 'Introduzca el Id en el sistema a integrar',
              role: 'Papel',
              rolePlaceholder: 'Elija un papel',
              unit: 'Departamento',
              unitPlaceHolder: 'Elija un departamento',
              managedUnits: 'Departamentos adicionales',
              managedUnitsPlaceholder: 'Seleccionar departamentos adicionales'
            },
            messages: {
              enterLastName: 'Por favor introduzca su apellido',
              enterFirstName: 'Por favor introduzca su nombre',
              enterEmail: 'Por favor introduzca su correo electrónico',
              enterValidEmail: 'El correo electrónbico no es correcto',
              enterRole: 'Por favor elija un papel',
              enterUnit: 'Por favor elija un departamento',
              onlyRootUnitForAdmin:
                'El administrador solo se puede asignar al departamento principal',
              allUnitsAreVisible:
                'El usuario ya tiene acceso a todos los departamentos de la organización'
            }
          }
        },
        lastAuthorization: 'Última autorización',
        accessLevels: {
          notInvited: 'No se ha enviado la invitación',
          invited: 'La invitación se ha enviado',
          loggedIn: 'Ha accedido',
          noAccess: 'No hay acceso',
          buttons: {
            setLevel: 'Establecer el nivel',
            resetLevel: 'Reiniciar el nivel'
          }
        },
        limitCasesModal: {
          usersCountLimit: {
            text: 'Límite de usuarios excedido',
            description:
              'No puede agregar más de 1500 usuarios a una organización. Consulte los términos de licencia de su organización o comuníquese con nuestro soporte.'
          },
          licenceCountLimit: {
            text: 'Límite de usuarios activos excedido',
            description_start:
              'El número de usuarios activos no puede exceder el número de licencias adquiridas. Número de licencias adquiridas para su organización -',
            description_end:
              'Verifique los términos de su licencia o comuníquese con nuestro soporte.'
          },
          closeButton: 'Soporte de contacto'
        }
      },
      roles: {
        title: 'Papeles y derechos de acceso',
        item: {
          buttons: {
            setting: 'Ajustar',
            delete: 'Eliminar',
            newRole: 'Nuevo papel',
            addRole: 'Añadir papel'
          },
          confirmDelete: '¿Eliminar el papel?',
          header: 'Nombre del papel'
        },
        modal: {
          title: 'Ajuste del derecho de acceso',
          buttons: {
            update: 'Actualizar',
            save: 'Guardar',
            cancel: 'Cancelar'
          },
          messages: {
            roleSuccessfullyUpdated: 'El papel se ha actualizado correctamente',
            updateRoleFailed: 'No se logró actualizar el papel',
            saveRoleFailed: 'No se logró guardar el papel',
            roleSuccessfullySaved: 'El papel ha sido creado'
          },
          form: {
            nameCategory: 'Nombre del papel',
            generalCategory: 'General',
            profileCategory: 'Perfil personal',
            analyticsCategory: 'Analítica, evaluación y retroalimentación',
            tasksCategory: 'Tareas',
            libraryCategory: 'Biblioteca de llamadas',
            administrateCategory: 'Administrar'
          }
        }
      },
      levels: {
        title: 'Niveles de empleados',
        item: {
          buttons: { setting: 'Ajustar los niveles' },
          header: 'Nombre del papel y del nivel'
        },
        modal: {
          title: 'Ajuste del nivel de los papeles',
          form: { namePlaceholder: 'Introduzca el nombre del nivel' },
          buttons: {
            save: 'Guardar',
            cancel: 'Cancelar',
            addLevel: 'Añadir nivel'
          },
          messages: {
            hasEmptyNames: 'Los nombres de los niveles no pueden estar vacios',
            hasSameNames: 'No puede guardar niveles con cel mismo nombre'
          }
        }
      },
      statuses: {
        title: 'Estado del contacto',
        form: {
          namePlaceholder: 'Introduzca el nombre del estado',
          empty: 'No se han creado estados',
          addStatus: 'Añadir estado',
          save: 'Guardar'
        }
      },
      notifications: {
        title: 'Notificaciones',
        table: {
          title: 'Ajuste de las notificiaciones para los papeles:',
          rolePlaceholder: 'Elija el papel',
          columns: {
            eventType: 'Tema de la notificación',
            emailNotification: 'Notificación por correo electrónico'
          },
          buttons: { setting: 'Ajustar' }
        },
        modal: {
          title: 'Ajeste de notificaciones:',
          isSendEmail: {
            title: '¿Enviar la notificación al correo electrónico?',
            true: 'Si',
            false: 'No'
          }
        }
      },
      connections: {
        title: 'Conexiones',
        table: {
          userHasNoIntegrations: 'Usuario no agregado a integraciones',
          deleteFrom: 'Eliminar de',
          users: 'Usuarios'
        },
        userAccounts: {
          userConnectedToIntegration: 'El usuario está conectado a la integración.',
          selectUserAccount: 'Seleccionar una cuenta',
          downloadingAccounts: 'Cargando cuentas',
          integrationHasNoAccounts: 'Esta integración no tiene cuentas para conectarse'
        }
      }
    },
    menu: {
      add: 'Añadir un departamento',
      rename: 'Rebautizar',
      delete: 'Eliminar'
    }
  }
};
