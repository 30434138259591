export default {
  uploadCallsPage: {
    messages: {
      successfullyLoaded: 'успешно загружен',
      loadFailed: 'Не удалось загрузить файл',
      maxFileText: 'Размер файла',
      maxFileSize: 'превышает 30 МБ.',
      fileFormatText: 'Некорректное расширение файла',
      fileFormatMessage: 'разрешены только mp3, wav или csv файлы.',
      fileFormat:
        'Вы можете загрузить файлы в формате mp3, wav или csv, размером до 30 мегабайт/файл',
      deleteSuccess: 'Успешно удалено',
      editSuccess: 'Изменения сохранены',
      saveEditingTooltip: 'Для сохранения необходимо заполнить обязательные поля'
    },
    buttons: {
      loadCalls: 'Загрузить звонки',
      downloadExample: 'Пример CSV',
      delete: 'Удалить',
      save: 'Сохранить',
      cancelEditing: 'Отменить изменения'
    },
    table: {
      columns: {
        operator: 'Оператор',
        direction: 'Тип звонка',
        startedAt: 'Дата звонка',
        startedAtPlaceholder: 'Выберите время',
        clientPhoneNumber: 'Номер телефона',
        duration: 'Длина звонка',
        actions: 'Действия',
        communicationType: 'Звонок',
        fileSize: 'Размер',
        directionTitle: 'Направление',
        selectOperator: 'Выберите оператора'
      },
      uinstalledCallDirection: 'Не установлен',
      confirmChange: {
        title: 'Изменения не сохранятся',
        ok: 'Продолжить',
        cancel: 'Отмена'
      },
      buttons: {
        cancel: 'Отмена',
        edit: 'Редактировать',
        delete: 'Удалить'
      },
      confirmDelete: {
        title: 'Уверены?',
        content: 'Отменить данное действие будет невозможно',
        ok: 'Удалить',
        cancel: 'Отмена'
      },
      cancelEditing: {
        title: 'Редактирование',
        content: 'Вы уверены что хотите отменить изменения?',
        ok: 'ОК',
        cancel: 'Отмена'
      }
    }
  }
};
