import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, message } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { createDatasetEndpoint, updateDatasetEndpoint } from 'core/api';
import { clearFields } from 'redux/ui/DatasetModal/reducer';
import { get, isEmpty } from 'lodash';
import SModal from 'components/Standard/SModal';
import { SInput } from './styled';
import Uploader from './Uploader';

const defaultDataset = {};
const filesToDelete = [];

const ModalDataset = ({ isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [filesList, setFilesList] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [updateStarted, setUpdateStarted] = useState(false);
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);
  const { createStarted } = useSelector(state => state.datasetsResource);
  const { loading: datasetsResourceLoading, byIds } = useSelector(
    state => state.datasetsResource,
    shallowEqual
  );
  const { loading: datasetsLoading } = useSelector(state => state.datasetsList);
  const loading = datasetsResourceLoading || datasetsLoading || createStarted || fileLoading;
  const { dataset = defaultDataset } = useSelector(state => state.uiDatasetModal, shallowEqual);
  const datasetsFileListByIds = useSelector(
    state => state.datasetsFileListResource.byIds,
    shallowEqual
  );
  const datasetsBindingIds = useSelector(state => state.datasetsBinding.ids, shallowEqual);
  const datasetsBindingResourceByIds = useSelector(
    state => state.datasetsBindingResource.byIds,
    shallowEqual
  );
  const datasetsBindingsByIds = Object.values(datasetsBindingResourceByIds).filter(item =>
    datasetsBindingIds.includes(item.id)
  );
  const isNewDataset = isEmpty(get(byIds, dataset.id, false));

  const closeModal = useCallback(() => {
    setFilesList([]);
    form.resetFields();
    dispatch(clearFields({ dataset: defaultDataset }));
    setFileLoading(false);
    setIsModalOpen(false);
    filesToDelete.length = 0;
    setUpdateStarted(false);
  }, [dispatch]);

  const handleSubmit = async data => {
    if (filesList.length === 0) {
      form.setFieldsValue({ file: null });
      message.info('Пожалуйста, загрузите файл');
      return;
    }

    const formData = new FormData();
    const { name, openaiId } = data;
    let fileIds = [];

    if (isNewDataset) {
      formData.append('name', name.trim());
      formData.append('organization_id', organizationId);
      formData.append('user_id', currentUserId);
      formData.append('is_active', true);
    } else {
      if (!updateStarted) {
        closeModal();
        return;
      }

      const openaiIds = filesToDelete.map(item => item.openaiId);

      fileIds = filesList
        .filter(obj => obj.openaiId !== undefined && !openaiIds.some(id => id === obj.openaiId))
        .map(obj => obj.openaiId);

      const data = {
        assistant_id: openaiId,
        file_ids: fileIds
      };
      formData.append('data', JSON.stringify(data));
    }

    for (let i = 1; i <= filesList.length; i++) {
      const currentFile = filesList[i - 1];

      const extension = currentFile.name
        .split('.')
        .pop()
        .toLowerCase();

      // Проверяем расширение файлов
      if (extension === 'pdf' || extension === 'txt') {
        // if (currentFile.hasOwnProperty('openaiId')) {
        // Исключаем файлы которые которые уже загружены в openai
        if ('openaiId' in currentFile) {
          continue;
        }
        formData.append(`file${i}`, currentFile);
      }
    }

    try {
      setFileLoading(true);
      const response = await axios.post(
        isNewDataset ? createDatasetEndpoint : updateDatasetEndpoint,
        formData,
        {
          'content-type': 'multipart/form-data'
        }
      );
      const key = isNewDataset ? response.data.attributes.key : response.data.key;
      localStorage.setItem('delayedKey', key);
      isNewDataset
        ? message.info(
            `${t('components.datasetsModal.title')} '${name}' ${t(
              'components.datasetsModal.messages.successCreate'
            )}`,
            6
          )
        : message.info(
            `${t('components.datasetsModal.title')} '${name}' ${t(
              'components.datasetsModal.messages.successUpdate'
            )}`,
            6
          );
      closeModal();
    } catch (error) {
      setFileLoading(false);
      console.log('error', error);
      message.error(t('errorPages.internalServerError.title'));
    }
  };

  const handleRemove = removedFile => {
    if (!isNewDataset) {
      setUpdateStarted(true);
      filesToDelete.push(removedFile);
    }
    const index = filesList.indexOf(removedFile);
    const newFileList = filesList.slice();
    newFileList.splice(index, 1);
    setFilesList(newFileList);
    message.info(
      `${t('components.datasetsModal.messages.fileText')} '${removedFile.name}' ${t(
        'components.datasetsModal.messages.removedLoad'
      )}`
    );
  };

  const { id = '', name = '', openaiId = '' } = dataset;

  const datasetBinding = Object.values(datasetsBindingsByIds).filter(
    binding => binding.assistantId === openaiId
  );

  const datasetFiles = Object.values(datasetsFileListByIds).filter(file => {
    return Object.values(datasetBinding).some(binding => file.openaiId === binding.fileId);
  }, shallowEqual);

  const initialValues = {
    id,
    openaiId,
    name,
    file: datasetFiles
  };

  useEffect(() => {
    if (dataset) {
      form.setFieldsValue(initialValues);
      setFilesList(datasetFiles);
    }
  }, [dataset]);

  return (
    <SModal
      title={
        isNewDataset
          ? t('components.datasetsModal.createTitle')
          : t('components.datasetsModal.editTitle')
      }
      centered
      visible={isModalOpen}
      onCancel={() => {
        if (fileLoading) {
          message.info(t('components.datasetsModal.messages.uploading'));
          return;
        }
        closeModal();
      }}
      size="big"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            if (fileLoading) {
              message.info(t('components.datasetsModal.messages.uploading'));
              return;
            }
            closeModal();
          }}
        >
          {t('components.datasetsModal.buttons.cancel')}
        </Button>,
        <Button key="save" type="primary" onClick={form.submit} loading={loading}>
          {isNewDataset
            ? t('components.datasetsModal.buttons.save')
            : t('components.datasetsModal.buttons.update')}
        </Button>
      ]}
    >
      <Form
        form={form}
        name="moveUserToUnit"
        initialValues={initialValues}
        scrollToFirstError
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item name="openaiId" style={{ display: 'none' }} />
        <Form.Item
          label={t('components.datasetsModal.form.fields.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('datasetAiPage.messages.enterName')
            },
            {
              pattern: /^(?!.*\s{2})\S.*$/,
              message: t('datasetAiPage.messages.tooManySpaces')
            },
            {
              max: 100,
              message: t('datasetAiPage.messages.nameTooLong')
            }
          ]}
        >
          <SInput
            placeholder={t('components.datasetsModal.form.placeholder.name')}
            disabled={!isNewDataset}
          />
        </Form.Item>
        <Form.Item
          name="file"
          rules={[
            {
              required: true,
              message: t('datasetAiPage.messages.loadFile'),
              validator: rule => {
                if (!filesList || filesList.length === 0) {
                  return Promise.reject(rule.message);
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Uploader
            filesList={filesList}
            setFilesList={setFilesList}
            handleRemove={handleRemove}
            setUpdateStarted={setUpdateStarted}
            datasetFiles={datasetFiles}
          />
        </Form.Item>
      </Form>
    </SModal>
  );
};

export default ModalDataset;
