import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const referenceCompare = (a, b) => a === b;

export const useTraceUpdate = props => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (!isEqual(prev.current[k], v)) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
};

export const useIsQiwiDomain = () => {
  const [isQiwiUrl, setIsQiwiUrl] = useState(false);
  useEffect(() => {
    setIsQiwiUrl(window.location.hostname.includes('dealapp.qiwi.com'));
  });

  return isQiwiUrl;
};
