import { INTEGRATIONS } from 'core/utils/constants';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import api from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import { saveBackgroundJob } from '../backgroundJob/operations';

export const createBinotelIntegration = ({ name, clientId, clientSecret }) => async dispatch => {
  try {
    const integration = await dispatch(
      integrationsResource.operations.create({
        name,
        clientId,
        clientSecret,
        endpoint: 'https://api.binotel.com/api/4.0',
        integrationType: INTEGRATIONS.binotel.type
      })
    );
    dispatch(saveBackgroundJob(integration?.currentBackgroundJob));

    return integration?.id;
  } catch (error) {}
};

export const syncDataWithBinotel = ({ id, fetchFrom }) => async dispatch => {
  const response = await api.binotelSyncDataByPeriod(
    { id },
    decamelizeKeys({
      fetchFrom
    })
  );

  const backgroundJob = processJsonApiObject(response.body.data);

  return dispatch(saveBackgroundJob(backgroundJob));
};
