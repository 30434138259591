import { Button, Col, Input, Row, Select, Upload } from 'antd';
import styled from 'styled-components';

const { Dragger } = Upload;

export const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormContainer = styled(Col)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-card {
      width: 80%;
      max-width: 560px;
    }
  }
`;

export const DeleteButton = styled(Button)`
  && {
    background: none;
    border: none;
    color: black;
    box-shadow: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`;

export const StyledCheckedInfo = styled.div`
  text-align: center;
  position: absolute;
  background: #f0f2f5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  gap: 8px;
  display: flex;
  padding: 0 4px;
`;

export const SCol = styled(Col)`
  width: 100%;
`;

export const SRow = styled(Row)`
  margin: 0 0 20px 0;
`;

export const SSelect = styled(Select)`
  width: 100%;
  margin: 5px 0 0 0 !important;
`;

export const SInput = styled(Input)`
  margin: 5px 0 0 0 !important;
`;

export const SDragger = styled(Dragger)`
  && {
    margin-bottom: 10px !important;

    & .ant-upload-list {
      margin-bottom: 10px !important;
    }
  }
`;
