import api from 'core/api';

export const manuallyStartTranscriptionTranscript = async (
  communicationIds,
  currentUserAttributes
) => {
  try {
    const organization_id = currentUserAttributes?.user['organization-id'];
    const requestBody = {
      ids: [communicationIds],
      organization_id
    };
    return await api.manuallyStartTranscriptionTranscript(requestBody);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const manuallyStartTranscriptionCheck = async (communicationIds, currentUserAttributes) => {
  try {
    const organization_id = currentUserAttributes?.user['organization-id'];
    const response = await api.manuallyStartTranscriptionCheck({
      id: communicationIds,
      organization_id
    });
    switch (response.body.data.status) {
      case 'not_transcripted': {
        return false;
      }
      case 'transcripted': {
        return true;
      }
      default:
        return true;
    }
  } catch (error) {
    console.log(error);
    return true;
  }
};
