import React from 'react';
import { Col, Tooltip } from 'antd';
import { FieldTimeOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateDashboardWidget } from '../../redux/ui/widgetSettingsModal/operations';

const WidgetViewLastUpdate = ({ widgetData, dateLastUpdate }) => {
  const dispatch = useDispatch();
  const formattedDate = moment(dateLastUpdate || Date.now()).format('DD.MM.YYYY HH:mm:ss');
  const onRefresh = () => {
    dispatch(
      updateDashboardWidget({
        id: widgetData.id,
        type: widgetData.type,
        filters: widgetData.filters
      })
    );
  };
  return (
    <Col style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip placement="top" title={`Дата последнего обновления\n${formattedDate}`}>
        <FieldTimeOutlined style={{ fontSize: '16px' }} />
      </Tooltip>
      <Tooltip placement="topRight" title="Обновить">
        <ReloadOutlined onClick={onRefresh} style={{ marginLeft: '4px' }} />
      </Tooltip>
    </Col>
  );
};

export default WidgetViewLastUpdate;
