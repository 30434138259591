import React from 'react';
import { Modal } from 'antd';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { WarningFilled } from '@ant-design/icons';
import { SButton } from 'components/Standard';
import { LIMIT_MODAL_TEXT, LIMIT_MODAL_TYPES } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { freshchatWidgetToken } from 'core/config';

const LimitsModal = ({ limitType, setLimitType, countLimit = 0 }) => {
  const { t } = useTranslation();

  const onClick = () => {
    setLimitType(null);
    if (!isEmpty(freshchatWidgetToken) && window?.fcWidget) {
      !window.fcWidget.isOpen() && window.fcWidget.open();
    }
  };

  return (
    <Modal
      visible={limitType}
      onCancel={() => setLimitType(null)}
      width={612}
      footer={null}
      centered
      className="limit-modal"
    >
      <SRow marginTop="9px">
        <SCol span={24} display="flex" justifyContent="center">
          <WarningFilled color="#FFC53D" style={{ fontSize: 70, color: '#FFC53D' }} />
        </SCol>
      </SRow>
      <SRow marginTop="33px">
        <SCol span={24} display="flex" justifyContent="center" style={{ textAlign: 'center' }}>
          <SText fontSize="20px" fontWeight="600">
            {t(LIMIT_MODAL_TEXT[limitType]?.text)}
          </SText>
        </SCol>
      </SRow>
      <SRow marginTop="12px">
        <SCol span={24} display="flex" justifyContent="center" style={{ textAlign: 'center' }}>
          {limitType === LIMIT_MODAL_TYPES.usersCountLimit ? (
            <SText type="secondary">{t(LIMIT_MODAL_TEXT[limitType]?.description)}</SText>
          ) : (
            <SText type="secondary">
              {`${t(LIMIT_MODAL_TEXT[limitType]?.description_start)} ${countLimit}. ${t(
                LIMIT_MODAL_TEXT[limitType]?.description_end
              )}`}
            </SText>
          )}
        </SCol>
      </SRow>
      <SRow marginTop="24px">
        <SCol span={24} display="flex" justifyContent="center" style={{ textAlign: 'center' }}>
          <SButton onClick={onClick} type="primary">
            {t('organizationStructure.tabs.employees.limitCasesModal.closeButton')}
          </SButton>
        </SCol>
      </SRow>
    </Modal>
  );
};

export default LimitsModal;
