import { generateAuthActions } from 'redux-token-auth';
import { apiUrl } from 'core/config';
import { get } from 'lodash';

const config = {
	authUrl: `${apiUrl}/auth`,
	userAttributes: {
		id: 'id',
		user: 'attributes'
	},
	storage: {
		flushGetRequests: false
	}
};

const { registerUser, signInUser, signOutUser, verifyCredentials } = generateAuthActions(config);

export { registerUser, signInUser, signOutUser, verifyCredentials };
