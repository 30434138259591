import { createReduxResource } from 'core/redux/resources';

export const phoneCallsResourceV3 = createReduxResource({
  name: 'phoneCallsV3',
  reactors: {
    onLoadByIdSucceed: ({ resource, dispatch }) => {
      dispatch({
        type: `clientInteractions/loadByIdSucceed`,
        payload: { ...resource, type: 'client-interactions' }
      });
    }
  }
});
