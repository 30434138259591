import axios from 'axios';
import { exportToExcelDashboardsEndpoint } from 'core/api';
import _ from 'lodash';

export const exportDashboardsPages = async ({ exportWidgetDataArray }) => {
  try {
    const result = await axios.post(
      exportToExcelDashboardsEndpoint,
      _.uniqBy(exportWidgetDataArray, 'id'),
      {
        headers: {
          uid: localStorage.getItem('uid')
        }
      }
    );

    return result?.data?.requestId;
  } catch (error) {
    console.log(error);
  }
};
