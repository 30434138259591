import { Col, Row, Typography } from 'antd';
import { SText } from 'components/Standard';
import moment from 'moment';

import React from 'react';
import styled from 'styled-components';
import PartContent from './PartContent';

import './style.css';

const EventConversationPart = ({ body, contentType, postedAt }) => {
  return (
    <Row type="flex" align="start" justify="start" gutter={[8, 8]} style={{ margin: '-4px' }}>
      <AvatarCol>
        <Row type="flex" align="middle" justify="center">
          <Col>{/* <Avatar size="default" icon={<UserOutlined />} theme="filled" /> */}</Col>
        </Row>
      </AvatarCol>

      <TextContainer align="left">
        <PartContent contentType={contentType}>{body}</PartContent>
        <SText marginLeft="4px" type="secondary">
          {moment(postedAt).format('DD/MM/YYYY, HH:mm:ss')}
        </SText>
      </TextContainer>
    </Row>
  );
};

const TextContainer = styled(Col)`
  &.ant-col {
    max-width: 85%;
    text-align: ${props => (props.align ? props.align : 'left')};
  }

  &.ant-col p {
    margin-bottom: 0 !important;
  }
`;

const AvatarCol = styled(Col)`
  &.ant-col {
    width: 50px;
  }
`;

export default EventConversationPart;
