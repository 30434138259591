import api from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import { INTEGRATIONS } from 'core/utils/constants';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import { saveBackgroundJob } from '../backgroundJob/operations';

export const createMangoIntegration = ({ name, clientSecret, clientId }) => async dispatch => {
    try {
        const integration = await dispatch(
            integrationsResource.operations.create({
            name,
            clientSecret,
            clientId,
            endpoint: 'https://app.mango-office.ru/vpbx/',
            integrationType: INTEGRATIONS.mango.type
            })
        );
        dispatch(saveBackgroundJob(integration?.currentBackgroundJob));

        return integration?.id;
    } catch (error) {
    console.log(error);
    }
};

export const syncDataWithMango = ({ id, fetchFrom }) => async dispatch => {
    const response = await api.mangoSyncDataByPeriod(
    { id },
    decamelizeKeys({
        fetchFrom
    })
    );

    const backgroundJob = processJsonApiObject(response.body.data);

    return dispatch(saveBackgroundJob(backgroundJob));
};