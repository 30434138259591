import createListReducer from 'core/redux/lists/listReducer';
import { promptsQuestionsAllResource } from 'redux/resources/promptsQuestionsAll';

export const listParams = {
  name: 'promptsQuestionsAllList',
  loader: promptsQuestionsAllResource.operations.load,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer(listParams);

const promptsQuestionsAllListReducer = reducer;

export { actions, promptsQuestionsAllListReducer, operations };
