import React from 'react';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { get, isEmpty, isEqual, orderBy } from 'lodash';
import { FLAGS_COLORS_LITERALS_RUS, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import { ResponsiveBar } from '@nivo/bar';
import truncateString from 'core/utils/truncateString';
import { getUserName } from 'components/UserPreview/getUserName';
import { useTranslation } from 'react-i18next';
import SMLWidgetHeader from 'pages/DashboardPage/Components/SMLWidgetHeader';
import { tooltipSetter } from '../../utils';
import { WidgetCard } from '../../styled';
import EmptyWidget from '../../Components/EmptyWidget';

const RatingFlagsCountByOperators = ({ widgetData, loadingWidgetId, isWarningWidget }) => {
  const { t } = useTranslation();

  const widgetsAnalyticsByIds = useSelector(state => state.dashboardAnalytics.byIds, isEqual);
  const usersByIds = useSelector(state => state.usersResource.byIds, isEqual);

  const { id, type, filters, name } = widgetData;

  const widgetsAnalyticsByIdsArray = Array.isArray(get(widgetsAnalyticsByIds, widgetData.id, []))
    ? widgetsAnalyticsByIds
    : Object.values(get(widgetsAnalyticsByIds, widgetData.id, []));

  const widgetValue =
    loadingWidgetId === id
      ? []
      : orderBy(
          get(widgetsAnalyticsByIdsArray, widgetData.id, []).map(item => ({
            ...item,
            name: getUserName({ user: usersByIds[item.id] }),
            gray: item.without_flag
          })),
          'name',
          'desc'
        );
  let lastItem = 0;
  let indexItem = 0;
  widgetValue.map((item, index) => {
    if (item.dateLastUpdate) {
      lastItem = item;
      indexItem = index;
    }
  });
  const dateLastUpdate = lastItem ? lastItem.dateLastUpdate : null;
  if (Array.isArray(widgetValue) && lastItem) {
    widgetValue.splice(indexItem, 1);
  }
  return (
    <>
      {isEmpty(widgetValue) || loadingWidgetId === id || isWarningWidget ? (
        <EmptyWidget
          key={id}
          widgetData={widgetData}
          loadingWidgetId={loadingWidgetId}
          isWarningWidget={isWarningWidget}
        />
      ) : (
        <Col span={6} key={id}>
          <WidgetCard>
            <SMLWidgetHeader
              name={name}
              type={type}
              widgetData={widgetData}
              filters={filters}
              dateLastUpdate={dateLastUpdate}
            />

            <ResponsiveBar
              data={widgetValue}
              keys={['without_flag', 'green', 'yellow', 'red']}
              indexBy="name"
              margin={{ top: 0, right: 10, bottom: 70, left: 120 }}
              padding={0.3}
              layout="horizontal"
              colors={bar => FLAGS_TO_COLORS_RELATIONS[bar.id]}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={null}
              axisRight={null}
              tooltip={tooltip =>
                tooltipSetter({
                  name: `${t(FLAGS_COLORS_LITERALS_RUS[tooltip.id])} ${tooltip.data.name}`,
                  value: tooltip.value
                })
              }
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: d => (d.toString().match(/\./) ? '' : d)
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                format: string => truncateString(string, 16)
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              animate
              motionStiffness={90}
              motionDamping={15}
            />
          </WidgetCard>
        </Col>
      )}
    </>
  );
};

export default RatingFlagsCountByOperators;
