import styled from 'styled-components';
import SButton from 'components/Standard/SButton';

export const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
  &.ant-btn:active:not(:hover) {
    background-color: transparent !important;
  }
  &.ant-btn:focus:not(:hover) {
    background-color: transparent !important;
  }
`;