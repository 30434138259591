import { INTEGRATIONS } from 'core/utils/constants';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import api from 'core/api';
import { processJsonApiCollection, processJsonApiObject } from 'core/jsonapi';
import loadIncludedObjectsToRedux from 'core/redux/resources/loadIncludedObjects';
import { saveBackgroundJob } from '../backgroundJob/operations';

export const createHelpDeskEddyIntegration = ({
  name,
  endpoint,
  clientSecret
}) => async dispatch => {
  try {
    const integration = await dispatch(
      integrationsResource.operations.create({
        name,
        endpoint,
        clientSecret,
        integrationType: INTEGRATIONS.help_desk_eddy.type
      })
    );
    dispatch(saveBackgroundJob(integration?.currentBackgroundJob));

    return integration?.id;
  } catch (error) {
    console.log(error);
  }
};

export const syncDataWithHelpDeskEddy = ({ id, fetchFrom }) => async dispatch => {
  const response = await api.helpDeskEddySyncDataByPeriod(
    { id },
    decamelizeKeys({
      fetchFrom
    })
  );

  const backgroundJob = processJsonApiObject(response.body.data);

  return dispatch(saveBackgroundJob(backgroundJob));
};

export const getHelpDeskEddyTicketFields = ({ id }) => async dispatch => {
  const response = await api.helpDeskEddyGetTicketFields({ id });
  const resources = processJsonApiCollection(response.body.data);
  loadIncludedObjectsToRedux({ objects: response.body.included, dispatch });
  return resources;
};

export const connectHelpDeskEddyTicketFieldById = ({ id, ticketFieldId }) => async () => {
  try {
    await api.helpDeskEddyConnectTicketField({ id, ticketFieldId }, {});
    return true;
  } catch (e) {
    return false;
  }
};

export const disconnectHelpDeskEddyTicketFieldById = ({ id, ticketFieldId }) => async () => {
  try {
    await api.helpDeskEddyDisconnectTicketField({ id, ticketFieldId }, {});
    return true;
  } catch (e) {
    return false;
  }
};
