import { transform } from 'lodash';
import { getUserName } from 'components/UserPreview/getUserName';
import { beatifyFloat } from 'core/utils/convertFloat';

const preparePeriodKeys = ({ widgetValue }) => {
  const emptyKeys = transform(
    widgetValue,
    (acc, value, key) => {
      const { history } = value;
      Object.assign(acc, history);
    },
    {}
  );
  return Object.keys(emptyKeys).sort((a, b) => new Date(a) - new Date(b));
};

export const prepareData = ({ widgetValue, usersByIds }) => {
  return Object.keys(widgetValue).map(key => ({
    id: getUserName({ user: usersByIds[widgetValue[key]?.id] }),
    data: preparePeriodKeys({ widgetValue }).map(historyData => ({
      x: historyData,
      y: beatifyFloat(widgetValue[key]?.history[historyData])
    }))
  }));
};
