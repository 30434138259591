import { message, Spin } from 'antd';
import QuestionModal from 'components/Questions/QuestionModal';
import { basePosition, getNewPosition, moveItemInArray } from 'core/utils/dnd';
import objectsDifference from 'core/utils/objectsDifference';
import { findIndex, get, isEmpty, isEqual, last, omit, sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { questionGroupsResource } from 'redux/resources/questionGroups';
import { questionsResource } from 'redux/resources/questions';
import { questionToGroupBindingsResource } from 'redux/resources/questionToGroupBindings';
import {
  getChecklistDefinitionQuestionGroups,
  getChecklistDefinitionQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import {
  setSortedQuestionGroups,
  updateMultipleSortedQuestionGroupBindings,
  updateSortedQuestionGroupBindings
} from 'redux/ui/checklistEditor/reducer';
import { setEditingQuestion } from 'redux/ui/questionModal/reducer';
import AddQuestionModal from './AddQuestionModal';
import EditQuestionGroupModal from './EditQuestionGroupModal';
import QuestionGroup from './QuestionGroup';
import QuestionsFromLibraryModal from './QuestionsFromLibraryModal';
import { promptsResource } from '../../../redux/resources/prompts';
import {
  actions as promptActions,
  operations as promptsListOperations
} from '../../../redux/lists/promptsList';
import { operations as promptsQuestionAllListOperations } from '../../../redux/lists/promptsQuestionsAllList';

const ChecklistItems = ({
  currentChecklist,
  currentChecklistQuestionGroups,
  questionGroupsByIds,
  questionsByIds,
  bindingsByIds,
  deleteBinding,
  createBinding,
  updateBinding,
  updateQuestionGroup,
  createQuestion,
  updateQuestion,
  loading,
  setSortedQuestionGroups,
  sortedQuestionGroups,
  sortedQuestionGroupBindings,
  updateSortedQuestionGroupBindings,
  updateMultipleSortedQuestionGroupBindings,
  setEditingQuestion,
  loadingChecklist,
  createPrompt,
  updatePrompt,
  updateQuestionsAvailability,
  loadPrompts,
  onPromptCreate
}) => {
  const questionGroupsBottom = get(last(sortedQuestionGroups), 'position', basePosition);
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading) {
      setSortedQuestionGroups(sortBy(currentChecklistQuestionGroups, 'position'));
    }
  }, [loading, currentChecklistQuestionGroups.length]);

  const reorderGroups = async ({ draggableId, destination, source }) => {
    const oldQuestionGroup = { ...questionGroupsByIds[draggableId] };

    const simulated = moveItemInArray(sortedQuestionGroups, source.index, destination.index);

    // * consider moving to dnd utils
    const top = get(simulated[destination.index - 1], 'position', 0);

    const bottom = get(simulated[destination.index + 1], 'position', questionGroupsBottom);

    const newPosition = getNewPosition({
      top,
      bottom,
      destinationIndex: destination.index,
      listBottom: questionGroupsBottom
    });

    const newQuestionGroup = { ...oldQuestionGroup, position: newPosition };

    setSortedQuestionGroups(simulated);

    await updateQuestionGroup(newQuestionGroup);
  };

  const reorderQuestions = async ({ draggableId, destination, source }) => {
    const destinationGroupId = destination.droppableId;
    const isDifferentGroup = destination.droppableId !== source.droppableId;

    const oldBinding = get(bindingsByIds, draggableId, {});

    const questionGroupBindings = sortedQuestionGroupBindings[destinationGroupId] || [];

    const simulated = moveItemInArray(questionGroupBindings, source.index, destination.index);

    const listBottom = get(
      last(questionGroupBindings),
      'position',
      isDifferentGroup ? basePosition : 0
    );
    const top = get(simulated[destination.index - 1], 'position', 0);
    const bottom = get(simulated[destination.index + 1], 'position', listBottom);

    const newPosition = getNewPosition({
      top,
      bottom,
      listBottom,
      destinationIndex: destination.index
    });

    const newBinding = {
      ...oldBinding,
      position: newPosition
    };

    const newBindings = [...simulated];
    newBindings.splice(findIndex(simulated, { id: oldBinding.id }), 1, newBinding);

    if (isDifferentGroup) {
      const newBinding = {
        ...oldBinding,
        // id: uniqueId(),
        questionGroupId: destinationGroupId,
        position: newPosition
      };

      const oldBindings = get(sortedQuestionGroupBindings, source.droppableId, []);

      updateMultipleSortedQuestionGroupBindings({
        [destinationGroupId]: sortBy([...questionGroupBindings, newBinding], 'position'),
        [source.droppableId]: get(sortedQuestionGroupBindings, source.droppableId, []).filter(
          ({ id }) => id !== oldBinding?.id
        )
      });

      return Promise.all([createBinding(newBinding), deleteBinding(oldBinding)]);
    }

    updateSortedQuestionGroupBindings({
      questionGroupId: destinationGroupId,
      bindings: newBindings
    });

    return updateBinding(newBinding);
  };

  const onDragEnd = async ({ draggableId, destination, source, type }) => {
    // dropped nowhere
    if (!destination) {
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    type === 'container'
      ? await reorderGroups({ draggableId, destination, source })
      : await reorderQuestions({ draggableId, destination, source });
  };

  const createNewPrompt = async question => {
    const promptName = question.promptName ? question.promptName : question.name;
    const { promptValue } = question;

    return createPrompt({
      operator_id: currentUserId,
      organization_id: organizationId,
      name: promptName,
      value: promptValue,
      binding_type: 'question'
    });
  };

  const updatePromptWithQuestions = async (
    promptId,
    addQuestions = [],
    deleteQuestions = [],
    promptValue
  ) => {
    const actions = {};

    if (addQuestions.length > 0) {
      actions.add_questions = addQuestions;
    }

    if (deleteQuestions.length > 0) {
      actions.delete_questions = deleteQuestions;
    }
    if (promptValue) {
      actions.update_value = promptValue;
    }
    await updatePrompt({
      id: promptId,
      actions
    });

    await updateQuestionsAvailability({
      organization_id: organizationId,
      type: 'questions',
      availability: 'all_availability'
    });

    await loadPrompts({
      organization_id: organizationId
    });
  };

  // * consider moving to ui operations
  const createQuestionWithBinding = async ({
    question,
    questionToGroupBinding,
    setPromptsAPILoading,
    selectedPrompt = null,
    promptInfo
  }) => {
    let newPrompt = '';
    const oldQuestion = omit(questionsByIds[question.id], 'valuesDisplayType');
    const oldBinding = bindingsByIds[questionToGroupBinding.id];
    const isNewQuestion = isEmpty(oldQuestion);
    const isQuestionNeedsUpdate = !isEqual(omit(question, 'valuesDisplayType'), oldQuestion);
    const isNewBinding = isEmpty(oldBinding);
    const isBindingNeedsUpdate = !isEqual(questionToGroupBinding, oldBinding);

    const {
      selectedPromptId: newPromptId = null,
      selectedPromptValue: newPromptValue = null
    } = question;

    const { useAi = false, promptRadio = null, promptLibrary = null } = promptInfo;

    const oldPromptId = selectedPrompt?.id;
    const oldPromptValue = selectedPrompt?.value;
    const oldQuestionPromptId = !isEmpty(selectedPrompt)
      ? selectedPrompt?.questions[0]?.questionId
      : null;

    const createdQuestion = isNewQuestion
      ? await createQuestion(question)
      : isQuestionNeedsUpdate &&
        (await updateQuestion({
          ...objectsDifference(omit(question, 'valuesDisplayType'), oldQuestion, [
            'colorZones',
            'standardComments'
          ]),
          id: question.id
        }));

    if (isQuestionNeedsUpdate && isEmpty(createdQuestion)) {
      return setPromptsAPILoading(false);
    }

    const createdBinding = isNewBinding
      ? await createBinding({
          ...questionToGroupBinding,
          questionId: get(createdQuestion, 'id', oldQuestion.id)
        })
      : isBindingNeedsUpdate &&
        (await updateBinding({
          ...objectsDifference(questionToGroupBinding, oldBinding),
          id: questionToGroupBinding.id
        }));

    if (createdQuestion) {
      // Если включено использование AI
      if (useAi) {
        // Если выбранно "Новый промпт"
        if (promptRadio === 'createPrompt' && isEmpty(selectedPrompt)) {
          // Создаём новый промпт
          newPrompt = await createNewPrompt(question);
        } else if (promptRadio === 'createPrompt' && !isEmpty(selectedPrompt)) {
          // Отвязываем старый промпт
          await updatePromptWithQuestions(oldPromptId, [], [oldQuestionPromptId]);
          // Создаём новый
          newPrompt = await createNewPrompt(question);
        }

        // Если выбрано "Промпт из библиотеки"
        if (isEmpty(selectedPrompt) && promptRadio === 'selectPrompt') {
          // Привязываем промпт из библиотеки к критерию
          await updatePromptWithQuestions(promptLibrary, [createdQuestion.id], [], newPromptValue);
        }

        // Привязываем новый промпт - если промпт был изменен
        if (newPromptId && oldPromptId && oldPromptId !== newPromptId) {
          // Отвязываем старый промпт
          await updatePromptWithQuestions(oldPromptId, [], [oldQuestionPromptId]);
          // Привязываем новый промпт
          await updatePromptWithQuestions(newPromptId, [question.id], [], newPromptValue);
        }

        // Обновляем значние промпта - если не изменился промпт и было изменено его значение
        if (oldPromptId === newPromptId && newPromptValue !== oldPromptValue) {
          // Обновляем значение промпта
          await updatePromptWithQuestions(oldPromptId, [], [], newPromptValue);
        }
      }

      // Если отключено использование Ai отвязываем промпт от критерия
      if (!useAi && !isEmpty(selectedPrompt)) {
        await updatePromptWithQuestions(oldPromptId, [], [oldQuestionPromptId]);
      }

      // Если был создан новый промпт привязываем его к критерию
      if (newPrompt) {
        onPromptCreate({ id: newPrompt.id });
        await updatePromptWithQuestions(newPrompt.id, [createdQuestion.id], []);
      }

      message.success(
        `${t('checklistsPage.checklistItems.messages.question')} ${
          isNewQuestion
            ? t('checklistsPage.checklistItems.messages.questionSuccessfullyCreated')
            : t('checklistsPage.checklistItems.messages.questionSuccessfullyUpdated')
        }`
      );
    } else if ((isNewQuestion || isQuestionNeedsUpdate) && !createdQuestion) {
      message.error(t('checklistsPage.checklistItems.messages.createQuestionFailed'));
    }

    setPromptsAPILoading(false);
    setEditingQuestion({}); // Закрываем модалку
  };

  const createMultipleQuestionsWithBindings = async ({ questionToGroupBindings }) => {
    await Promise.all(questionToGroupBindings.map(binding => createBinding(binding)));
  };

  return (
    <>
      <Spin spinning={loadingChecklist} style={{ margin: '16px 0' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="top-container" direction="vertical" type="container">
            {(provided, contextSnapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {sortedQuestionGroups.map((questionGroup, i) => (
                  <Draggable index={i} key={questionGroup.id} draggableId={questionGroup.id}>
                    {(provided, { isDragging }) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Droppable
                          direction="vertical"
                          droppableId={questionGroup.id}
                          key={questionGroup.id}
                          type="group"
                        >
                          {(provided, { isDraggingOver }) => {
                            return (
                              <QuestionGroup
                                questionGroup={questionGroup}
                                currentChecklist={currentChecklist}
                                currentChecklistQuestionGroupsByIds={currentChecklistQuestionGroups}
                                provided={provided}
                                listBottom={questionGroupsBottom}
                                isDragging={isDragging}
                                isDraggingGlobal={contextSnapshot.isDraggingOver}
                                isDraggingOver={isDraggingOver}
                              />
                            );
                          }}
                        </Droppable>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Spin>
      <AddQuestionModal currentChecklist={currentChecklist} />
      <QuestionsFromLibraryModal onSubmit={createMultipleQuestionsWithBindings} />
      <QuestionModal onSubmit={createQuestionWithBinding} currentChecklist={currentChecklist} />
      <EditQuestionGroupModal />
    </>
  );
};

const mapStateToProps = state => {
  const {
    currentChecklist,
    sortedQuestionGroups,
    sortedQuestionGroupBindings
  } = state.uiChecklistEditor;
  const { updateByIdStarted } = state.questionGroupsResource;
  return {
    currentChecklistQuestionGroups: getChecklistDefinitionQuestionGroups(state, currentChecklist),
    currentChecklistQuestions: getChecklistDefinitionQuestions(state, currentChecklist),
    bindingsByIds: state.questionToGroupBindingsResource.byIds,
    questionGroupsByIds: state.questionGroupsResource.byIds,
    questionsByIds: state.questionsResource.byIds,
    loading: updateByIdStarted || state.checklistDefinitionsResource.loading,
    sortedQuestionGroups,
    sortedQuestionGroupBindings
  };
};

const mapDispatchToProps = {
  updateBinding: questionToGroupBindingsResource.operations.updateById,
  deleteBinding: questionToGroupBindingsResource.operations.deleteById,
  createBinding: questionToGroupBindingsResource.operations.create,
  createPrompt: promptsResource.operations.create,
  updateQuestionGroup: questionGroupsResource.operations.updateById,
  createQuestion: questionsResource.operations.create,
  updateQuestion: questionsResource.operations.updateById,
  updatePrompt: promptsResource.operations.updateById,
  onPromptCreate: promptActions.onPromptCreate,
  updateQuestionsAvailability: promptsQuestionAllListOperations.load,
  loadPrompts: promptsListOperations.load,
  setSortedQuestionGroups,
  setEditingQuestion,
  updateSortedQuestionGroupBindings,
  updateMultipleSortedQuestionGroupBindings
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistItems);
