import { get, put } from 'redux-bees';

const userAccountBindingsEndpoints = apiPath => ({
  getUserAccountBindings: { method: get, path: `${apiPath}/integrations/user_account_bindings` },
  getUserAccountBindingsCSV: {
    method: get,
    path: `${apiPath}/api_integrations/:id/user_account_bindings_csv`
  },
  importCustomUserAccountBindings: {
    method: put,
    path: `${apiPath}/api_integrations/:id/import_user_account_bindings`
  },
  importSaasUserAccountBindings: {
    method: put,
    path: `${apiPath}/saas_integrations/:id/import_user_account_bindings`
  },
  getSaasUserAccountBindingsMatches: {
    method: get,
    path: `${apiPath}/saas_integrations/:id/user_account_binding_matches`
  },
  updateUserAccountBinding: {
    method: put,
    path: `${apiPath}/integrations/user_account_bindings/:id`
  },
  unbindUserAccountBinding: {
    method: put,
    path: `${apiPath}/integrations/user_account_bindings/:id/unbind`
  }
  //   createUnit: { method: post, path: `${apiPath}/organization/units` },
  //   updateUnit: { method: put, path: `${apiPath}/organization/units/:id` },
  //   deleteUnit: { method: destroy, path: `${apiPath}/organization/units/:id` }
});

export default userAccountBindingsEndpoints;
