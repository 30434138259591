export default {
  workPlanTaskConfigurationEditor: {
    newTaskTitle: 'Nuova sfida',
    taskStarted: 'Attività iniziata',
    taskSaved: 'Attività salvata',
    deleteTask: 'Elimina',
    saveTask: 'Salvare',
    startTask: 'Correre',
    copyTask: 'Crea una copia',
    taskInfo: {
      totalCommunications: 'comunicazioni totali',
      checkedOperators: 'personale controllato',
      reviewers: 'revisori'
    },
    delete: {
      title: "Eliminare l'attività?",
      taskDeleted: 'Attività eliminata',
      cantDeleteTask: "Impossibile eliminare l'attività"
    },
    schedule: {
      taskConfig: "Parametri dell'attività",
      taskName: "Nome dell'attività",
      distribution: 'Distribuzione',
      distributionSettings: {
        communicationsNumberByEachOperator: 'Impostare il numero per dipendente',
        communicationsPercentageByEachOperator: 'Imposta la percentuale per dipendente',
        communicationsNumberByOperators: 'Impostare il numero per gruppo di dipendenti'
      },
      period: 'Periodo',
      timeFrom: "La data dell'inizio",
      timeTo: 'Data di completamento',
      repeatEvery: 'Ripeti ogni',
      repeat: 'Ripetizioni',
      toRepeat: 'Ripetere',
      dayTimeFrom: 'Ora di inizio',
      dayTimeTo: 'Tempo di completamento',
      periodForTask: "Periodo di esecuzione dell'attività",
      periodTask: 'Periodo di esecuzione',
      date: 'Data',
      time: 'Tempo',
      start: 'Iniziare',
      end: 'Fine in',
      stopTask: 'Interrompi attività',
      customPeriod: 'Periodo personalizzabile',
      scheduleChangePeriodTask:
        'Se il periodo di esecuzione è cambiato, il tipo di ripetizione sarà resettato a "Non ripetere".',
      info: {
        schedulePeriodTask: 'Periodo per il quale è necessario controllare il compito',
        scheduleStopTask: 'Seleziona la data in cui vuoi smettere di ripetere il compito'
      }
    },
    filters: {
      deleteFilters: 'Rimuovi filtri',
      maxCount: 'Numero di valutazioni',
      clientInteractionType: 'Tipo di comunicazione',
      addUsers: 'Aggiungi utente',
      add: 'Aggiungere',
      usersAdded: 'Utenti',
      addFilters: 'Aggiungi tipo di comunicazione',
      datesRange: 'Controllare le date',
      invalid:
        'Per impostare i controllori, è necessario: aggiungere il tipo di comunicazione, selezionare il numero di comunicazioni da controllare e selezionare i dipendenti da controllare\n',
      valid: 'Per iniziare il compito è necessario selezionare le pedine'
    },
    clientInteractionFilters: {
      operators: 'Dipendenti',
      addFilter: 'Aggiungere il filtro',
      totalCountForAll: 'Conteggio totale per tutti',
      setConfiguration: {
        operatorsToCheck: 'Seleziona i dipendenti da controllare',
        communicationsToCheck: 'Impostare le comunicazioni per il controllo',
        directionOfCommunications: 'Impostare la direzione delle comunicazioni',
        durationOfCommunications: 'Imposta la durata della comunicazione',
        communicationPartsCount: 'Imposta il numero di messaggi'
      },
      deleteFiltersModalTitle: 'Cancellare i filtri insieme al tipo di comunicazione?',
      quantityByOperator: 'Numero per dipendente',
      percentageByOperator: '% per dipendente',
      operatorsToReview: 'Dipendenti da controllare',
      searchFilter: 'Inserisci il nome del filtro'
    },
    distribution: {
      addReviewer: 'Aggiungi recensore',
      manual: 'Manuale',
      auto: 'Uniformemente'
    },
    reviewersWithoutPermissions:
      'Un controllore non può essere aggiunto a un compito perché non ha diritti di accesso a uno o più record di operatori.',
    changeTypeDistribution:
      'Se il tipo di allocazione viene cambiato, i valori delle allocazioni per le pedine impostate manualmente saranno resettati. Continuare?',
    addUsersModal: {
      users: 'Dipendenti',
      unit: 'Dipartimento',
      role: 'Ruolo'
    }
  }
};
