import styled from 'styled-components';
import { space, layout, flexbox, background, shadow, position, border } from 'styled-system';
import { Card } from 'antd';
import { isBoolean, isString } from 'lodash';

export default styled(Card)`
  &.ant-card {
    border-radius: ${({ rounded = true }) => {
      if (isBoolean(rounded)) {
        return rounded ? '8px' : '0px';
      }
      if (isString(rounded)) return rounded;
    }};
    box-shadow: ${({ shadowed = true }) => {
      if (isBoolean(shadowed)) {
        return shadowed ? '1px 1px 6px rgba(0, 0, 0, 0.06)' : 'none';
      }
      if (isString(shadowed)) return shadowed;
    }};
    border: ${({ bordered = false }) => {
      if (isBoolean(bordered)) {
        return bordered ? '1px solid var(--gray-border)' : 'none';
      }
      if (isString(bordered)) return bordered;
    }};
    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
    ${border}
  }

  &.ant-card > .ant-card-body {
    padding: ${props => (props.bodyPadding ? props.bodyPadding : '32px')};
    background: ${props => (props.bodyBG ? props.bodyBG : 'unset')};
  }

  &.ant-card > .ant-card-head {
    padding: ${props => (props.headPadding ? props.headPadding : '0 16px')};
  }
`;
