import { INTEGRATIONS } from 'core/utils/constants';
import decamelizeKeys from 'decamelize-keys';
import { integrationsResource } from 'redux/resources/integrations';
import api from 'core/api';
import { processJsonApiObject } from 'core/jsonapi';
import { saveBackgroundJob } from '../backgroundJob/operations';

export const createZendeskIntegration = ({
  name,
  accessToken,
  clientId,
  endpoint
}) => async dispatch => {
  try {
    const integration = await dispatch(
      integrationsResource.operations.create({
        accessToken,
        name,
        clientId,
        endpoint,
        integrationType: INTEGRATIONS.zendesk.type
      })
    );
    dispatch(saveBackgroundJob(integration?.currentBackgroundJob));

    return integration?.id;
  } catch (error) {}
};

export const syncDataWithZendesk = ({ id, fetchFrom }) => async dispatch => {
  const response = await api.zendeskSyncDataByPeriod(
    { id },
    decamelizeKeys({
      fetchFrom
    })
  );

  const backgroundJob = processJsonApiObject(response.body.data);

  return dispatch(saveBackgroundJob(backgroundJob));
};
