import { createSelector } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

export const getClientInteractionsByIds = state => state.clientInteractionsResource.byIds;

export const getClientInteractionsCrmEntityIds = createSelector(
  getClientInteractionsByIds,
  (_, crmEntityId) => crmEntityId,
  (crmEntityIds, crmEntityId) => pickBy(crmEntityIds, { crmEntityId })
);
