import { Tooltip, Typography } from 'antd';
import { BOOL_VALUES, CUSTOM_FIELDS_TYPES } from 'core/utils/constants';
import { find, get, isEmpty, isUndefined, isArray } from 'lodash';
import moment from 'moment';
import React from 'react';
import i18n from 'i18next';
import SText from './Standard/SText';

const { Text, Link } = Typography;

const getCustomFieldSelectValue = ({ customFields = {}, customFieldsByKeys, customFieldKey }) => {
  // FIXME: remove toString conversion after Qiwi fix their keys
  const getText = key =>
    get(find(customFieldsByKeys[customFieldKey]?.settings, { key: key + '' }), 'text', '');

  if (Array.isArray(customFields[customFieldKey])) {
    return customFields[customFieldKey]
      .map(element => {
        if (Array.isArray(element)) {
          return element.map(getText).join(', ');
        }
      })
      .join(' / ');
  }

  return getText(customFields[customFieldKey]);
};

const displayDuration = fieldValue => {
  if (isUndefined(fieldValue)) return '';
  if (fieldValue === 0 || fieldValue === '0') return '00:00:00';
  return moment.duration(fieldValue, 'seconds').format('hh:mm:ss');
};

const renderBool = value => {
  if (value === 'true' || value === true) {
    return i18n.t(BOOL_VALUES.TRUE.title);
  }
  if (value === 'false' || value === false) {
    return i18n.t(BOOL_VALUES.FALSE.title);
  }
  return '-';
};

const CustomFieldValueView = ({
  customFields,
  customFieldsByKeys,
  customFieldKey,
  value,
  wrapValue = true,
  customFieldInfo = false, // Клик по кнопке Дополнительная информация - убираем ограничение по символам,
  maxLength = 15,
  sliced = true
}) => {
  const fieldType = customFieldsByKeys?.[customFieldKey]?.fieldType;
  const fieldValue = value || get(customFields, customFieldKey, []);

  // TODO: реализовать логику отображения полей с типом datetime с учетом timezone сервера, или значения которое записано в строке с датой
  // const displayDateTimeWithTimeZone = fieldValue => {
  //   if (isEmpty(fieldValue)) return '';
  //   return moment(fieldValue)
  //     .utc(0)
  //     .format('DD/MM/YYYY, HH:mm');
  // };

  if (fieldValue == null) {
    return '-';
  }
  if (!fieldValue) {
    return '';
  }
  const customFieldValue = isArray(fieldValue) ? fieldValue.join(', ') : fieldValue;
  const currentLength = customFieldValue.length; // Длина переданной строки
  const toStringValue = customFieldValue.toString();
  const substring = `${toStringValue.substring(0, maxLength)}...`;
  const stringToRender = currentLength > maxLength ? substring : customFieldValue; // Строка которая пойдёт на рендер

  const defaultString = sliced ? (
    <Tooltip title={customFieldValue}>
      <SText
        style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
        ellipsis
      >
        {customFieldInfo ? customFieldValue : stringToRender}
      </SText>
    </Tooltip>
  ) : (
    <SText style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
      {customFieldValue}
    </SText>
  );

  if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
    if (wrapValue) {
      return !isEmpty(fieldValue) && <Text>{moment(fieldValue).format('DD/MM/YYYY, HH:mm')}</Text>;
    }
    return !isEmpty(fieldValue) && moment(fieldValue).format('DD/MM/YYYY HH:mm');
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY) {
    return defaultString;
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.STRING_URL) {
    if (wrapValue) {
      return (
        <Tooltip title={customFieldValue}>
          <Link href={fieldValue} target="_blank">
            {stringToRender}
          </Link>
        </Tooltip>
      );
    }
    return defaultString;
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY) {
    return defaultString;
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.BOOLEAN) {
    if (wrapValue) return <Text>{renderBool(fieldValue)}</Text>;
    return renderBool(fieldValue);
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.DURATION) {
    if (wrapValue) return <Text>{displayDuration(fieldValue)}</Text>;
    return displayDuration(fieldValue);
  }

  if (fieldType === CUSTOM_FIELDS_TYPES.SELECT) {
    if (wrapValue) {
      return (
        <Text>
          {getCustomFieldSelectValue({
            customFields,
            customFieldsByKeys,
            customFieldKey
          })}
        </Text>
      );
    }
    return getCustomFieldSelectValue({
      customFields,
      customFieldsByKeys,
      customFieldKey
    });
  }

  if (wrapValue) return defaultString;

  return defaultString;
};

export default CustomFieldValueView;
