import React from 'react';
import { useDispatch } from 'react-redux';
import WidgetTable from 'components/Dashboard/SmallWidgetTable';
import { updateActiveChartsViewItem } from 'redux/ui/dashboard/reducer';

const UsersTable = ({ tableData, widgetId, selectedUserId }) => {
  console.log('UsersTable');
  const dispatch = useDispatch();
  return (
    <WidgetTable
      rowKey="id"
      dataSource={tableData.tableRows}
      bordered={false}
      columns={tableData.tableColumns}
      pagination={false}
      size="small"
      showSorterTooltip={false}
      scroll={{ y: 462 }}
      onRow={record => {
        return {
          onClick: () => {
            dispatch(
              updateActiveChartsViewItem({
                [widgetId]: {
                  id: widgetId,
                  selectedUserId: selectedUserId === record.id ? '' : record.id
                }
              })
            );
          }
        };
      }}
    />
  );
};

export default UsersTable;
