import { Activity } from 'react-feather';
import { Col, Progress, Row, Tooltip, Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import ChecklistDefinitionColorZonesByOperator from 'components/Team/ColorZonesBar';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';
import { valueFormatter } from 'core/utils/valueFormatter';
import { get, isEmpty, sortBy } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getQuestionGroupQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { setAnswersHistoryQuestionId } from 'redux/ui/userAnalyticsPage/reducer';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons';

const { Text } = Typography;

const QuestionsAnalytics = ({
  widgets,
  questionsByIds,
  questionGroupQuestions,
  questionGroup = {},
  reviewsCount = 0,
  loading,
  isGroupable,
  setAnswersHistoryQuestionId,
  checklistDefinition,
  allQuestionsScores = false
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <SCard loading={loading} />;
  }

  const { name, questionsIds, max } = questionGroup;

  const analyticsQuestions = (
    questionsIds ||
    Object.keys(get(widgets, ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COLOR_ZONE_BY_QUESTIONS, {}))
  ).map(questionId => questionsByIds[questionId]);

  const questionsToRender = (isEmpty(questionGroupQuestions)
    ? analyticsQuestions
    : sortBy(questionGroupQuestions, 'binding.position')
  ).map(question => {
    const colorZones = get(
      widgets,
      [ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COLOR_ZONE_BY_QUESTIONS, question?.id],
      {}
    );

    const averageScore = get(
      widgets,
      [ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_AVERAGE_BY_QUESTIONS, question?.id, 'value'],
      0
    );

    const answersCount = get(
      widgets,
      [ANALYTICS_WIDGETS_NAMES.ANSWER_SCORES_COUNT_BY_QUESTIONS, question?.id, 'value'],
      0
    );

    const countsString = reviewsCount ? ` (${answersCount}/${reviewsCount}) ` : '';

    return {
      ...question,
      averageScore,
      answersCount,
      countsString,
      colorZones: {
        low: colorZones.low,
        high: colorZones.high,
        medium: colorZones.medium
      }
    };
  });

  const questionGroupAverageScore = get(
    widgets,
    [ANALYTICS_WIDGETS_NAMES.AVERAGE_SCORE_BY_QUESTION_GROUPS, questionGroup.id, 'value'],
    0
  );

  return (
    <SCard bordered shadowed>
      <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
        {isGroupable && !allQuestionsScores && (
          <Col span={4}>
            <Row
              gutter={[8, 8]}
              style={{ margin: '-4px' }}
              type="flex"
              align="middle"
              justify="center"
            >
              <SCol display="flex" justifyContent="center" span={24}>
                <Text style={{ width: '100%', textAlign: 'center' }} strong>
                  {name}
                </Text>
              </SCol>
              <SCol display="flex" justifyContent="center" span={24}>
                <Progress
                  type="circle"
                  percent={
                    checklistDefinition.ratingMode === 'percentage'
                      ? questionGroupAverageScore
                      : (questionGroupAverageScore / max) * 100
                  }
                  format={() => (
                    <Text strong>
                      {valueFormatter({
                        value: questionGroupAverageScore,
                        ratingMode: checklistDefinition.ratingMode
                      })}
                    </Text>
                  )}
                  strokeColor="var(--blue_6)"
                />
              </SCol>
            </Row>
          </Col>
        )}
        <Col span={isGroupable && !allQuestionsScores ? 20 : 24}>
          <Row gutter={[8, 8]} style={{ margin: '-4px' }}>
            {questionsToRender.map(({ name, text, averageScore, id, colorZones, countsString }) => (
              <Col span={24} key={id}>
                <Row type="flex" justify="space-between" align="middle">
                  <SCol span={24} flex="auto" display="flex" alignItems="center">
                    <Tooltip title={name}>
                      <SText strong>{name}</SText>
                    </Tooltip>
                    <IconButton
                      tooltip={{
                        title: text,
                        overlayStyle: { width: 500 }
                      }}
                      button={{
                        disabled: !text,
                        icon: <Icon icon={InfoCircleOutlined} />,
                        size: 'icon',
                        style: { width: 24, marginRight: 4 }
                      }}
                    />
                  </SCol>
                  <Col span={24}>
                    <Row type="flex" gutter={[8, 8]} style={{ margin: '-4px' }} align="middle">
                      <Col span={19}>
                        <ChecklistDefinitionColorZonesByOperator
                          data={{ id, ...colorZones }}
                          reviewsCount={reviewsCount}
                        />
                      </Col>
                      <SCol display="flex" justifyContent="center" span={2}>
                        <IconButton
                          tooltip={{
                            title: t('userAnalyticsPage.widgets.questionTooltip'),
                            overlayStyle: { width: 500 }
                          }}
                          button={{
                            icon: <Icon icon={Activity} />,
                            size: 'icon',
                            style: { fontSize: '24px', lineHeight: '10px', cursor: 'pointer' },
                            onClick: () => setAnswersHistoryQuestionId(id)
                          }}
                        />
                      </SCol>
                      <Col span={3}>
                        <Row type="flex" justify="start" align="middle" gutter={[4, 4]}>
                          <Col>
                            <Text style={{ fontSize: '24px', lineHeight: '10px' }} strong>
                              {valueFormatter({
                                value: averageScore,
                                ratingMode: checklistDefinition.ratingMode
                              })}
                            </Text>
                          </Col>
                          <Col>
                            <Text
                              type="secondary"
                              strong
                              style={{ fontSize: '14px', lineHeight: '10px' }}
                            >
                              {countsString}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </SCard>
  );
};

const mapStateToProps = (state, ownProps) => {
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;
  const { currentUser } = state.uiUserAnalyticsPage;

  const checklistDefinition = get(
    checklistDefinitionsByIds,
    state.uiUserAnalyticsPage.filters[currentUser.id].currentChecklistDefinitionId,
    {}
  );
  return {
    checklistDefinition,
    questionsByIds: state.questionsResource.byIds,
    questionGroupQuestions: getQuestionGroupQuestions(state, ownProps.questionGroup || {})
  };
};

const mapDispatchToProps = {
  setAnswersHistoryQuestionId
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsAnalytics);
